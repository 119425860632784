.Block--jobs {

    .JobsWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 1100px;
    }

    .JobsTitle {
        @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
        margin-bottom: u(40);

        &-dot {
            color: $yellow--300;
        }
    }

    .JobItem {
        @include fluid-type(width,$viewport--md,$viewport--xl,200px,300px);
        @include fluid-type(height,$viewport--md,$viewport--xl,200px,300px);
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        border-top-right-radius: u(60);
        border-bottom-left-radius: u(60);
        margin-left: u(20);
        margin-right: u(20);
        margin-bottom: u($spacing-unit * 2) !important;
        transition: ease-in-out .5s;
        padding: u(20);
        text-decoration: none;

        @include mq($until: $viewport--md - 1) {
            margin-left: 0;
            margin-right: 0;
        }

        &--content {

            .JobItem-title {
                max-width: 70%;
            }
        }

        &--black {
            background-color: $grey--500;

            .JobItem-title {
                color: $yellow--300;
            }
        }

        &--yellow {
            background-color: $yellow--300;

            .JobItem-title {
                color: $grey--500;
            }
        }

        @include mq($until: $viewport--md - 1) {
            width: 100%;
            margin-bottom: u($spacing-unit) !important;
        }

        &-plus {
            position: absolute;
            right: u(20);
            bottom: u(20);
            @include fluid-type(width,$viewport--md,$viewport--xl,80px,120px);
            @include fluid-type(height,$viewport--md,$viewport--xl,80px,120px);

            span {
                background-color: $white;
                display: flex;
                position: absolute;

                &:nth-of-type(1) {
                    width: u(2);
                    height: 100%;
                    left: 0;
                    right: 0;
                    margin-right: auto;
                    margin-left: auto;
                }

                &:nth-of-type(2) {
                    width: 100%;
                    height: u(2);
                    top: 0;
                    bottom: 0;
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }
        }

        &-object {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgb(52,52,52);
                background: linear-gradient(180deg, rgba(52,52,52,1) 0%, rgba(52,52,52,0) 60%, rgba(52,52,52,0) 100%);
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $black;
                opacity: 0;
                transition: ease-in-out .5s;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-label {
            position: relative;
            z-index: 1;
            @include fluid-type(font-size,$viewport--md,$viewport--lg,14px,16px);
            color: $yellow--300;
            text-decoration: none;
            background: rgba(255, 255, 255, .2);
            border-radius: 60px;
            width: fit-content;
            padding: 0 u($spacing-unit--sm);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-title {
            position: relative;
            z-index: 1;
            color: $white;
            font-family: $font-ibm;
            font-weight: 600;
            @include fluid-type(font-size,$viewport--md,$viewport--lg,16px,20px);
            text-decoration: none;
        }

        &-desc {
            position: relative;
            z-index: 1;
            @include fluid-type(font-size,$viewport--md,$viewport--lg,14px,16px);
            color: $white;
            text-decoration: none;
            max-width: 70%;
            opacity: 0;
            transition: ease-in-out .5s;
        }

        &-arrow {
            position: absolute;
            z-index: 3;
            right: 100%;
            bottom: u(20);
            transition: ease-in-out .5s;
            padding: u(5) u(25);
            border-radius: u(30);
            background-color: $green--300;

            .Arrow {
                width: u(25);
            }
        }

        &:hover,
        &:active {
            border-radius: u(60);
            transform: scale(1.08);
            padding: u(30);

            .JobItem-object {

                &::before {
                    opacity: .3;
                }
            }

            .JobItem-arrow {
                right: u(20);
            }

            .JobItem-desc {
                opacity: 1;
            }
        }
    }
}

.Block--moreJobs {
    margin-bottom: -120px;
    background-color: $yellow--300;
    border-top-right-radius: u(60);
    border-top-left-radius: u(60);
    padding-top: u(50);
    padding-bottom: u(100);

    .Slider {
        overflow: inherit;
    }

    .MoreJobsTitle {
        @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
        margin-bottom: u(40);
        color: $grey--500;

        &-dot {
            color: $yellow--300;
        }
    }

    .MoreJobsWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 1100px;
    }

    .MoreJobsItem {
        @include fluid-type(width,$viewport--md,$viewport--xl,200px,300px);
        @include fluid-type(height,$viewport--md,$viewport--xl,200px,300px);
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        border-top-right-radius: u(60);
        border-bottom-left-radius: u(60);
        margin-bottom: u($spacing-unit * 2) !important;
        margin-left: u($spacing-unit);
        margin-right: u($spacing-unit);
        transition: ease-in-out .5s;
        padding: u(20);
        text-decoration: none;

        @include mq($until: $viewport--md - 1) {
            width: u(230);
            height: u(230);
            margin-bottom: u($spacing-unit) !important;
            margin-right: 0;
            margin-left: 0;
        }

        &-object {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgb(52,52,52);
                background: linear-gradient(180deg, rgba(52,52,52,1) 0%, rgba(52,52,52,0) 60%, rgba(52,52,52,0) 100%);
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $black;
                opacity: 0;
                transition: ease-in-out .5s;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-title {
            position: relative;
            z-index: 1;
            color: $white;
            font-family: $font-ibm;
            font-weight: 600;
            @include fluid-type(font-size,$viewport--md,$viewport--lg,16px,20px);
            text-decoration: none;
        }

        &-desc {
            position: relative;
            z-index: 1;
            @include fluid-type(font-size,$viewport--md,$viewport--lg,14px,16px);
            color: $white;
            text-decoration: none;
            max-width: 70%;
            opacity: 0;
            transition: ease-in-out .5s;
        }

        &-arrow {
            position: absolute;
            z-index: 3;
            right: 100%;
            bottom: u(20);
            transition: ease-in-out .5s;
            padding: u(5) u(25);
            border-radius: u(30);
            background-color: $green--300;

            .Arrow {
                width: u(25);
            }
        }

        &:hover,
        &:active {
            border-radius: u(60);
            transform: scale(1.08);
            padding: u(30);

            .MoreJobsItem-object {

                &::before {
                    opacity: .3;
                }
            }

            .MoreJobsItem-arrow {
                right: u(20);
            }

            .MoreJobsItem-desc {
                opacity: 1;
            }
        }
    }
}