.HomeExpertise{
    @include position(relative, $z: 1);
    @include breakout();
    padding: u(80,0);
    padding-right: u(48);

    @include mq($until: $viewport--md) {
        padding: u(48,0);
        padding-right: u(24);
    }

    &::before {
        background-color: $yellow--300;
        border-top-right-radius: u($spacing-unit--xl);
        border-bottom-right-radius: u($spacing-unit--xl);
    }


    &-content{
        @include fluid-type(padding-left,1350px,1550px,0,100px);
    }

    &-header{
        text-align: center;
        position: relative;
        padding-bottom: 50px;
    }

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-1,$heading-size-1--lg);
        margin-bottom: u(8);
    }

    &-subTitle{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-3,$heading-size-3--lg);
    }

    &-dot{
        color: $white;
        display: inline-block;
    }

    &-text{
        // padding-top: u(24);
        font-family: $font-ibm;

        p:last-of-type{
            margin-bottom: 0;
        }
    }

    &-image{
        height: u(150);
    }

    &-object{
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: u(40);
        transform: translateX(-25px);
    }

    &-btnContainer{
        @include position(absolute,$top:0,$right:0);
        transform: translateY(10px);

        @include mq($until: $viewport--md) {
            position: relative;
        }
    }

    .Grid-cell:nth-child(2){
        .HomeExpertise-image{
            width: u(240);
            object-fit: contain;
        }
    }
}