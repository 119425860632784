/* Article */
.Article {

    // Jobs

    &-jobImage{
        padding-top: 24px;
        padding-left: 24px;

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            width: (100% / 2);
        }

        @include mq($from: $viewport--lg) {
            width: (100% / 3);
        }

        .ArticleJob-image{
            border-radius: 0px 48px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .ArticleJob-object{
            display: block;
            height: 100%;
        }
    }

    // Link
    &-link {
        display: block;

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
            text-decoration: none;
        }
    }

    // Object
    &-object {
        overflow: hidden;
        position: relative;

        &.Picture {
            display: block;
        }
    }

    // Image
    &-image {
        @include position(absolute, $top: 50%, $left: 50%);
        transform: translate(-50%, -50%);
        width: 100%;
    }

    // Body
    &-body {
        position: relative;
        padding: u($spacing-unit);
    }

    &-categories{
        font-size: u(14);
        font-weight: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #bababa;
    }

    &-cat {
        font-size: u(14);
        font-weight: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #bababa;
    }

    &-work{
        font-size: u(14);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: $grey--500;
        margin-bottom: u(4);
    }

    // InnerBody
    &-innerBody {

        :last-child {
            margin-bottom: 0;
        }
    }

    // Header
    &-header {
        margin-bottom: u($spacing-unit--sm);
        display: flex;
        flex-direction: column;
    }

    // Author
    &-author {
        display: flex;
        align-items: center;

        .Avatar {
            &-object {
                img {
                    border-radius: 100%;
                }
            }
        }
    }

    // Title
    &-title {
        margin-bottom: u(5) !important;
        color: $grey--600 !important;

        &:not(:only-child) {
            margin-top: u($spacing-unit--sm);
        }
    }

    // Date
    &-date {
        color: $grey--300;
        font-size: u(14);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;

        .Article-author & {

            @include mq($until: $viewport--md - 1) {
                font-size: u(12);
            }
        }
    }

    // Iteration
    &--1:not(.Article--alt) {

        .Article-body::before {
            right: u($spacing-unit--xl);
            left: u($spacing-unit--sm * -1);
        }

        .Article-object {
            border-radius: px(0, $spacing-unit--sm, $spacing-unit--sm, 0);
        }
    }

    &--2:not(.Article--alt) {

        .Article-body::before {
            right: u($spacing-unit--sm * -1);
            left: u($spacing-unit--sm * -1);
        }

        .Article-object {
            border-radius: px($spacing-unit--sm, $spacing-unit--sm, 0, 0);
        }
    }

    &--3:not(.Article--alt) {

        .Article-body::before {
            right: u($spacing-unit--sm * -1);
            left: u($spacing-unit--xl);
        }

        .Article-object {
            border-top-left-radius: px($spacing-unit--sm);
            border-bottom-left-radius: px($spacing-unit--sm);
        }
    }

    /* Default */
    &:not(.Article--alt):not(.Article--more) {

        // Link
        .Article-link {
            padding-bottom: u($spacing-unit--sm);
        }

        // Object
        .Article-object {
            padding-bottom: (100% / 5 * 3);

            .Articles--jobs & {
                padding-bottom: (100% / 612 * 240);
            }
        }

        // Body
        .Article-body {
            background-color: $white;
            border-radius: px($spacing-unit--sm);
            margin-top: u($spacing-unit--xl * -1);
            margin-right: u($spacing-unit--sm);
            margin-left: u($spacing-unit--sm);

            &::before {
                @include position(absolute, $top: 0, $bottom: u($spacing-unit--sm * -1), $z: -1);
                background-color: $yellow--300;
                border-radius: px(0, 0, $spacing-unit--sm, $spacing-unit--sm);
                content: '';
            }
        }

        // Footer
        .Article-footer {
            @include position(absolute, $top: 0, $right: u(32));
            transform: translateY(-50%);
        }
    }

    /* Alt */
    &--alt {

        // Object
        .Article-object {
            border-radius: u($spacing-unit--sm);
            padding-bottom: (100% / 376 * 138);
        }

        // Body
        .Article-body {
            background-color: $grey--500;
            color: $white;
            margin-top: u($spacing-unit * -1);
            margin-bottom: u($spacing-unit--sm);
            padding-bottom: u($spacing-unit--lg);

            .Article-title {
                color: $white !important;
            }

            @include mq($until: $viewport--md - 1) {
                position: relative;

                &::after {
                    @include position(absolute, $top: 0, $bottom: 0);
                    background-color: $grey--500;
                    content: '';
                    width: u($structure-responsive-gutter--l);
                }
            }
        }

        // Footer
        .Article-footer {
            @include position(absolute, $bottom: 0);
            transform: translateY(50%);
        }

        // Rows
        @include mq($from: $viewport--lg) {

            &:nth-child(6n+1),
            &:nth-child(6n+2),
            &:nth-child(6n+3) {

                .Article-object {
                    margin-right: u($spacing-unit);
                }

                .Article-body {
                    margin-left: u($spacing-unit--xl);
                    // border-top-left-radius: u($spacing-unit--xs);
                    // border-bottom-left-radius: u($spacing-unit--xs);
                }

                .Article-footer {
                    right: u($spacing-unit * 3);
                }
            }

            &:nth-child(6n+4),
            &:nth-child(6n+5),
            &:nth-child(6n+6) {

                .Article-object {
                    margin-left: u($spacing-unit);
                }

                .Article-body {
                    border-top-right-radius: u($spacing-unit--xs);
                    border-bottom-right-radius: u($spacing-unit--xs);
                    margin-right: u($spacing-unit--xl);
                }
            }
        }

        @include mq($from: $viewport--md) {

            .Article-body {
                border-radius: u($spacing-unit--xs);
            }
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

            &:nth-child(4n+1),
            &:nth-child(4n+2) {

                .Article-object {
                    margin-right: u($spacing-unit);
                }

                .Article-body {
                    margin-left: u($spacing-unit--xl);
                    border-top-left-radius: u($spacing-unit--xs);
                    border-bottom-left-radius: u($spacing-unit--xs);
                }

                .Article-footer {
                    right: u($spacing-unit * 3);
                }
            }

            &:nth-child(4n+3),
            &:nth-child(4n+4) {

                .Article-object {
                    margin-left: u($spacing-unit);
                }

                .Article-body {
                    border-top-right-radius: u($spacing-unit--xs);
                    border-bottom-right-radius: u($spacing-unit--xs);
                    margin-right: u($spacing-unit--xl);
                }
            }
        }

        @include mq($until: $viewport--md - 1) {

            &:nth-child(odd) {

                .Article-object {
                    margin-left: u($spacing-unit);
                }

                .Article-body {
                    margin-right: u($spacing-unit--xl);
                    border-top-right-radius: u($spacing-unit--xs);
                    border-bottom-right-radius: u($spacing-unit--xs);

                    &::after {
                        right: 100%;
                    }
                }

                .Article-footer {
                    right: u($spacing-unit * 3);
                }
            }

            &:nth-child(even) {

                .Article-object {
                    margin-right: u($spacing-unit);
                }

                .Article-body {
                    margin-left: u($spacing-unit--xl);
                    border-top-left-radius: u($spacing-unit--xs);
                    border-bottom-left-radius: u($spacing-unit--xs);

                    &::after {
                        left: 100%;
                    }
                }

                .Article-footer {
                    right: u($spacing-unit * 3);
                }
            }
        }
    }

    // More
    &--more {
        @include grid(1fr, 1fr);
        color: $white;

        .Article-object,
        .Article-body {
            @include grid-area(1, 2, 1, 2);
        }

        .Article-object {
            background-color: $grey--500;
            border-radius: u($spacing-unit--sm);
            padding-bottom: (100% / 3 * 4);
        }

        .Article-image {
            opacity: .28;
        }

        .Article-body {
            align-self: center;
            justify-self: center;
            text-align: center;
        }
    }
}
