/* Mission */
.Slider--mission {
    margin-right: u($structure-responsive-gutter--r * -1);
    margin-left: u($structure-responsive-gutter--l * -1);

    .Mission-body {
        padding-left: u($structure-responsive-gutter--r);
        padding-right: u($structure-responsive-gutter--r);
    }
}

.Mission {

    &-heading{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-5,$heading-size-4);
    }

    &-items {
        position: relative;

        @include mq($from: $structure-responsive-breakpoint) {
            @include grid(u(200) 1fr auto 1fr auto auto u(458), auto u(82) auto u(80) auto);
        }

        @include mq($from: $viewport--lg, $until: $structure-responsive-breakpoint - 1) {
            @include grid(u(200) 1fr auto auto auto auto u(458), auto u(82) auto u(80) auto);
        }

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            @include grid(repeat(4, auto), repeat(5, auto));
        }
    }

    &-title {
        color: $yellow--300;
        margin-bottom: u($spacing-unit--sm);
    }

    &-item {
        display: flex;
    }

    &-image {
        display: block;
    }

    @include mq($until: $viewport--md - 1) {

        &-object {
            display: block;
            overflow: hidden;
        }

        &-image {

            .Slider-item:nth-child(1) & {

                path:last-child {
                    animation: smar 2s linear infinite;
                }
            }

            .Slider-item:nth-child(2) & {

                path:last-child {
                    animation: smar2 2s linear infinite;
                }
            }

            @keyframes smar {
                0% {
                    transform: translateX(0);
                }

                50% {
                    transform: translateX(5%)
                }

                100% {
                    transform: translateX(0);
                }
            }

            @keyframes smar2 {
                0% {
                    transform: translateX(-5%);
                }

                50% {
                    transform: translateX(0);
                }

                100% {
                    transform: translateX(-5%);
                }
            }

            .Slider-item:first-child & {
                margin-top: u(5.4);
            }

            .Slider-item:nth-child(2) & {
                @include position(relative, $left: 50%);
                transform: translateX(-47%);
            }

            .Slider-item:nth-child(3) & {
                @include position(relative, $left: 100%);
                transform: translateX(-100%);
                margin-top: u(0.5);
            }
        }

        &-body {

            .Slider-item:nth-child(2) & {
                text-align: center;
            }

            .Slider-item:nth-child(3) & {
                text-align: right;
            }
        }
    }

    @include mq($from: $viewport--md) {

        &-object {
            display: block;
            flex-shrink: 0;

            @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                align-self: center;
                margin-top: u($spacing-unit);
                margin-bottom: u($spacing-unit);
            }

            &--1 {
                // @include grid-area(7, 8, 1, 3);
                justify-self: end;

                @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                    @include grid-area(5, 6, 1, 2);
                }

                @include mq($from: $viewport--lg) {
                    @include grid-area(7, 8, 1, 3);
                }

                @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

                    .Mission-image {
                        width: u(252);
                    }
                }
            }

            &--2 {
                // @include grid-area(2, 8, 2, 4);
                margin-top: u(5);

                @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                    @include grid-area(2, 3, 2, 3);
                }

                @include mq($from: $viewport--lg) {
                    @include grid-area(2, 8, 2, 4);
                }

                @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

                    .Mission-image {
                        width: u(330);
                    }
                }
            }

            &--3 {
                // @include grid-area(1, 3, 5, 6);
                margin-top: u(-2px);

                @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                    @include grid-area(5, 6, 3, 4);
                }

                @include mq($from: $viewport--lg) {
                    @include grid-area(1, 3, 5, 6);
                }

                @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

                    .Mission-image {
                        width: u(227);
                    }
                }
            }
        }

        &-body {
            max-width: u(506);

            :last-child {
                margin-bottom: 0;
            }

            @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                align-self: center;
            }

            &--1 {
                // @include grid-area(1, 7, 1, 2);
                align-self: center;
                justify-self: end;
                text-align: right;

                @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                    @include grid-area(1, 5, 1, 2);
                    margin-right: u($spacing-unit--xl);
                }

                @include mq($from: $viewport--lg) {
                    @include grid-area(1, 7, 1, 2);
                    margin-right: u(68);
                    margin-top: u(82);
                }
            }

            &--2 {
                // @include grid-area(6, 8, 3, 4);

                @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                    @include grid-area(3, 6, 2, 3);
                    margin-left: u($spacing-unit--xl);
                }

                @include mq($from: $viewport--lg) {
                    @include grid-area(6, 8, 3, 4);
                    align-self: end;
                    justify-self: end;
                }

                @include mq($from: $viewport--lg, $until: $structure-responsive-breakpoint - 1) {
                    margin-top: u(400);
                    margin-bottom: u(-175);
                }
            }

            &--3 {
                // @include grid-area(4, 8, 5, 6);

                @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                    @include grid-area(1, 5, 3, 4);
                    justify-self: end;
                    text-align: right;
                    margin-right: u($spacing-unit--xl);
                }

                @include mq($from: $viewport--lg) {
                    @include grid-area(4, 8, 5, 6);
                    align-self: center;
                }
            }

            .Mission-item:nth-of-type(1) & {
                align-self: center;
                margin-right: u(68);
                margin-left: auto;
                text-align: right;
            }
        }
    }

    @include mq($until: $viewport--lg - 1) {

        &-pipe {
            display: none;
        }
    }

    @include mq($from: $viewport--lg) {

        &-pipe {
            @include position(absolute);

            &--1 {
                top: u(222.6);
                right: u(-0.5);
                display: flex;

                .Mission-pipeImage {
                    height: u(403);
                }

                @include mq($from: $viewport--md, $until: 1100px) {
                    max-width: u(310);
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-start;
                    overflow: hidden;
                }
            }

            &--2 {
                top: u(460);
                left: u(13);

                .Mission-pipeImage {
                    height: u(524);
                }
            }
        }

        &-pipeImage {
            max-width: none;

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

                .Mission-pipe--1 & {
                    width: u(231);
                }

                .Mission-pipe--2 & {
                    width: u(110);
                }
            }
        }
    }
}
