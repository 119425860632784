.ImageText {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include mq($from: $viewport--md) {
        flex-direction: row;
        gap: 4rem;
        align-items: center;
    }

    @include mq($from: $viewport--lg) {
        gap: 6rem;
    }
    &--inverted {
        @include mq($from: $viewport--md) {
            flex-direction: row-reverse;
        }
    }
}

.ImageText-col {
    flex-basis: 100%;
    @include mq($from: $viewport--md) {
        flex: 0 1 50%;
    }
}

.ImageText-image {
    border-top-right-radius: px(92.5);
    border-bottom-left-radius: px(92.5);
    overflow: hidden;
    width: 100%;
    picture, img {
        width: 100%;
    }
}
