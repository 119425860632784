/* Fields: input */
.#{$form-namespace}Form-field--input,
.#{$form-namespace}Form-field--textarea {
    @extend %Form-field--reset;
    @extend %Form-field--shadow;

    // Placeholder
    &::placeholder {
        color: $grey--300;
    }

    // Error
    .has-error & {
        @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -1.5 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="jam jam-alert-f" fill="#{$red--300}"><path d=\'M10 20.393c-5.523 0-10-4.477-10-10 0-5.522 4.477-10 10-10s10 4.478 10 10c0 5.523-4.477 10-10 10zm0-15a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2z\' /></svg>');
        background-position: center right u(9.3);
        background-repeat: no-repeat;
        background-size: u(13.3);
    }

    // Icon
    .with-icon & {
        padding-left: u(16 + 9.3 + $form-element-padding--l);
    }
}

.#{$form-namespace}Form-field--input {
    padding: em($form-element-padding--t, $form-element-padding--r, $form-element-padding--b, $form-element-padding--l);
}

.#{$form-namespace}Form-field--textarea {
    padding: em($form-element-padding--t - 2, $form-element-padding--r, $form-element-padding--b - 2, $form-element-padding--l);
}
