/* Story */
.Story {

    &-intro {
        @include grid(1fr, 1fr #{u(48)} auto);
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-bottom: u($spacing-unit * 3);
        }
    }

    &Intro {

        &-object {
            @include grid-area(1, 2, 1, 3);

            @include mq($from: $viewport--md) {
                grid-row: 1 / 4;
            }
        }

        &-object,
        &-image {
            display: block;
            width: 100%;
        }

        &-body {
            @include grid-area(1, 2, 2, 4);
        }

        &-innerBody {
            @include position(relative, $z: 1);
            background-color: $white;
            border-radius: u($spacing-unit--sm);
            max-width: u(616);
            padding: u($spacing-unit, $spacing-unit--sm, 0);

            @include mq($from: $viewport--md) {
                padding: u($spacing-unit);
                margin-bottom: u($spacing-unit);
            }

            :last-child {
                margin-bottom: 0;
            }
        }
    }

    &-item {
        margin-top: u($spacing-unit--lg * -1);
        margin-left: u($spacing-unit * -1);

        @include mq($from: $viewport--md) {
            display: flex;
            justify-content: space-between;
        }

        + .Story-item {
            padding-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                padding-top: u($spacing-unit * 3);
            }
        }

        @include mq($from: $viewport--md) {

            &:nth-child(odd) {
                flex-direction: row-reverse;

                .StoryItem-body {
                    max-width: u(506 + $spacing-unit);
                }

                .StoryItem-objects {
                    max-width: u(612 + $spacing-unit + $spacing-unit);
                }
            }

            &:nth-child(even) {

                .StoryItem-objects {
                    max-width: u(506 + $spacing-unit);
                }

                .StoryItem-body {
                    max-width: u(612 + $spacing-unit + $spacing-unit);

                    > p,
                    > ul,
                    > ol {
                        max-width: u(469);
                    }
                }
            }
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    &Item {

        &-body,
        &-objects {
            padding-top: u($spacing-unit--lg);
            padding-left: u($spacing-unit);

            @include mq($from: $viewport--md) {
                width: 50%;
            }
        }

        &-body {
            align-self: center;
        }

        &-objects {
            display: flex;
            align-self: start;
            margin-left: u($spacing-unit--xs * -1);

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit * -1);
            }

            &.has-multiple {

                @include mq($from: $viewport--md) {
                    width: 100%;
                }

                > .StoryItem-object {
                    width: 50%;
                }
            }
        }

        &-object {
            margin-left: u($spacing-unit--xs);
            align-self: flex-start;

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit);

                + .StoryItem-object {
                    margin-top: u($spacing-unit);
                }
            }

            @include mq($until: $viewport--md - 1) {

                &:first-child:not(:only-child) {
                    margin-top: u($spacing-unit);
                }
            }
        }

        &-object,
        &-image {
            display: block;
            width: 100%;
        }
    }
}
