.Steps{
    text-align: center;

    &-gridContainer{
        justify-content: center;
    }

    &-item{
        display: flex;
        flex-direction: column;
    }

    &-aboveText{
        font-size: u(18);
        display: inline-block;
        min-height: u(50);
    }

    &-step{
        display: inline-block;
        background: $grey--500;
        font-family: $font-ibm;
        font-size: u(18);
        color: $white;
        padding: u(12, 27);
        font-weight: bold;
    }

    &-imgContainer{
        margin-bottom: u(50);
    }

    &-stepContainer{
        margin-bottom: u(30);
    }

    &-itemTitle{
        font-weight: bold;
        font-family: $font-ibm;
        font-size: u(18);
        line-height: 23px;
        background-image: url('/assets/img/title-background.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: top 11px right 0px;
    }

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-2,$heading-size-1);
        margin-bottom: u(8);
        max-width: u( 650px);
        margin: 0 auto;
    }

    &-text{
        max-width: u( 650px);
        margin: 0 auto;

        p:last-of-type{
            margin-bottom: 0;
        }
    }

    &-container{
        padding-top: u(75);
    }

    &-image{
        max-height: 150px;
    }
}