.Workflow{

    &-foldout {
        @include position(relative, $z: 1);
        @include breakout();
        margin: 0;
        padding: 0;
        transition: ease-in-out .3s;
        right: -200%;
    
        @include mq($until: $viewport--md) {
            padding: u(70,0);
            padding-left: u(24);
        }
    
        &::before {
            background-color: $yellow--100;
            border-top-left-radius: u($spacing-unit--xl);
            border-bottom-left-radius: u($spacing-unit--xl);
            right: auto !important;
        }

        p {
            margin: 0;
        }
    }

    &-cta {
        @include position(relative, $right: -200%, $z: 1);
        transition: ease-in-out .3s;
    }

    &-more {
        height: 0;

        &.visible {
            display: block;
            height: auto;
            transition: ease-in-out .3s;

            .Workflow-foldout {
                right: 0;
                padding: u($spacing-unit, 24);
                margin: u($spacing-unit) 0 u($spacing-unit--xs) 0;

                @include mq($until: $viewport--md) {
                    margin: u($spacing-unit) u(-20) u($spacing-unit--xs) u(-20);
                }
            }

            .Workflow-cta {
                right: 0;
            }
        }
    }

    &-cta {
        margin: u($spacing-unit--sm) 0;
        font-family: $font-ibm;
        font-weight: bold;

        .Button {
            margin-left: u($spacing-unit--xs);
        }
    }

    .Grid-reverse{
        flex-direction: row-reverse;

        @include mq($until: $viewport--lg) {
            flex-direction: column-reverse;
        }
    }

    .Grid{
        @include mq($until: $viewport--lg) {
            padding-bottom: u(48);
            flex-direction: column-reverse;
        }
    }

    &-stepCount{
        font-family: $font-ibm;
        background: $green--400;
        font-weight: bold;
        font-size: u(16);
        width: 39px;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        border-radius: 100%;
        margin-bottom: u(12);
    }

    &-title{
        margin-bottom: u(8);
    }

    &-text{
        max-width: u(475);
        margin-bottom: u(8);
    }

    &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq($until: $viewport--lg) {
            padding-bottom: u(28);
            padding-top: u(48);
        }

        &--reverse{
            padding-left: u(125);

            @include mq($until: $viewport--lg) {
                padding-left: 0;
            }
        }
    }

    &-image1{
        @include fluid-type(width,$viewport--md,1350px,500px,925px);
        @include fluid-type(height,$viewport--md,1350px,300px,400px);

        @include mq($until: $viewport--lg) {
            display: none;
        }
    }

    &-image2{
        margin-left: 45%;
        margin-top: u(24px);
        margin-bottom: u(24px);
        @include fluid-type(width,$viewport--md,1350px,300px,449px);

        @include mq($until: $viewport--lg) {
            display: none;
        }
    }
}