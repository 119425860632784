.Block--blogs {
    margin-bottom: -120px;
    background-color: $yellow--300;
    border-top-right-radius: u(60);
    border-top-left-radius: u(60);
    padding-top: u(50);
    padding-bottom: u(100);

    .Blogs {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mq($until: $viewport--md - 1) {
            flex-direction: column;
        }

        &Item {
            overflow: hidden;
            border-radius: u(60);
            height: u(400);
            position: relative;
            text-decoration: none;
            padding: u(40);
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            width: 20%;
            transition: ease-in-out 1s;

            @include mq($until: $viewport--md - 1) {
                width: 100%;
                margin-bottom: u($spacing-unit);
            }

            &.active {
                width: 55%;
                border-top-left-radius: 0;
                border-bottom-right-radius: 0;

                @include mq($until: $viewport--md - 1) {
                    width: 100%;
                }

                .BlogsItem {

                    &-arrow {
                        right: u(30);
                    }

                    &-content {
                        opacity: 1;
                    }

                    &-object {

                        &::before {
                            opacity: .3;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }

            &-detail {
                @include fluid-type(font-size,$viewport--md,$viewport--lg,14px,20px);
                color: $yellow--300;
            }

            &-title {
                color: $white;
                font-family: $font-ibm;
                font-weight: 600;
                @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
                text-decoration: none;
                @include fluid-type(line-height,$viewport--md,$viewport--lg,23px,45px);
                margin-bottom: u(10);
            }

            &-object {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;

                &::after {
                    content: "";
                    position: absolute;
                    opacity: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgb(52,52,52);
                    background: linear-gradient(0deg, rgba(52,52,52,1) 0%, rgba(52,52,52,0) 60%, rgba(52,52,52,0) 100%);
                }
    
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $black;
                    opacity: 0;
                    transition: ease-in-out 1s;
                }
            }
    
            &-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &-content {
                z-index: 2;
                opacity: 0;
                transition: ease-in-out 1s;
                position: absolute;
                padding: u(40);
                bottom: 0;
                left: 0;
                width: u(550);

                @include mq($from: $viewport--md - 1, $until: $viewport--lg - 1) {
                    width: u(400);
                }

                @include mq($until: $viewport--md - 1) {
                    max-width: 100%;
                }
            }

            &-arrow {
                position: absolute;
                z-index: 3;
                right: 100%;
                bottom: u(20);
                transition: ease-in-out 1s;
                padding: u(5) u(25);
                border-radius: u(30);
                background-color: $green--300;

                .Arrow {
                    width: u(25);
                }
            }
        }

        &Title {
            @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
            margin-bottom: u(40);
            color: $grey--500;
    
            &-dot {
                color: $white;
            }
        }
    }
}