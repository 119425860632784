/* Mixin: Breakout */
@mixin breakout($position: 'left') {

    &::before {
        @if($position == 'left') {
            @include position(absolute, $top: 0, $right: 0, $bottom: 0, $z: -1);
        } @else {
            @include position(absolute, $top: 0, $bottom: 0, $left: 0, $z: -1);
        }

        content: '';
        width: calc(100% + #{u($structure-responsive-gutter--l)});

        @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
            width: calc(100% + #{u($structure-gutter--l)});
        }

        @include mq($from: $structure-responsive-breakpoint) {
            width: calc((100vw - #{u($structure-width)}) / 2 + 100%);
        }
    }
}
