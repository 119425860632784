/* Fields: file */
.#{$form-namespace}Form-field--file {
    cursor: pointer;
    display: block;

    &.is-placeholder {

        .Form-indicator {
            color: $grey--300;
        }
    }

    input[type='file'] {
        @include position(absolute, 0, 0, 0, 0, -1);
        opacity: 0;
        width: 100%;

        // UI state
        &:focus ~ .#{$form-namespace}Form-indicator {
            border-color: $form-element-border-color--focus;
            box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-element-box-shadow--focus);

            // Error
            .#{$form-namespace}Form-item.has-error & {
                box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-error-element-box-shadow);
            }

            // Success
            .#{$form-namespace}Form-item.is-success & {
                box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-success-element-box-shadow);
            }
        }
    }

    .#{$form-namespace}Form-indicator {
        @extend %Form-field--reset;
        @extend %Form-field--shadow;
        border-style: dashed;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: em($form-element-padding--t, $form-element-padding--r, $form-element-padding--b, $form-element-padding--l);

        &::before {
            @include truncate;
            content: attr(data-file-text);
            display: block;
        }
    }

    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}
