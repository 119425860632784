.ServiceBanner{
    height: calc(100vh - (#{u($structure-header-height)}));
    position: relative;

    @include mq($until: $viewport--lg) {
        height: auto;
    }

    > .Container{
        height: 100%;
    }
    .Grid{
        height: 100%;
        align-items: center;

        @include mq($until: $viewport--lg) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    &-img{
        @include mq($until: $viewport--lg) {
            order: 2;
            padding-top: u(24) !important;
        }
    }

    &-content{
        @include mq($until: $viewport--lg) {
            order: 1;
            padding-top: u(24) !important;
        }
    }

    &-scrollDown{
        display: flex;
        flex-direction: column;
        color: $green--400;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.56;
        align-items: center;
        @include position(absolute,$left: 50%, $bottom: 50px);
        transform: translateX(-50%);
        z-index: 10;
        cursor: pointer;

        @include mq($until: $viewport--lg) {
            display: none;
        }
    }

    &-arrowDown{
        animation: bounce 2s infinite;
    }

    &-scrollDownText{
        margin-bottom: u(10);
        display: inline-block;
    }

    &-contentContainer{
        padding-left: u(50);

        @include mq($until: $viewport--lg) {
            padding-left: 0;
        }
    }

    &-text{
        max-width: u(620);
    }

    &-title{
        margin-bottom: u(32);
    }
}