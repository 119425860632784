/* Contact */
.Block--contact {
    @include position(relative, $z: 3);
    @include grid(1fr 1fr, auto auto #{u(220)} auto auto auto auto);
    margin-left: u($spacing-unit--xs * -1);

    @include mq($from: $viewport--md) {
        margin-left: u($spacing-unit * -1);
        @include grid(1fr 1fr 2fr, repeat(4, auto));
    }

    @include mq($until: $viewport--md - 1) {
        margin-top: u($spacing-unit * -1 - $spacing-unit--lg);
    }
}

.Contact {

    &-title,
    &-item,
    &-object,
    &-form {
        margin-left: u($spacing-unit--xs);

        @include mq($from: $viewport--md) {
            margin-left: u($spacing-unit);
        }
    }


    &-map {
        @include position(relative, $z: -1);
        @include grid-area(1, 3, 2, 7);

        @include mq($until: $viewport--md - 1) {
            margin-right: u($structure-responsive-gutter--r * -1);
            margin-left: u($structure-responsive-gutter--l * -1);
        }

        @include mq($from: $viewport--sm) {
            right: 50%;
            left: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            width: 100vw;
        }

        @include mq($from: $viewport--md) {
            @include grid-area(1, 4, 3, 5);

            @include mq($from: $viewport--md) {
                margin-bottom: u($spacing-unit * -5);
            }
        }
    }

    &-title {
        @include position(relative, $z: 1);
        @include grid-area(1, 3, 4, 5);

        @include mq($until: $viewport--md - 1) {
            @include font-size(16, false);
        }

        @include mq($from: $viewport--md) {
            @include grid-area(1, 2, 1, 2);
        }
    }

    &-item {
        @include grid-area(1, 3, 5, 6);
        margin-bottom: u($spacing-unit);

        @include mq($from: $viewport--md) {
            @include grid-area(1, 2, 2, 3);
        }

        @include mq($until: $viewport--md - 1) {
            @include position(relative, $z: 0);
            justify-self: start;
            position: relative;
            padding-right: u($spacing-unit);
            padding-bottom: u($spacing-unit);

            :last-child {
                margin-bottom: 0;
            }

            &::before {
                @include position(absolute, $top: u(-37 - $spacing-unit), $right: 0, $bottom: 0, $left: u($structure-responsive-gutter--l * -1), $z: -1);
                background-color: $white;
                border-top-right-radius: u($spacing-unit--sm);
                border-bottom-right-radius: u($spacing-unit--sm);
                content: '';
            }
        }

        strong {
            font-weight: 700;
        }

        a:not([class]) {
            color: currentColor;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-object {
        align-self: end;
        justify-self: start;

        &:first-of-type {
            @include grid-area(1, 2, 6, 7);
        }

        &:last-of-type {
            @include grid-area(2, 3, 6, 7);

            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit--xl * -1);
            }

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit--xl);
            }
        }

        @include mq($from: $viewport--md) {

            &:first-of-type {
                @include grid-area(1, 2, 3, 4);
            }

            &:last-of-type {
                @include grid-area(2, 3, 2, 4);
            }
        }
    }

    &-form {
        @include grid-area(1, 3, 1, 3);
        align-self: end;
        background-color: $yellow--300;
        padding: u($spacing-unit--lg, $spacing-unit);

        a:not([class]) {
            color: $white;
        }

        @include mq($from: $viewport--md) {
            @include grid-area(3, 4, 2, 4);
            border-top-left-radius: u($spacing-unit--xl);
            border-bottom-right-radius: u($spacing-unit--xl);
        }

        @include mq($until: $viewport--md - 1) {
            position: relative;
            border-bottom-left-radius: u($spacing-unit--xl);

            &::after {
                @include position(absolute, $top: 0, $bottom: 0, $left: 100%);
                background-color: $yellow--300;
                content: '';
                width: u($structure-responsive-gutter--r);
            }
        }

        .Form-field--textarea {

            @include mq($until: $viewport--md - 1) {
                max-height: u(140);
            }
        }

        .Button--secondary {
            background-color: $white;
        }
    }
}

.GoogleMaps {
    position: relative;
    height: u(725);
    width: 100%;

    @include mq($from: $viewport--md) {
        height: u(960);
    }

    &::after {
        @include position(absolute, $top: 0, $right: 0, $left: 0, $z: 1);
        background-image: linear-gradient(to bottom, rgba($white, 1) 0%, rgba($white, 0) 100%);
        content: '';
        height: u(400);
    }
}
