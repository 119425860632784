.Form {

    &--jobs {

        &::before {
            display: none;
        }

        &-title, &-subtitle {
            @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
        }

        &-title {
            color: $yellow--300;
            margin-bottom: u(-15);

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u(0);
            }
        }

        &-subtitle {
            margin-bottom: u(40);
        }

        .Form-item {
            position: relative;

            &-icon {
                width: u(80);
                margin-bottom: u($spacing-unit / 2);
            }
        }

        .Form-field {

            &--file {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }

        .File-label {
            background-color: $white;
            border-radius: u(30);
            padding: u(10) u(30);
            color: $grey--300;
            font-size: u(14);
        }
    }

    &-wrapper {
        max-width: u(1100);
        background-color: $yellow--300;
        padding: u(55) u(60);
        border-radius: u(60);
        width: 100%;

        @include mq($until: $viewport--md - 1) {
            padding: u(30) u(20);
        }
    }

    &-body {
        @include breakout();
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit);
        position: relative;

        @include mq($until: $viewport--md - 1) {
            padding-right: u($spacing-unit);
        }

        @include mq($from: $viewport--md) {
            @include breakout(right);
            padding-top: u($spacing-unit--lg);
            padding-bottom: u($spacing-unit--lg);
            padding-left: u($spacing-unit--xl);
        }

        &::before {
            background-color: $yellow--300;

            @include mq($until: $viewport--md - 1) {
                border-top-right-radius: u($spacing-unit--lg);
                border-bottom-right-radius: u($spacing-unit--lg);
            }

            @include mq($from: $viewport--md) {
                border-top-left-radius: u(77);
                border-bottom-left-radius: u(77);
            }
        }

        a:not([class]) {
            color: $white;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-object {
        background-color: $grey--200;
        border-bottom-left-radius: u($spacing-unit--xl);
        border-bottom-right-radius: u($spacing-unit--xl);
        max-width: u(506);
        margin-left: auto;
        padding: u($spacing-unit);
    }

    @include mq($until: $viewport--md - 1) {
        position: relative;
        margin-top: u($spacing-unit);

        &::before {
            @include position(absolute, u($spacing-unit--xl), u($structure-responsive-gutter--r * -1), 0, 0, -1);
            background-color: $grey--200;
            border-bottom-left-radius: u($spacing-unit--lg);
            content: '';
        }
    }
}

form {

    .Button {

        @include mq($from: $viewport--md) {
            max-width: 100%;
            width: u(160);
        }
    }
}

#brochure{
    max-width: u(360);

    .Form-label{
        display: none;
    }
}
