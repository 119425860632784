.Picture {
    display: inline-block;

    & > img,
    .Slider-item & {
        display: block;
    }

    // Styleguide
    &.is-test {
        display: block;
        padding-bottom: 100%;
    }

    // Placeholder
    &:not(.without-placeholder) {
        background-image: url('#{$path-img}default.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;

        .Picture-image {
            background-color: $white;
            width: 100%;
        }
    }
}
