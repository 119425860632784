/* Blocks */
.Block {
    margin-top: u($spacing-unit--xl * 1.5);

    @include mq($from: $viewport--md) {
        margin-top: u($spacing-unit--xl * 1.5);
    }

    + .Block {

    }

    // Header
    &-header {

        &--mobile {

            @include mq($until: $viewport--md - 1) {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin-bottom: u($spacing-unit--sm);

                .Block-title {
                    border-radius: u(40);
                    padding: u($spacing-unit / 1.5, $spacing-unit);
                    margin-bottom: 0;
                    line-height: (23 / 18);
                }

                &::before {
                    @include position(absolute, 0, u($structure-responsive-gutter--r * -1), 50%, u($structure-responsive-gutter--l * -1), -1);
                    content: '';
                }
            }
        }
    }

    //Customers

    &--customers{
       padding-bottom: u(25);

       @include mq($until: $viewport--md) {
            padding-bottom: u(50);
       }
    }

    &--serviceBanner{
        margin-top: u(48);
    }

    &--bannerNew{
        margin-top: u(130);

        @include mq($until: $viewport--lg) {
            margin-top: u(70);
        }
    }

    &--bgBanner + &--testimonials {
        margin-top: u(-240);
    }

    &--highlight{
        position: relative;
        padding-bottom: u(90);

        &::after {
            @include position(absolute, $top: u($spacing-unit), $right: 0, $bottom: 0);
            background-color: rgba($yellow--200, 0.3);
            border-bottom-left-radius: px($spacing-unit--xl);
            content: '';
            display: block;

            @include mq($from: $viewport--md) {
                width: 71.25vw;
            }

            @include mq($until: $viewport--md - 1) {
                left: u($spacing-unit * 1.5);
            }
        }
    }

    &--workflow{
        padding-bottom: u(40);

        @include mq($until: $viewport--lg) {
            padding-bottom: 0;
        }
    }

    &--homeBanner, &--bgBanner {
        @include position(relative,0,0,null,0);
        margin-top: -104px;
    }

    &--homeExpertise{
        position: relative;
        padding-bottom: u(50);

        &::after {
            @include position(absolute, $top: u($spacing-unit), $right: 0, $bottom: 0);
            background-color: rgba($yellow--200, 0.3);
            border-bottom-left-radius: px($spacing-unit--xl);
            content: '';
            display: block;

            @include mq($from: $viewport--md) {
                width: 71.25vw;
            }

            @include mq($until: $viewport--md - 1) {
                left: u($spacing-unit * 1.5);
            }
        }
    }

    &--banner{
        position: relative;
        z-index: 10;
    }

    &--caseBanner{
        margin-top: u(100);

        @include mq($until: $viewport--md) {
            margin-top: u(48);
        }
    }

    &--contact{
        margin-top: 0;

        @include mq($until: $viewport--md) {
            margin-top: u(24) !important;
        }
    }

    &--mission{
        margin-top: 120px;

        @include mq($until: $viewport--md) {
            margin-top: u(48);
        }
    }
}
