.HomeBanner{
    position: relative;
    min-height: u(788px);
    max-height: 100vh;
    padding: u(48,0);
    padding-top: u(92);
    padding-bottom: u(170);
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include mq($until: $viewport--md) {
        padding-bottom: u(72);
        padding-top: u(130);
    }

    &:after{
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(52, 52, 52, 0.8), rgba(52, 52, 52, 0.8));
        @include position(absolute,$top:0,$left:0);
        z-index: 1;
    }

    @include mq($until: $viewport--md) {
        min-height: auto;
    }

    &-image{
        @include position(absolute,$top:0,$left:0);
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &-video{
        @include position(absolute,$top:0,$left:0);
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &-title{
        font-family: $font-ibm;
        @include fluid-type(font-size,$viewport--md,$viewport--lg,40px,64px);
        display: flex;
        flex-direction: column;
        text-align: center;
        color: $white;
    }

    &-titleTop{
        font-family: $font-barlow;
        font-weight: normal;
        display: inline-block;
    }

    &-titleBottom{
        font-weight: bold;
        display: inline-block;
    }

    &-content{
        position: relative;
        z-index: 2;
        text-align: center;
    }

    &-text{
        margin-bottom: u(40);

        p{
            font-size: u(20);
            font-weight: normal;
            line-height: 1.56;
            letter-spacing: normal;
            color: $white;
        }

        p:last-of-type{
            margin-bottom: 0;
        }
    }

    &-dot{
        color: $yellow--300;
        display: inline-block;
    }
}