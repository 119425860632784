.MediaBlock{

    text-align: center;

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-2,$heading-size-1);
        margin-bottom: u(8);
        max-width: u( 650px);
        margin: 0 auto;
    }

    &-text{
        max-width: u( 650px);
        margin: 0 auto;
        p:last-of-type{
            margin-bottom: 0;
        }
    }

    &-media{
        padding-top: u(48);
    }
}