/* Logo */
.Logo {
    flex-shrink: 0;

    @include mq($from: $viewport--md) {
        margin-right: u($spacing-unit--xl);
    }

    &,
    &-object {
        display: block;
    }

    &-object {

        .Logo--icon & {
            @include dimensions(48);

            @include mq($until: $viewport--md - 1) {
                z-index: -1;

                &.is-sticky {
                    position: fixed;
                    top: u($spacing-unit - 7);
                }
            }

            @include mq($from: $viewport--md) {
                @include dimensions(72);
            }
        }
    }

    &--icon {

        @include mq($until: $viewport--md - 1) {

            body:not(.is-compact):not(.has-logo) &:not(.has-sticky) {
                // @include position(absolute, $left: u($structure-responsive-gutter--l));
                // transform: translateY(#{u(-100)}) translateY(-50%);
                // top: u(336);
            }

            body.HomePage:not(.is-compact):not(.has-logo) &:not(.has-sticky) {
                // top: 73.275vh
            }
        }
    }

    &--footer {
        @include dimensions(47.25, 30);
        margin-bottom: u($spacing-unit);

        @include mq($from: $viewport--md) {
            @include dimensions(78.75, 50);
        }
    }

    &--nav {

        @include mq($until: $viewport--md - 1) {
            @include dimensions(78.75, 50);
        }
    }

    &-header{
        width: 85px !important;
        height: 55px;
    }

    &-isNotTopWhite{
        display: block;

        header.is-notTop & {
            display: none;
        }
    }

    &-isNotTopBlack{
        display: none;

        header.is-notTop & {
            display: block;
        }
    }
}
