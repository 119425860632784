/* Benefits */
.Benefits {

    &-title {

        @include mq($until: $viewport--md - 1) {
            text-align: center;
        }
    }
}

.Benefit {
    background-color: $grey--200;
    display: flex;
    align-items: flex-end;
    max-width: u(420);

    + .Benefit {
        margin-top: u(40);
    }

    &-body {
        padding: u($spacing-unit);

        @include mq($from: $viewport--md) {
            padding-top: u($spacing-unit--sm);
            padding-right: u($spacing-unit--sm);
            padding-bottom: u(40);

            .Benefit:first-of-type & {
                padding-bottom: u($spacing-unit--sm);
            }
        }
    }

    &-object {
        flex-shrink: 0;

        @include mq($from: $viewport--md) {
            padding-right: u($spacing-unit);
            padding-left: u($spacing-unit);
        }
    }

    &:nth-of-type(odd) {
        margin-right: u($spacing-unit--xl);
        border-top-right-radius: u($spacing-unit--sm);
        border-bottom-left-radius: u($spacing-unit--sm);

        @include mq($until: $viewport--md - 1) {

            .Benefit-body {
                padding-right: u($spacing-unit--xs);
            }
        }

        @include mq($from: $viewport--md) {
            margin-right: u($spacing-unit--xl * 2);
        }

        .Benefit-object {
            margin-right: u($spacing-unit--lg * -1);

            @include mq($from: $viewport--md) {
                margin-right: u($spacing-unit--xl * -1);
            }
        }
    }

    &:nth-of-type(even) {
        flex-direction: row-reverse;
        margin-left: u($spacing-unit--xl);
        border-top-left-radius: u($spacing-unit--sm);
        border-bottom-right-radius: u($spacing-unit--sm);

        @include mq($until: $viewport--md - 1) {

            .Benefit-body {
                padding-left: u($spacing-unit--xs);
            }
        }

        @include mq($from: $viewport--md) {
            margin-left: u($spacing-unit--xl * 2);
        }

        .Benefit-object {
            margin-left: u($spacing-unit--lg * -1);

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit--xl * -1);
            }
        }
    }
}
