/* Header */
.Header {
    @include position(absolute, $top: 0, $right: 0, $left: 0, $z: 4);
    padding-top: u(17);
    padding-bottom: u($spacing-unit);

    @include mq($from: $viewport--md) {
        @include transition(#{background-color, transform, padding});
        position: fixed;
        will-change: transform;

        &.is-pinned {
            transform: translateY(0%);
        }

        &.is-unpinned {
            transform: translateY(-100%);
        }

        &.is-notTop {
            background-color: rgba($white, 0.9);
            color: $grey--500;
            padding-top: u($spacing-unit--xs);
            padding-bottom: u($spacing-unit--xs);

            .Nav-link {

                body.HomePage & {
                    color: $grey--500;

                    &:hover {
                        color: $yellow--300;
                    }
                }
            }
        }
    }

    @include mq($from: $viewport--md) {
        padding-top: u($spacing-unit--sm);
        padding-bottom: u($spacing-unit--sm);
    }

    > .Container {
        display: flex;
        align-items: center;
        position: static;
    }

    // ToggleNav
    @include mq($until: $viewport--md - 1) {

        &::after {
            @include transition(#{opacity, visibility});
            background-color: rgba($black, 0.2);
            content: '';

            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: -1;

            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
