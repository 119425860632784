.TextImage{

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-2,$heading-size-1);
        margin-bottom: u(8);
    }

    &-subTitle{
        font-size: u(16);
        line-height: 25px;
        font-style: italic;
    }

    &-image{
        border-top-left-radius: u(32);
        border-bottom-left-radius: u(32);

        @include mq($until: $viewport--lg) {
           border-radius: u(32);
           width: 100%;
        }
    }

    &-object{

        @include mq($until: $viewport--lg) {
            width: 100%;
        }
    }

    &-titleContainer{
        padding-bottom: u(24);
        position: relative;
    }

    &-contentContainer{
        display: flex;
        flex-direction: column;
        padding-right: u(110);

        @include mq($until: $viewport--md) {
            padding-right: 0;
        }
    }

    &-itemTitle{
        font-weight: bold;
        font-family: $font-ibm;
        display: inline-block;
        z-index: 2;
        font-size: u(18);
        line-height: 23px;
        position: relative;
    }

    &-backgroundTitle{
        position: absolute;
        top: 5px;
        z-index: 1;
    }

}