.Brands {
    &-heading{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-5,$heading-size-4);
    }

    &-items {
        display: flex;
        flex-direction: column;
        @include mq($from: $viewport--md) {
            margin-top: 3rem;
        }

        .Brands-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            gap: u($spacing-unit--xl);

            @include mq($until: $viewport--md) {
                flex-direction: column;
                gap: 0;
            }


            &:not(:last-of-type) {
                margin-bottom: u($spacing-unit--xl);
                @include mq($from: $viewport--lg) {
                    margin-bottom: 6rem;
                }
            }

            picture {
                width: 300px;
                height: auto;
                //background-color: $grey--200;
                //border-radius: 0px 42px 0px 62px;
                padding: 0 u($spacing-unit--xl);
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    max-width: 300px;
                }

                @include mq($until: $viewport--md) {
                    width: 100%;
                    margin-bottom: u($spacing-unit--md);
                }
            }
    
            .Brands-body {
                width: calc(100% - 300px);

                @include mq($until: $viewport--md) {
                    width: 100%;
                }
    
                .Brands-title {
                    color: $yellow--300;
                }

                .Brands-link {
                    color: $grey--500;
                    font-weight: 600;
                }
            }
        }


    }
}