/* Notification */
.Notification {
    @include dimensions(18);
    background-color: $green--300;
    border-color: transparent;
    border-radius: $notification-border-radius;
    border-style: solid;
    border-width: px($notification-border-width);
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: if($notification-font-size == $font-size, null, u($notification-font-size));
    font-weight: 500;
    text-align: $notification-text-align;

    .Nav-item & {
        position: absolute;
        bottom: 75%;
        left: 100%;
        transform: translateX(#{u(2px)});
    }
}
