.ServiceColumns{

    .Grid{
        margin-left: u(-80);
    }

    .Grid-cell{
        padding-left: 80px;
    }

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-5,$heading-size-4);
        margin-bottom: u(8);
    }

    &-content{
        position: relative;
        z-index: 2;
    }

    &-header{
        padding-bottom: u($spacing-unit--sm);
    }

    &--grey{
        background: $grey--200;
        padding:u(48,97);
        border-top-left-radius: u(48);
        border-bottom-right-radius: u(48);
        position: relative;

        @include mq($until: $viewport--lg) {
            padding:u(48,48);
        }

        @include mq($until: $viewport--md) {
            padding:u(48,24);
        }
    }

    &-subTitle{
        font-size: u($heading-size-6);
        line-height: 25px;
        font-style: italic;
    }

    &-titleContainer{
        position: relative;
    }

    &-itemTitle{
        font-weight: bold;
        font-family: $font-ibm;
        display: inline-block;
        position: relative;
        z-index: 2;

        &--grey{
            font-size: u($heading-size-6);
            line-height: 23px;
            position: relative;
        }

        &--white{
            font-size: u($heading-size-6);
            line-height: 26px;
            color: $green--400;
        }
    }

    &-backgroundTitle{
        position: absolute;
        top: 5px;
        z-index: 1;
    }

    &-text{
        p{
            font-size: u(16);
            line-height: 150%;

            strong{
                font-weight: 700;
            }

            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    &-item{
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &-itemTextBottom{
        margin-top: auto;
        padding-top: u(40);

        strong{
            font-weight: 700;
        }

        p:last-of-type{ margin-bottom: 0;}
    }
}