/* Chip */
.Chip {
    @include transition(#{background-color, color});
    border-color: transparent;
    border-radius: if($chip-border-radius > 0, px($chip-border-radius), 0);
    border-style: solid;
    border-width: px($chip-border-width);
    cursor: pointer;
    display: inline-block;
    font-size: if($chip-font-size == $font-size, null, u($chip-font-size));
    padding: em($chip-padding--t, $chip-padding--r, $chip-padding--b, $chip-padding--l);
    position: relative;
    text-align: $chip-text-align;
    user-select: none;

    &::after {
        @include transition(transform);
        @include position(absolute, 0, 0, 0, 0, -1);
        animation: 0;
        border-radius: if($button-border-radius > 0, px($button-border-radius), 0);
        content: '';
    }

    &,
    &:active,
    &:focus {
        background-color: $grey--200;
        color: $black;
    }

    &,
    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    &.is-active {
        background-color: $yellow--300;
        color: $white;

        &::after {
            background-color: $yellow--100;
        }
    }

    @media (hover: hover) {

        &:hover {
            background-color: $yellow--300;
            color: $white;

            &::after {
                background-color: $yellow--100;
            }
        }
    }

    &:hover {

        &::after {
            animation: button 800ms linear 2;
        }
    }

    &.is-disabled {
        color: $grey--300;
        cursor: default;
        pointer-events: none;
    }
}
