/* Button */

.Button {

    &:not(.Button--bare):not(.Button--icon):not(.is-disabled) {

        &::after {
            @include transition(transform);
            @include position(absolute, 0, 0, 0, 0, -1);
            animation: 0;
            border-radius: if($button-border-radius > 0, px($button-border-radius), 0);
            content: '';
        }

        &:hover {

            &::after {
                animation: button 800ms linear 1;
                transform: rotate(5.14deg);
            }
        }

        &.is-hovered {

            &::after {
                transform: rotate(-5.14deg);
            }
        }
    }

    // Download


    &--download{
        width: auto !important;
    }

    // Primary
    &--primary {

        &,
        &:active,
        &:focus,
        &:hover,
        &.is-active {
            background-color: $yellow--300;
        }

        &.is-disabled {
            background-color: $grey--300;
        }

        &:not(.Button--bare):not(.Button--icon):not(.is-disabled) {

            &::after {
                background-color: $yellow--100;
            }
        }
    }

    // Secondary
    &--secondary {

        &,
        &:active,
        &:focus,
        &:hover,
        &.is-active {
            background-color: $white;
        }

        &.is-disabled {
            background-color: $grey--200;
        }

        &:not(.Button--bare):not(.Button--icon):not(.is-disabled) {

            &::after {
                background-color: $yellow--100;
            }
        }
    }

    // Tertiary
    &--tertiary {
        color: $white;

        &,
        &:active,
        &:focus,
        &:hover,
        &.is-active {
            background-color: $green--300;
        }

        &.is-disabled {
            background-color: $green--600;
        }

        &:not(.Button--bare):not(.Button--icon):not(.is-disabled) {

            &::after {
                background-color: $green--200;
            }
        }
    }

    // Icon
    &--icon {
        padding: em($spacing-unit--xs / 2, $spacing-unit / 1.5);

        &,
        &:active,
        &:focus,
        &:hover,
        &.is-active {
            background-color: $green--300;
            color: $white;
        }

        .Button-icon {
            font-size: u(24px);
            display: block;
            animation: 0;

            .Article:hover & {
                animation: icon 1s linear 1;
            }
        }
    }

    // Info
    &--info {
        background-color: $blue;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $blue--600;
                color: $white;
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--600;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $green--600;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $green--600;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $orange--600;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $orange--600;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $red--600;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $red--600;
            }
        }
    }

    // Outline
    &--outline {
        background-color: transparent;
        border-color: currentColor;
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;

        &,
        &:active,
        &.is-active,
        &:focus,
        &.is-focused {
            color: currentColor;
        }

        &:hover,
        &.is-hovered {
            color: $green--300;
        }

        &.is-disabled {
            color: $grey--300;
        }

        &:hover {

            .Button-icon {
                animation: icon 1s linear 1;

                .Block--service & {
                    animation-name: icon-reverse;
                }
            }
        }
    }

    // Bare GReen

    &--bareGreen{
        color: $green--300;
        font-weight: bold;
    }

    // has-icon
    &.has-icon {
        display: inline-flex;
        align-items: center;
    }

    // Button-icon
    &-icon {
        @include dimensions(1em);
        fill: currentColor;
        flex-shrink: 0;

        .has-icon & {
            // @include position(relative, $top: 0.125em);
            margin-left: u($spacing-unit--xs);
        }

        .Block--service & {
            @include position(relative, $top: .125em);
        }
    }
}
