.Cta{
    background: $yellow--300;
    padding: u(88,24);
    border-top-left-radius: u(48);
    border-bottom-right-radius: u(48);
    text-align: center;
    position: relative;

    @include mq($until: $viewport--md) {
        padding: u(48,24);
    }

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-5,$heading-size-4);
    }

    &-text{
        margin: 0 auto;
        max-width: u(475);
        font-size: 16px;
        font-weight: normal;;
        line-height: 1.56;
        color: $grey--500;
        text-align: center;
    }

    &-link{
            position: relative;
            z-index: 5;
    }

    &::after {
        @include transition(transform);
        @include position(absolute, $top: 0, $bottom: 0,$left:0, $z: -1);
        animation: 0;
        background-color: $yellow--100;
        width: 100%;
        height: 100%;
        border-top-left-radius: u(48);
        border-bottom-right-radius: u(48);
        content: '';
    }

    &:hover {

        &::after {
            animation: callToAction 800ms linear 1;
            transform: rotate(1.82deg);
        }
    }
}