@charset "UTF-8";
/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: Palette */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Components: Fields */
/* Components: Cookie */
/* Components: Chip */
/* Components: Modal */
/* Components: Notification */
/* Components: DigitalPulse */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: Units */
/* Functions: String */
/* Functions: SVG */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Breakout */
/* Mixin: Carret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.5625;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  scroll-behavior: smooth; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* HR */
hr {
  margin-top: 24px;
  margin-bottom: 23px;
  color: #bababa;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #00d198;
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    color: #00d198;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 32px;
  padding-left: 0px; }
  ol li,
  ul li {
    margin-bottom: 2px; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #000000;
  background-color: #fce064; }

/* Heading */
h1, .Alfa,
h2, .Bravo,
h3, .Charlie,
h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  font-family: "IBM Plex Serif", serif; }
  h1 small, .Alfa small,
  h2 small, .Bravo small,
  h3 small, .Charlie small,
  h4 small, .Delta small,
  h5 small, .Echo small,
  h6 small, .Foxtrot small {
    font-weight: normal; }

h1, .Alfa {
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.2; }
  @media screen and (min-width: 48em) and (max-width: 82.9375em) {
    h1, .Alfa {
      font-size: 40px;
      line-height: 1.2; } }
  @media screen and (min-width: 83em) {
    h1, .Alfa {
      font-size: 48px;
      line-height: 1.2; } }

h2, .Bravo {
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 1.2; }
  @media screen and (min-width: 48em) and (max-width: 82.9375em) {
    h2, .Bravo {
      font-size: 24px; } }
  @media screen and (min-width: 83em) {
    h2, .Bravo {
      font-size: 36px;
      line-height: 1.2; } }

h3, .Charlie {
  margin-bottom: 24px;
  font-size: 22px; }
  @media screen and (min-width: 48em) and (max-width: 82.9375em) {
    h3, .Charlie {
      font-size: 24px; } }
  @media screen and (min-width: 83em) {
    h3, .Charlie {
      font-size: 28px;
      line-height: 1.2; } }

h4, .Delta {
  margin-bottom: 24px;
  font-size: 20px; }

h5, .Echo {
  margin-bottom: 24px;
  font-size: 18px; }

h6, .Foxtrot {
  margin-bottom: 24px;
  font-size: 16px; }

.Giga {
  margin-bottom: 25px;
  font-size: 96px;
  line-height: 1.1; }

.Mega {
  margin-bottom: 25px;
  font-size: 72px;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 25px;
  font-size: 48px;
  line-height: 1.2; }

.Milli {
  margin-bottom: 25px;
  font-size: 14px; }

.Micro {
  margin-bottom: 25px;
  font-size: 12px; }

/* Paragraph */
p {
  margin-bottom: 24px; }

.Lead {
  font-size: 18px; }

/* Main */
html {
  background-color: #ffffff;
  color: #343434;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  overflow-x: hidden; }
  html.fonts-loaded {
    font-family: "Barlow", sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%;
  overflow-x: hidden; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 24px;
  padding: 1em 1em 1em 1em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 32px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 24px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 36px;
  border-style: solid;
  border-width: 0px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.5em 1em 0.5em 1em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button.is-active, .Button:focus, .Button.is-focused, .Button:hover, .Button.is-hovered {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--5by3:before {
  padding-bottom: 60%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 24px; }

.Form-label {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 8px;
  line-height: 1.35714; }
  .Form-item:not(.is-required) .Form-label::after {
    font-size: 75%;
    content: attr(data-optional);
    margin-left: 8px; }
  .Form-item.has-error .Form-label {
    color: #cc2200; }
  .Form-item.is-success .Form-label {
    color: #00d198; }

.Form-help {
  color: #bababa;
  font-size: 14px;
  display: block;
  margin-top: 8px;
  line-height: 1.29; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 16px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #d6d6d6; }

.Form-item.has-error .Form-help--validation {
  color: #cc2200; }

.Form-item.is-success .Form-help--validation {
  color: #00d198; }

.Form-controls {
  position: relative;
  z-index: 1; }
  .Form-controls.with-icon {
    color: #d6d6d6; }
    .Form-controls.with-icon .Icon {
      position: absolute;
      top: 12.5px;
      left: 15px;
      z-index: 1;
      user-select: none;
      pointer-events: none; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 8px;
    margin-bottom: 0;
    padding-right: 24px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-top: -8px;
  margin-left: -8px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-top: 8px;
    padding-left: 8px; }

.Grid--withSmallGutter {
  margin-top: -16px;
  margin-left: -16px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-top: 16px;
    padding-left: 16px; }

.Grid--withGutter {
  margin-top: -24px;
  margin-left: -24px; }
  .Grid--withGutter > .Grid-cell {
    padding-top: 24px;
    padding-left: 24px; }

.Grid--withLargeGutter {
  margin-top: -40px;
  margin-left: -40px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-top: 40px;
    padding-left: 40px; }

.Grid--withExtraLargeGutter {
  margin-top: -48px;
  margin-left: -48px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-top: 48px;
    padding-left: 48px; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto; }

.Nav-item {
  margin: 0; }

.Nav-link {
  user-select: none;
  display: block; }
  .Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
    text-decoration: none; }

.Nav-toggle {
  cursor: pointer; }
  .Nav-toggle .Toggle-icon {
    display: block;
    fill: currentColor; }

.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap; }

.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto; }

.Nav--alignCenter {
  text-align: center; }
  .Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: center; }

.Nav--alignRight {
  text-align: right; }
  .Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: flex-end; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1296px;
  padding-right: 24px;
  padding-left: 24px;
  position: relative;
  width: 100%; }
  .Container--xl {
    max-width: 1488px; }
  .Container--lg {
    max-width: 1448px; }
  .Container--md {
    max-width: 1296px; }
  .Container--sm {
    max-width: 848px; }
  .Container--xs {
    max-width: 648px; }
  @media screen and (min-width: 48em) {
    .Container {
      max-width: 1328px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1480px; }
      .Container--md {
        max-width: 1328px; }
      .Container--sm {
        max-width: 880px; }
      .Container--xs {
        max-width: 680px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  grid-area: header;
  position: relative; }
  @media screen and (min-width: 48em) {
    .Header {
      height: 104px; } }
  .Header > .Container {
    height: 100%; }

.Footer {
  grid-area: footer;
  position: relative; }
  .Footer > .Container {
    height: 100%; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main;
  margin-top: 104px; }
  .Main > .Container {
    padding-top: 32px;
    padding-bottom: 48px; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        padding-top: 72px;
        padding-bottom: 72px; } }
    .Main > .Container :last-child {
      margin-bottom: 0; }
    .Main > .Container:last-child {
      margin-bottom: -48px; }
      @media screen and (min-width: 48em) {
        .Main > .Container:last-child {
          margin-bottom: -72px; } }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 32px; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 24px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #bababa;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 5.33333px 8px 5.33333px 8px; }
  .Table thead {
    background-color: #d6d6d6;
    border-bottom: 2px solid #bababa; }
  .Table tbody tr + tr {
    border-top: 1px solid #bababa; }
  .Table tfoot {
    background-color: #d6d6d6;
    border-top: 2px solid #bababa; }
  @media screen and (max-width: 63.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #bababa;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #bababa;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -16px -16px 16px;
          padding: 16px;
          background-color: #f5f5f5;
          border-top: 1px solid #bababa;
          border-bottom: 1px solid #bababa; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #f5f5f5; }

@media screen and (max-width: 63.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

/* Alert */
.Alert--success {
  color: #2a7d66;
  background-color: #e1fbf4;
  border-color: #ccf8ec; }
  .Alert--success a {
    color: #1f5e4d; }

.Alert--info {
  color: #455e99;
  background-color: #e8edf9;
  border-color: #d6def4; }
  .Alert--info a {
    color: #344773; }

.Alert--warning {
  color: #aa7139;
  background-color: #fbf1e6;
  border-color: #f8e5d1; }
  .Alert--warning a {
    color: #7f552b; }

.Alert--danger {
  color: #86382d;
  background-color: #fbe5e2;
  border-color: #f8d3cd; }
  .Alert--danger a {
    color: #652a22; }

/* Button */
.Button:not(.Button--bare):not(.Button--icon):not(.is-disabled)::after {
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  animation: 0;
  border-radius: 36px;
  content: ''; }

.Button:not(.Button--bare):not(.Button--icon):not(.is-disabled):hover::after {
  animation: button 800ms linear 1;
  transform: rotate(5.14deg); }

.Button:not(.Button--bare):not(.Button--icon):not(.is-disabled).is-hovered::after {
  transform: rotate(-5.14deg); }

.Button--download {
  width: auto !important; }

.Button--primary, .Button--primary:active, .Button--primary:focus, .Button--primary:hover, .Button--primary.is-active {
  background-color: #fad018; }

.Button--primary.is-disabled {
  background-color: #d6d6d6; }

.Button--primary:not(.Button--bare):not(.Button--icon):not(.is-disabled)::after {
  background-color: #fdefb0; }

.Button--secondary, .Button--secondary:active, .Button--secondary:focus, .Button--secondary:hover, .Button--secondary.is-active {
  background-color: #ffffff; }

.Button--secondary.is-disabled {
  background-color: #f5f5f5; }

.Button--secondary:not(.Button--bare):not(.Button--icon):not(.is-disabled)::after {
  background-color: #fdefb0; }

.Button--tertiary {
  color: #ffffff; }
  .Button--tertiary, .Button--tertiary:active, .Button--tertiary:focus, .Button--tertiary:hover, .Button--tertiary.is-active {
    background-color: #00d198; }
  .Button--tertiary.is-disabled {
    background-color: #005f44; }
  .Button--tertiary:not(.Button--bare):not(.Button--icon):not(.is-disabled)::after {
    background-color: #54e0ba; }

.Button--icon {
  padding: 0.25em 1em; }
  .Button--icon, .Button--icon:active, .Button--icon:focus, .Button--icon:hover, .Button--icon.is-active {
    background-color: #00d198;
    color: #ffffff; }
  .Button--icon .Button-icon {
    font-size: 24px;
    display: block;
    animation: 0; }
    .Article:hover .Button--icon .Button-icon {
      animation: icon 1s linear 1; }

.Button--info {
  background-color: #1b4dc3;
  color: #ffffff; }
  .Button--info:not(.Button--outline):active, .Button--info:not(.Button--outline):focus, .Button--info:not(.Button--outline):hover, .Button--info:not(.Button--outline).is-active {
    background-color: #082978;
    color: #ffffff; }
  .Button--info.Button--outline {
    color: #1b4dc3; }
    .Button--info.Button--outline:active, .Button--info.Button--outline:focus, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-active {
      color: #082978; }

.Button--success {
  background-color: #00a779;
  color: #ffffff; }
  .Button--success:not(.Button--outline):active, .Button--success:not(.Button--outline):focus, .Button--success:not(.Button--outline):hover, .Button--success:not(.Button--outline).is-active {
    background-color: #005f44;
    color: #ffffff; }
  .Button--success.Button--outline {
    color: #00a779; }
    .Button--success.Button--outline:active, .Button--success.Button--outline:focus, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-active {
      color: #005f44; }

.Button--warning {
  background-color: #e27101;
  color: #ffffff; }
  .Button--warning:not(.Button--outline):active, .Button--warning:not(.Button--outline):focus, .Button--warning:not(.Button--outline):hover, .Button--warning:not(.Button--outline).is-active {
    background-color: #af4a00;
    color: #ffffff; }
  .Button--warning.Button--outline {
    color: #e27101; }
    .Button--warning.Button--outline:active, .Button--warning.Button--outline:focus, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-active {
      color: #af4a00; }

.Button--danger {
  background-color: #b31700;
  color: #ffffff; }
  .Button--danger:not(.Button--outline):active, .Button--danger:not(.Button--outline):focus, .Button--danger:not(.Button--outline):hover, .Button--danger:not(.Button--outline).is-active {
    background-color: #880300;
    color: #ffffff; }
  .Button--danger.Button--outline {
    color: #b31700; }
    .Button--danger.Button--outline:active, .Button--danger.Button--outline:focus, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-active {
      color: #880300; }

.Button--outline {
  background-color: transparent;
  border-color: currentColor; }

.Button--large {
  font-size: 20px;
  padding-right: 32px;
  padding-left: 32px; }

.Button--small {
  font-size: 12px;
  padding-right: 10.66667px;
  padding-left: 10.66667px; }

.Button--full {
  display: block;
  width: 100%; }

.Button--bare {
  border: 0;
  border-radius: 0;
  padding: 0; }
  .Button--bare, .Button--bare:active, .Button--bare.is-active, .Button--bare:focus, .Button--bare.is-focused {
    color: currentColor; }
  .Button--bare:hover, .Button--bare.is-hovered {
    color: #00d198; }
  .Button--bare.is-disabled {
    color: #d6d6d6; }
  .Button--bare:hover .Button-icon {
    animation: icon 1s linear 1; }
    .Block--service .Button--bare:hover .Button-icon {
      animation-name: icon-reverse; }

.Button--bareGreen {
  color: #00d198;
  font-weight: bold; }

.Button.has-icon {
  display: inline-flex;
  align-items: center; }

.Button-icon {
  height: 1em;
  width: 1em;
  fill: currentColor;
  flex-shrink: 0; }
  .has-icon .Button-icon {
    margin-left: 8px; }
  .Block--service .Button-icon {
    position: relative;
    top: 0.125em; }

/* Chip */
.Chip {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  border-color: transparent;
  border-radius: 20.5px;
  border-style: solid;
  border-width: 0px;
  cursor: pointer;
  display: inline-block;
  padding: 0.5em 1em 0.5em 1em;
  position: relative;
  text-align: center;
  user-select: none; }
  .Chip::after {
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    animation: 0;
    border-radius: 36px;
    content: ''; }
  .Chip, .Chip:active, .Chip:focus {
    background-color: #f5f5f5;
    color: #000000; }
  .Chip, .Chip:active, .Chip:focus, .Chip:hover {
    text-decoration: none; }
  .Chip.is-active {
    background-color: #fad018;
    color: #ffffff; }
    .Chip.is-active::after {
      background-color: #fdefb0; }
  @media (hover: hover) {
    .Chip:hover {
      background-color: #fad018;
      color: #ffffff; }
      .Chip:hover::after {
        background-color: #fdefb0; } }
  .Chip:hover::after {
    animation: button 800ms linear 2; }
  .Chip.is-disabled {
    color: #d6d6d6;
    cursor: default;
    pointer-events: none; }

.Modal {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10; }
  .Modal, .Modal:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Modal::before {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    z-index: -1; }
  .Modal:not(.is-active) {
    opacity: 0;
    visibility: hidden; }
  .Modal-inner {
    display: flex;
    align-items: center;
    min-height: 100%; }
  .Modal-paddingRn {
    padding-right: 0 !important; }
  .Modal-gridCell {
    padding-top: 0 !important; }
  .Modal-innerBody {
    background-color: #ffffff;
    border-radius: 0;
    width: auto;
    max-width: none !important; }
    @media screen and (min-width: 37.5em) {
      .Modal-innerBody {
        margin: 32px auto;
        max-width: 500px;
        width: 100%; } }
    @media screen and (max-width: 64em) {
      .Modal-innerBody {
        padding: 24px; } }
  .Modal-header {
    border-bottom: 1px solid #d6d6d6;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 16px 16px 16px; }
  .Modal-title {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 0; }
  .Modal-close {
    font-size: 24px;
    transition-property: opacity;
    transition-duration: visibility;
    transition-timing-function: linear;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    padding: 16px 16px 16px 16px;
    margin: -16px -16px -16px auto; }
    html:not(.is-tabbing) .Modal-close:focus {
      outline: 0; }
    .Modal-close:not(:hover) {
      opacity: .5; }
  .Modal-body {
    padding: 1em 1em 1em 1em; }
  .Modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1em 1em 1em 1em;
    border-top: 1px solid #d6d6d6; }

/* Notification */
.Notification {
  height: 18px;
  width: 18px;
  background-color: #00d198;
  border-color: transparent;
  border-radius: 100% 100% 100% 0;
  border-style: solid;
  border-width: 0px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  text-align: center; }
  .Nav-item .Notification {
    position: absolute;
    bottom: 75%;
    left: 100%;
    transform: translateX(2px); }

/* Call To Action */
.CallToAction {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center; }
  .CallToAction, .CallToAction:active, .CallToAction.is-active, .CallToAction:focus, .CallToAction.is-focused, .CallToAction:hover, .CallToAction.is-hovered {
    color: #000000;
    text-decoration: none; }
  @media screen and (min-width: 48em) {
    .CallToAction {
      max-width: 615px;
      padding-top: 36px;
      padding-bottom: 36px; }
      .CallToAction::before {
        background-color: #00d198; }
      .CallToAction::after {
        transition-property: transform;
        transition-duration: 200ms;
        transition-timing-function: linear;
        width: calc(100% + 48px); } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 82.9375em) {
      .CallToAction::after {
        width: calc(100% + 64px); } }
    @media screen and (min-width: 48em) and (min-width: 83em) {
      .CallToAction::after {
        width: calc((100vw - 1248px) / 2 + 100% + 24px); } }
  @media screen and (min-width: 48em) {
      .CallToAction, .CallToAction:active, .CallToAction.is-active, .CallToAction:focus, .CallToAction.is-focused, .CallToAction:hover, .CallToAction.is-hovered {
        color: #ffffff; }
      .CallToAction--left {
        padding-right: 24px; }
        .CallToAction--left::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          content: '';
          width: calc(100% + 24px); } }
      @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 82.9375em) {
        .CallToAction--left::before {
          width: calc(100% + 40px); } }
      @media screen and (min-width: 48em) and (min-width: 83em) {
        .CallToAction--left::before {
          width: calc((100vw - 1248px) / 2 + 100%); } }
  @media screen and (min-width: 48em) {
        .CallToAction--left::before, .CallToAction--left::after {
          border-top-right-radius: 92.5px;
          border-bottom-right-radius: 92.5px; }
        .CallToAction--left::before {
          z-index: 0; }
        .CallToAction--left::after {
          right: 0; }
      .CallToAction--right {
        margin-left: auto;
        flex-direction: row-reverse;
        padding-left: 24px; }
        .CallToAction--right::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          content: '';
          width: calc(100% + 24px); } }
      @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 82.9375em) {
        .CallToAction--right::before {
          width: calc(100% + 40px); } }
      @media screen and (min-width: 48em) and (min-width: 83em) {
        .CallToAction--right::before {
          width: calc((100vw - 1248px) / 2 + 100%); } }
  @media screen and (min-width: 48em) {
        .CallToAction--right::before, .CallToAction--right::after {
          border-top-left-radius: 92.5px;
          border-bottom-left-radius: 92.5px; }
        .CallToAction--right::before {
          z-index: 0; }
        .CallToAction--right::after {
          left: 0; } }
  .CallToAction-title {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.4375; }
    @media screen and (min-width: 48em) {
      .CallToAction-title {
        font-size: 20px;
        line-height: 1.3; } }
  @media screen and (min-width: 48em) {
    .CallToAction-body {
      position: relative;
      z-index: 1;
      flex-grow: 1; } }
  @media screen and (max-width: 47.9375em) {
    .CallToAction-body {
      margin-right: 16px; } }
  @media screen and (max-width: 47.9375em) {
    .CallToAction-object {
      background-color: #00d198;
      border-top-left-radius: 92.5px;
      border-bottom-left-radius: 92.5px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 16px 30px 16px 16px;
      margin-right: -24px;
      margin-left: auto;
      width: 145px; } }
  .CallToAction-objectItem {
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 48em) {
      .CallToAction-objectItem {
        height: 59px;
        width: 59px; } }
  @media screen and (min-width: 48em) {
    .CallToAction-object {
      position: relative;
      z-index: 1; }
      .CallToAction--right .CallToAction-object {
        margin-right: 40px; }
      .CallToAction--left .CallToAction-object {
        margin-left: 40px; } }
  .CallToAction-icon {
    height: 24px;
    width: 24px;
    color: #00d198;
    fill: currentColor; }
    @media screen and (min-width: 48em) {
      .CallToAction-icon {
        height: 36px;
        width: 36px; } }
  .CallToAction-arrow {
    color: #ffffff;
    fill: currentColor;
    margin-left: auto; }
    @media screen and (min-width: 48em) {
      .CallToAction-arrow {
        display: none; } }
  .CallToAction::after {
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    animation: 0;
    background-color: #a8efdc;
    content: ''; }
  .CallToAction:hover::after {
    animation: callToAction 800ms linear 1;
    transform: rotate(1.82deg); }
  .CallToAction.is-hovered::after {
    transform: rotate(1.82deg); }

/* Quote */
.Quote {
  text-align: right;
  max-width: 505px;
  margin-left: auto; }
  .Quote-body {
    color: #ffffff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    position: relative; }
    .Quote-body::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      width: calc(100% + 24px); }
      @media screen and (min-width: 48em) and (max-width: 82.9375em) {
        .Quote-body::before {
          width: calc(100% + 40px); } }
      @media screen and (min-width: 83em) {
        .Quote-body::before {
          width: calc((100vw - 1248px) / 2 + 100%); } }
    @media screen and (min-width: 48em) {
      .Quote-body {
        font-family: "IBM Plex Serif", serif;
        font-weight: 700;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 65px;
        line-height: 1.4375; } }
    .Quote-body::before {
      background-color: #00d198; }
    .Quote-body > :last-child {
      margin-bottom: 0; }
    .Quote-body::before, .Quote-body::after {
      border-top-left-radius: 61.5px;
      border-bottom-left-radius: 61.5px; }
      @media screen and (min-width: 48em) {
        .Quote-body::before, .Quote-body::after {
          border-top-left-radius: 69.5px;
          border-bottom-left-radius: 69.5px; } }
    .Quote-body::after {
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: absolute;
      top: 0;
      right: -2%;
      bottom: 0;
      left: 0;
      z-index: -2;
      animation: 0;
      background-color: #a8efdc;
      content: '';
      width: calc(100% + 24px); }
      @media screen and (min-width: 48em) and (max-width: 82.9375em) {
        .Quote-body::after {
          width: calc(100% + 40px); } }
      @media screen and (min-width: 83em) {
        .Quote-body::after {
          width: calc((100vw - 1248px) / 2 + 100%); } }
  .Quote-author {
    display: block;
    margin-top: 8px;
    margin-left: 24px; }

/* Article */
.Article {
  /* Default */
  /* Alt */ }
  .Article-jobImage {
    padding-top: 24px;
    padding-left: 24px; }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Article-jobImage {
        width: 50%; } }
    @media screen and (min-width: 64em) {
      .Article-jobImage {
        width: 33.33333%; } }
    .Article-jobImage .ArticleJob-image {
      border-radius: 0px 48px;
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .Article-jobImage .ArticleJob-object {
      display: block;
      height: 100%; }
  .Article-link {
    display: block; }
    .Article-link, .Article-link:active, .Article-link:focus, .Article-link:hover {
      color: currentColor;
      text-decoration: none; }
  .Article-object {
    overflow: hidden;
    position: relative; }
    .Article-object.Picture {
      display: block; }
  .Article-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
  .Article-body {
    position: relative;
    padding: 24px; }
  .Article-categories {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.32;
    letter-spacing: normal;
    color: #bababa; }
  .Article-cat {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.32;
    letter-spacing: normal;
    color: #bababa; }
  .Article-work {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    color: #343434;
    margin-bottom: 4px; }
  .Article-innerBody :last-child {
    margin-bottom: 0; }
  .Article-header {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column; }
  .Article-author {
    display: flex;
    align-items: center; }
    .Article-author .Avatar-object img {
      border-radius: 100%; }
  .Article-title {
    margin-bottom: 5px !important;
    color: #050505 !important; }
    .Article-title:not(:only-child) {
      margin-top: 16px; }
  .Article-date {
    color: #d6d6d6;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32; }
    @media screen and (max-width: 47.9375em) {
      .Article-author .Article-date {
        font-size: 12px; } }
  .Article--1:not(.Article--alt) .Article-body::before {
    right: 48px;
    left: -16px; }
  .Article--1:not(.Article--alt) .Article-object {
    border-radius: 0px 16px 16px 0px; }
  .Article--2:not(.Article--alt) .Article-body::before {
    right: -16px;
    left: -16px; }
  .Article--2:not(.Article--alt) .Article-object {
    border-radius: 16px 16px 0px 0px; }
  .Article--3:not(.Article--alt) .Article-body::before {
    right: -16px;
    left: 48px; }
  .Article--3:not(.Article--alt) .Article-object {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px; }
  .Article:not(.Article--alt):not(.Article--more) .Article-link {
    padding-bottom: 16px; }
  .Article:not(.Article--alt):not(.Article--more) .Article-object {
    padding-bottom: 60%; }
    .Articles--jobs .Article:not(.Article--alt):not(.Article--more) .Article-object {
      padding-bottom: 39.21569%; }
  .Article:not(.Article--alt):not(.Article--more) .Article-body {
    background-color: #ffffff;
    border-radius: 16px;
    margin-top: -48px;
    margin-right: 16px;
    margin-left: 16px; }
    .Article:not(.Article--alt):not(.Article--more) .Article-body::before {
      position: absolute;
      top: 0;
      bottom: -16px;
      z-index: -1;
      background-color: #fad018;
      border-radius: 0px 0px 16px 16px;
      content: ''; }
  .Article:not(.Article--alt):not(.Article--more) .Article-footer {
    position: absolute;
    top: 0;
    right: 32px;
    transform: translateY(-50%); }
  .Article--alt .Article-object {
    border-radius: 16px;
    padding-bottom: 36.70213%; }
  .Article--alt .Article-body {
    background-color: #343434;
    color: #ffffff;
    margin-top: -24px;
    margin-bottom: 16px;
    padding-bottom: 32px; }
    .Article--alt .Article-body .Article-title {
      color: #ffffff !important; }
    @media screen and (max-width: 47.9375em) {
      .Article--alt .Article-body {
        position: relative; }
        .Article--alt .Article-body::after {
          position: absolute;
          top: 0;
          bottom: 0;
          background-color: #343434;
          content: '';
          width: 24px; } }
  .Article--alt .Article-footer {
    position: absolute;
    bottom: 0;
    transform: translateY(50%); }
  @media screen and (min-width: 64em) {
    .Article--alt:nth-child(6n+1) .Article-object, .Article--alt:nth-child(6n+2) .Article-object, .Article--alt:nth-child(6n+3) .Article-object {
      margin-right: 24px; }
    .Article--alt:nth-child(6n+1) .Article-body, .Article--alt:nth-child(6n+2) .Article-body, .Article--alt:nth-child(6n+3) .Article-body {
      margin-left: 48px; }
    .Article--alt:nth-child(6n+1) .Article-footer, .Article--alt:nth-child(6n+2) .Article-footer, .Article--alt:nth-child(6n+3) .Article-footer {
      right: 72px; }
    .Article--alt:nth-child(6n+4) .Article-object, .Article--alt:nth-child(6n+5) .Article-object, .Article--alt:nth-child(6n+6) .Article-object {
      margin-left: 24px; }
    .Article--alt:nth-child(6n+4) .Article-body, .Article--alt:nth-child(6n+5) .Article-body, .Article--alt:nth-child(6n+6) .Article-body {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-right: 48px; } }
  @media screen and (min-width: 48em) {
    .Article--alt .Article-body {
      border-radius: 8px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Article--alt:nth-child(4n+1) .Article-object, .Article--alt:nth-child(4n+2) .Article-object {
      margin-right: 24px; }
    .Article--alt:nth-child(4n+1) .Article-body, .Article--alt:nth-child(4n+2) .Article-body {
      margin-left: 48px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; }
    .Article--alt:nth-child(4n+1) .Article-footer, .Article--alt:nth-child(4n+2) .Article-footer {
      right: 72px; }
    .Article--alt:nth-child(4n+3) .Article-object, .Article--alt:nth-child(4n+4) .Article-object {
      margin-left: 24px; }
    .Article--alt:nth-child(4n+3) .Article-body, .Article--alt:nth-child(4n+4) .Article-body {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-right: 48px; } }
  @media screen and (max-width: 47.9375em) {
    .Article--alt:nth-child(odd) .Article-object {
      margin-left: 24px; }
    .Article--alt:nth-child(odd) .Article-body {
      margin-right: 48px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px; }
      .Article--alt:nth-child(odd) .Article-body::after {
        right: 100%; }
    .Article--alt:nth-child(odd) .Article-footer {
      right: 72px; }
    .Article--alt:nth-child(even) .Article-object {
      margin-right: 24px; }
    .Article--alt:nth-child(even) .Article-body {
      margin-left: 48px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; }
      .Article--alt:nth-child(even) .Article-body::after {
        left: 100%; }
    .Article--alt:nth-child(even) .Article-footer {
      right: 72px; } }
  .Article--more {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    color: #ffffff; }
    .Article--more .Article-object,
    .Article--more .Article-body {
      grid-column: 1/2;
      grid-row: 1/2; }
    .Article--more .Article-object {
      background-color: #343434;
      border-radius: 16px;
      padding-bottom: 133.33333%; }
    .Article--more .Article-image {
      opacity: .28; }
    .Article--more .Article-body {
      align-self: center;
      justify-self: center;
      text-align: center; }

/* Outdated Browser */
.Outdated {
  background-color: #b31700;
  color: #ffffff;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500; }
  .Outdated-title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px; }
  .Outdated-text {
    margin-bottom: 0;
    line-height: 1; }
  .Outdated-button {
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    width: 230px;
    /*need for IE*/
    position: absolute;
    top: 12px;
    right: 24px; }
    .Outdated-button:hover {
      background-color: #ffffff;
      color: #b31700; }
  * html .Outdated {
    position: absolute; }

/* Cookie */
.CookieConsent {
  transition-property: transform, opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: 0s, 200ms, 200ms;
  background-color: #00d198;
  color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4; }
  .CookieConsent:not(.is-active) {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden; }
  .CookieConsent > .Container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .CookieConsent a {
    font-weight: 700; }
    .CookieConsent a, .CookieConsent a:active, .CookieConsent a:focus, .CookieConsent a:hover {
      color: inherit; }
  .CookieConsent-close {
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    font-size: 150%;
    margin-left: 24px;
    line-height: 1;
    transition-property: opacity;
    transition-duration: 200ms; }
    .CookieConsent-close:not(:hover) {
      opacity: .75; }

/* Header */
.Header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 4;
  padding-top: 17px;
  padding-bottom: 24px; }
  @media screen and (min-width: 48em) {
    .Header {
      transition-property: background-color, transform, padding;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: fixed;
      will-change: transform; }
      .Header.is-pinned {
        transform: translateY(0%); }
      .Header.is-unpinned {
        transform: translateY(-100%); }
      .Header.is-notTop {
        background-color: rgba(255, 255, 255, 0.9);
        color: #343434;
        padding-top: 8px;
        padding-bottom: 8px; }
        body.HomePage .Header.is-notTop .Nav-link {
          color: #343434; }
          body.HomePage .Header.is-notTop .Nav-link:hover {
            color: #fad018; } }
  @media screen and (min-width: 48em) {
    .Header {
      padding-top: 16px;
      padding-bottom: 16px; } }
  .Header > .Container {
    display: flex;
    align-items: center;
    position: static; }
  @media screen and (max-width: 47.9375em) {
    .Header::after {
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: rgba(0, 0, 0, 0.2);
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1; }
      html:not(.show-nav) .Header::after {
        opacity: 0;
        visibility: hidden; } }

/* Navs */
@media screen and (min-width: 48em) {
  .Navs {
    flex-grow: 1; } }

@media screen and (max-width: 47.9375em) {
  .Navs {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    display: flex;
    color: #343434;
    cursor: pointer; }
    html:not(.show-nav) .Navs {
      pointer-events: none;
      transform: translateX(100%) translateX(-82px); }
      html:not(.show-nav) .Navs .Nav-toggle {
        pointer-events: auto; } }

@media screen and (max-width: 47.9375em) {
  .Navs .Nav-toggle {
    align-self: start;
    background-color: #ffffff;
    border-top-left-radius: 70px;
    border-bottom-left-radius: 70px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    margin-top: 24px;
    padding-right: 16px;
    padding-left: 8px;
    margin-left: 24px; }
    .Navs .Nav-toggle .Toggle-icon {
      height: 34px;
      width: 34px; } }

.Navs-body {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 47.9375em) {
    .Navs-body {
      transition-property: box-shadow;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: #ffffff;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
      padding: 48px 32px 24px 32px;
      cursor: default; }
      .show-nav .Navs-body {
        box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.25); } }

/* Nav */
@media screen and (min-width: 48em) {
  .Nav--main {
    margin-left: auto; } }

@media screen and (max-width: 47.9375em) {
  .Nav--main {
    margin-top: 48px;
    margin-bottom: 48px; } }

@media screen and (max-width: 47.9375em) {
  .Nav--main .Nav-list {
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.Nav--main .Nav-item--jobs {
  position: relative; }

.Nav--main .Nav-item--hasDropdown:hover .Nav--dropdown {
  opacity: 1;
  visibility: visible; }

@media screen and (max-width: 47.9375em) {
  .Nav--main .Nav-item + .Nav-item {
    margin-top: 32px; } }

.Nav--main .Nav-link, .Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover {
  color: #343434; }

.Nav--main .Nav-link.is-active {
  display: flex;
  align-items: center;
  font-weight: 700; }

@media screen and (min-width: 48em) {
  .Nav--main .Nav-list {
    margin-left: -24px; } }

@media screen and (min-width: 64em) and (max-width: 79.9375em) {
  .Nav--main .Nav-list {
    margin-left: -48px; } }

@media screen and (min-width: 80em) {
  .Nav--main .Nav-list {
    margin-left: -60px; } }

.Nav--main .Nav-item {
  position: relative; }
  @media screen and (min-width: 48em) {
    .Nav--main .Nav-item {
      padding-left: 24px; } }
  @media screen and (min-width: 64em) and (max-width: 79.9375em) {
    .Nav--main .Nav-item {
      padding-left: 32px; } }
  @media screen and (min-width: 80em) {
    .Nav--main .Nav-item {
      padding-left: 60px; } }

@media screen and (min-width: 48em) {
  .Nav--main .Nav-list {
    justify-content: flex-end; }
  body.HomePage .Nav--main .Nav-link {
    color: #ffffff; }
    body.HomePage .Nav--main .Nav-link:hover {
      color: #fad018; }
  .Nav--main .Nav-link:hover {
    color: #fad018; } }

.Nav--socialMedia {
  position: relative; }
  .Main .Nav--socialMedia {
    display: inline-block; }
    .Main .Nav--socialMedia::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      content: '';
      width: calc(100% + 24px); }
      @media screen and (min-width: 48em) and (max-width: 82.9375em) {
        .Main .Nav--socialMedia::before {
          width: calc(100% + 40px); } }
      @media screen and (min-width: 83em) {
        .Main .Nav--socialMedia::before {
          width: calc((100vw - 1248px) / 2 + 100%); } }
    .Main .Nav--socialMedia::before {
      background-color: #343434;
      border-top-right-radius: 27px;
      border-bottom-right-radius: 27px; }
      @media screen and (min-width: 48em) {
        .Main .Nav--socialMedia::before {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px; } }
    .Main .Nav--socialMedia .Nav-list {
      color: #ffffff;
      padding-top: 15px;
      padding-right: 24px;
      padding-bottom: 15px; }
      @media screen and (max-width: 47.9375em) {
        .Main .Nav--socialMedia .Nav-list {
          margin-left: -24px; } }
      @media screen and (min-width: 48em) {
        .Main .Nav--socialMedia .Nav-list {
          padding-top: 34px;
          padding-right: 76px;
          padding-bottom: 34px;
          justify-content: flex-end; } }
    .Main .Nav--socialMedia .Nav-item {
      margin-left: 24px; }
    .Main .Nav--socialMedia .Nav-link {
      position: relative; }
      .Main .Nav--socialMedia .Nav-link:hover .Nav-icon {
        transition-delay: 0ms; }
        .Main .Nav--socialMedia .Nav-link:hover .Nav-icon:not(.Nav-icon--hover) {
          opacity: 0; }
        .Main .Nav--socialMedia .Nav-link:hover .Nav-icon--hover {
          opacity: 1;
          transform: scale(1.25);
          transition-delay: 100ms; }
      .Main .Nav--socialMedia .Nav-link, .Main .Nav--socialMedia .Nav-link:active, .Main .Nav--socialMedia .Nav-link.is-active, .Main .Nav--socialMedia .Nav-link:focus, .Main .Nav--socialMedia .Nav-link.is-focused, .Main .Nav--socialMedia .Nav-link:hover, .Main .Nav--socialMedia .Nav-link.is-hovered {
        color: #ffffff; }
    .Main .Nav--socialMedia .Nav-icon {
      transition-property: opacity, transform;
      transition-duration: 200ms;
      transition-timing-function: linear;
      fill: currentColor;
      display: block;
      transition-delay: 100ms; }
      @media screen and (min-width: 48em) {
        .Main .Nav--socialMedia .Nav-icon {
          height: 32px;
          width: 32px; } }
      .Main .Nav--socialMedia .Nav-icon--hover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        fill: #00d198;
        animation: 0;
        opacity: 0; }
  .Header .Nav--socialMedia {
    margin-top: auto; }
    .Header .Nav--socialMedia .Nav-item + .Nav-item {
      margin-left: 32px; }

.Nav--dropdown {
  position: absolute;
  opacity: 0;
  list-style: none;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  padding: 0;
  top: calc(100% + 10px);
  left: 60px;
  width: calc(100% + 80px);
  margin-top: 3px;
  border-top: none;
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 0px 16px; }
  @media screen and (max-width: 48em) {
    .Nav--dropdown {
      display: none; } }

.Nav-list--dropdown {
  list-style: none; }

.Nav-item--dropdown:not(:last-of-type) {
  margin-bottom: 16px; }

.Nav-link--dropdown {
  color: #343434;
  transition: .4s all;
  text-decoration: none; }
  .Nav-link--dropdown:hover {
    color: #fad018; }
  .Nav-link--dropdown.is-active {
    font-weight: bold;
    color: #343434; }

.Nav--footer {
  font-size: 10px;
  line-height: 1.4; }
  @media screen and (min-width: 48em) {
    .Nav--footer {
      font-size: 14px;
      line-height: 1.28571; } }
  .Nav--footer .Nav-item + .Nav-item {
    display: flex;
    align-items: center; }
    .Nav--footer .Nav-item + .Nav-item::before {
      content: '|';
      flex-shrink: 0;
      margin-right: 8px;
      margin-left: 8px; }
  .Nav--footer .Nav-link, .Nav--footer .Nav-link:active, .Nav--footer .Nav-link:focus, .Nav--footer .Nav-link:hover {
    color: #ffffff; }
  .Nav--footer .Nav-link:hover {
    text-decoration: underline; }

.Nav--column .Nav-item + .Nav-item {
  margin-top: 8px; }

.Nav--column .Nav-link, .Nav--column .Nav-link:active, .Nav--column .Nav-link:focus, .Nav--column .Nav-link:hover {
  color: #ffffff; }

.Nav--column .Nav-link:hover {
  text-decoration: underline; }

.Nav--team {
  margin-bottom: 48px; }
  .Nav--team .Nav-title {
    margin-bottom: 32px; }
    @media screen and (min-width: 48em) {
      .Nav--team .Nav-title {
        text-align: center; } }
  .Nav--team .Nav-list {
    margin-top: -16px;
    margin-left: -16px; }
    @media screen and (min-width: 48em) {
      .Nav--team .Nav-list {
        margin-left: -32px;
        justify-content: center; } }
  .Nav--team .Nav-item {
    padding-top: 16px;
    padding-left: 16px; }
    @media screen and (min-width: 48em) {
      .Nav--team .Nav-item {
        padding-left: 32px; } }

.Nav--filter {
  margin-bottom: 48px; }
  .Nav--filter .Nav-title {
    margin-bottom: 32px; }
    @media screen and (min-width: 48em) {
      .Nav--filter .Nav-title {
        text-align: center; } }
  .Nav--filter .Nav-list {
    margin-top: -16px;
    margin-left: -16px; }
    @media screen and (min-width: 48em) {
      .Nav--filter .Nav-list {
        margin-left: -32px; } }
  .Nav--filter .Nav-item {
    padding-top: 16px;
    padding-left: 16px; }
    @media screen and (min-width: 48em) {
      .Nav--filter .Nav-item {
        padding-left: 32px; } }

.Nav--lang {
  position: relative;
  z-index: 1; }
  @media screen and (min-width: 48em) {
    .Nav--lang {
      margin-left: 24px; } }
  @media screen and (min-width: 64em) and (max-width: 79.9375em) {
    .Nav--lang {
      margin-left: 32px; } }
  @media screen and (min-width: 80em) {
    .Nav--lang {
      margin-left: 60px; } }
  @media screen and (max-width: 47.9375em) {
    .Nav--lang {
      margin-top: 24px; } }
  @media screen and (min-width: 48em) {
    .Nav--lang:not(:hover) .Nav-list {
      opacity: 0;
      visibility: hidden; } }
  .Nav--lang .Nav-toggle {
    transition-property: color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    cursor: default;
    font-weight: 600;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #343434; }
    body.HomePage .Nav--lang .Nav-toggle {
      color: #ffffff; }
    body.HomePage .Header.is-notTop .Nav--lang .Nav-toggle {
      color: #343434; }
    @media screen and (min-width: 48em) {
      .Nav--lang .Nav-toggle {
        margin-right: -8px; } }
    .Nav--lang .Nav-toggle-icon {
      fill: currentColor; }
  @media screen and (min-width: 48em) {
    .Nav--lang .Nav-list {
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: absolute;
      top: 100%;
      right: 0;
      color: #000000;
      background-color: #f5f5f5;
      border-radius: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      flex-direction: column; } }
  @media screen and (max-width: 47.9375em) {
    .Nav--lang .Nav-list {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin-left: -24px; }
      .Nav--lang .Nav-list > .Nav-item {
        margin-left: 24px; } }
  @media screen and (min-width: 83em) {
    .Nav--lang .Nav-list {
      transform: translateX(-50%);
      left: 50%;
      right: auto; } }
  .Nav--lang .Nav-link {
    color: currentColor; }
    @media screen and (min-width: 48em) {
      .Nav--lang .Nav-link {
        padding: 8px 16px; } }
    .Nav--lang .Nav-link.is-active {
      color: #00d198;
      font-weight: 700; }
      @media screen and (min-width: 48em) {
        .Nav--lang .Nav-link.is-active {
          background-color: #00d198;
          color: #ffffff;
          font-weight: 700; } }
    .Nav--lang .Nav-link:not(.is-active):hover {
      color: #00d198; }

/* Logo */
.Logo {
  flex-shrink: 0; }
  @media screen and (min-width: 48em) {
    .Logo {
      margin-right: 48px; } }
  .Logo, .Logo-object {
    display: block; }
  .Logo--icon .Logo-object {
    height: 48px;
    width: 48px; }
    @media screen and (max-width: 47.9375em) {
      .Logo--icon .Logo-object {
        z-index: -1; }
        .Logo--icon .Logo-object.is-sticky {
          position: fixed;
          top: 17px; } }
    @media screen and (min-width: 48em) {
      .Logo--icon .Logo-object {
        height: 72px;
        width: 72px; } }
  .Logo--footer {
    height: 30px;
    width: 47.25px;
    margin-bottom: 24px; }
    @media screen and (min-width: 48em) {
      .Logo--footer {
        height: 50px;
        width: 78.75px; } }
  @media screen and (max-width: 47.9375em) {
    .Logo--nav {
      height: 50px;
      width: 78.75px; } }
  .Logo-header {
    width: 85px !important;
    height: 55px; }
  .Logo-isNotTopWhite {
    display: block; }
    header.is-notTop .Logo-isNotTopWhite {
      display: none; }
  .Logo-isNotTopBlack {
    display: none; }
    header.is-notTop .Logo-isNotTopBlack {
      display: block; }

/* Fields */
/* Fields: field */
.Form-field--file .Form-indicator, .Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition-property: border-color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  appearance: none;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bababa;
  color: #343434;
  line-height: inherit;
  vertical-align: middle;
  width: 100%; }
  .Form-item.has-error .Form-field--file .Form-indicator, .Form-field--file .Form-item.has-error .Form-indicator, .Form-item.has-error .Form-field--input,
  .Form-item.has-error .Form-field--textarea, .Form-item.has-error .Form-field--select {
    border-color: #cc2200; }
  .Form-item.is-success .Form-field--file .Form-indicator, .Form-field--file .Form-item.is-success .Form-indicator, .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #00d198; }
  .Form-field--file .Form-indicator:focus, .Form-field--input:focus,
  .Form-field--textarea:focus, .Form-field--select:focus, .Form-field--file .is-focused.Form-indicator, .is-focused.Form-field--input,
  .is-focused.Form-field--textarea, .is-focused.Form-field--select {
    border-color: #fad018; }
  .Form-field--file .is-disabled.Form-indicator, .is-disabled.Form-field--input,
  .is-disabled.Form-field--textarea, .is-disabled.Form-field--select, .Form-field--file .Form-indicator[disabled], .Form-field--input[disabled],
  .Form-field--textarea[disabled], .Form-field--select[disabled] {
    background-color: #f5f5f5;
    border-color: #d6d6d6; }

.is-tabbing .Form-field--file .Form-indicator:focus, .Form-field--file .is-tabbing .Form-indicator:focus, .is-tabbing .Form-field--input:focus,
.is-tabbing .Form-field--textarea:focus, .is-tabbing .Form-field--select:focus {
  box-shadow: 0 0 2px 1px #2662ef;
  outline: 0; }
  .Form-item.has-error .is-tabbing .Form-field--file .Form-indicator:focus, .Form-field--file .Form-item.has-error .is-tabbing .Form-indicator:focus, .Form-item.has-error .is-tabbing .Form-field--input:focus,
  .Form-item.has-error .is-tabbing .Form-field--textarea:focus, .Form-item.has-error .is-tabbing .Form-field--select:focus {
    box-shadow: 0 0 2px 1px rgba(204, 34, 0, 0.6); }
  .Form-item.is-success .is-tabbing .Form-field--file .Form-indicator:focus, .Form-field--file .Form-item.is-success .is-tabbing .Form-indicator:focus, .Form-item.is-success .is-tabbing .Form-field--input:focus,
  .Form-item.is-success .is-tabbing .Form-field--textarea:focus, .Form-item.is-success .is-tabbing .Form-field--select:focus {
    box-shadow: 0 0 2px 1px rgba(0, 209, 152, 0.6); }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 72px !important; }

.Form-field--sm {
  max-width: 120px !important; }

.Form-field--md {
  max-width: 240px !important; }

.Form-field--lg {
  max-width: 360px !important; }

.Form-field--xl {
  max-width: 480px !important; }

.Form-field--full {
  max-width: none !important; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 4px; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: #fad018;
      border-color: #f5f5f5; }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: #fad018;
      box-shadow: 0 0 2px 1px #2662ef; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.5625em 0.5625em;
    border: 1px solid #bababa;
    flex-shrink: 0;
    height: 1em;
    margin-right: 16px;
    width: 1em;
    position: relative;
    top: 0.28125em; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox > .Form-indicator {
  border-radius: 4px; }

.Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block; }
  .Form-field--file.is-placeholder .Form-indicator {
    color: #d6d6d6; }
  .Form-field--file input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%; }
    .Form-field--file input[type='file']:focus ~ .Form-indicator {
      border-color: #fad018;
      box-shadow: 0 0 2px 1px #2662ef; }
      .Form-item.has-error .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: 0 0 2px 1px rgba(204, 34, 0, 0.6); }
      .Form-item.is-success .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: 0 0 2px 1px rgba(0, 209, 152, 0.6); }
  .Form-field--file .Form-indicator {
    border-style: dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4375em 0.9375em 0.4375em 0.9375em; }
    .Form-field--file .Form-indicator::before {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      content: attr(data-file-text);
      display: block; }

/* Fields: input */
.Form-field--input::placeholder,
.Form-field--textarea::placeholder {
  color: #d6d6d6; }

.has-error .Form-field--input, .has-error
.Form-field--textarea {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%22-2%20-1.5%2024%2024%22%20width%3D%2224%22%20height%3D%2224%22%20preserveAspectRatio%3D%22xMinYMin%22%20class%3D%22jam%20jam-alert-f%22%20fill%3D%22%23cc2200%22%3E%3Cpath%20d%3D'M10%2020.393c-5.523%200-10-4.477-10-10%200-5.522%204.477-10%2010-10s10%204.478%2010%2010c0%205.523-4.477%2010-10%2010zm0-15a1%201%200%200%200-1%201v5a1%201%200%200%200%202%200v-5a1%201%200%200%200-1-1zm0%2010a1%201%200%201%200%200-2%201%201%200%200%200%200%202z'%20%2F%3E%3C%2Fsvg%3E");
  background-position: center right 9.3px;
  background-repeat: no-repeat;
  background-size: 13.3px; }

.with-icon .Form-field--input, .with-icon
.Form-field--textarea {
  padding-left: 40.3px; }

.Form-field--input {
  padding: 0.4375em 0.9375em 0.4375em 0.9375em; }

.Form-field--textarea {
  padding: 0.3125em 0.9375em 0.3125em 0.9375em; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }

.Form-field--select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%22-5%20-8%2024%2024%22%20width%3D%2224%22%20height%3D%2224%22%20preserveAspectRatio%3D%22xMinYMin%22%20class%3D%22jam%20jam-chevron-down%20Button-icon%22%3E%3Cpath%20d%3D%22M7.071%205.314l4.95-4.95a1%201%200%201%201%201.414%201.414L7.778%207.435a1%201%200%200%201-1.414%200L.707%201.778A1%201%200%201%201%202.121.364l4.95%204.95z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-position: right 9.3px center;
  background-repeat: no-repeat;
  background-size: 1em;
  overflow: hidden;
  padding: 0.4375em 2.5em 0.4375em 0.9375em; }
  .Form-field--select.is-placeholder {
    color: #d6d6d6; }
  .with-icon .Form-field--select {
    padding-left: 40.3px; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.21875em 0.9375em 0.21875em 0.9375em; }
    .Form-field--select[multiple] option + option {
      border-top: 1px solid #bababa; }

/* Slider */
/* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden; }
  .Slider--no-flexbox .Slider-item {
    float: left; }
  .Slider--vertical > .Slider-items {
    flex-direction: column; }
  .Slider-items {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform; }
  .Slider--android .Slider-item, .Slider-items {
    transform: translate3d(0px, 0, 0); }
  .Slider--multirow > .Slider-items {
    flex-wrap: wrap; }
  .Slider--free-mode > .Slider-items {
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .Slider-item {
    flex-shrink: 0;
    position: relative;
    transition-property: transform; }
    .Slider-item.is-blank {
      visibility: hidden; }
  .Slider--autoheight,
  .Slider--autoheight .Slider-item {
    height: auto; }
  .Slider--autoheight .Slider-items {
    align-items: flex-start;
    transition-property: transform, height; }
  .Slider--3d {
    perspective: 1200px; }
    .Slider--3d .Slider-items,
    .Slider--3d .Slider-item,
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom,
    .Slider--3d .swiper-cube-shadow {
      transform-style: preserve-3d; }
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10; }
    .Slider--3d .Slider-item-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .Slider--wp8-horizontal,
  .Slider--wp8-horizontal > .Slider-items {
    touch-action: pan-y; }
  .Slider--wp8-vertical,
  .Slider--wp8-vertical > .Slider-items {
    touch-action: pan-x; }

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0; }

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out; }

.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity; }
  .Slider--fade .Slider-item .Slider-item {
    pointer-events: none; }

.Slider--fade .is-active,
.Slider--fade .is-active .is-active {
  pointer-events: auto; }

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px;
  /* Windows 8 IE 10 fix */ }

/* Slider: cube */
.Slider--cube {
  overflow: visible; }
  .Slider--cube .Slider-item {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    z-index: 1; }
    .Slider--cube .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--cube.Slider--rtl .Slider-item {
    transform-origin: 100% 0; }
  .Slider--cube .is-active,
  .Slider--cube .is-active .is-active {
    pointer-events: auto; }
  .Slider--cube .is-active,
  .Slider--cube .is-next,
  .Slider--cube .is-prev,
  .Slider--cube .is-next + .Slider-item {
    pointer-events: auto;
    visibility: visible; }
  .Slider--cube .swiper-slide-shadow-top,
  .Slider--cube .swiper-slide-shadow-bottom,
  .Slider--cube .swiper-slide-shadow-left,
  .Slider--cube .swiper-slide-shadow-right {
    backface-visibility: hidden;
    z-index: 0; }
  .Slider--cube .swiper-cube-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    filter: blur(50px);
    opacity: 0.6; }

/* Slider: flip */
.Slider--flip {
  overflow: visible; }
  .Slider--flip .Slider-item {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1; }
    .Slider--flip .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--flip .is-active,
  .Slider--flip .is-active .is-active {
    pointer-events: auto; }
  .Slider--flip .Slider-item--shadow-top,
  .Slider--flip .Slider-item--shadow-bottom,
  .Slider--flip .Slider-item--shadow-left,
  .Slider--flip .Slider-item--shadow-right {
    backface-visibility: hidden;
    z-index: 0; }

/* Slider: lazy */
.Slider-preloader {
  height: 42px;
  width: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -21px;
  margin-left: -21px; }
  .Slider-preloader:after {
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block; }

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* Slider: navigation */
.Slider-button {
  height: 44px;
  width: 27px;
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-top: -22px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 44px; }
  .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: none; }
  .Slider-button.is-locked {
    display: none; }

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'currentColor'%2F%3E%3C%2Fsvg%3E");
  right: auto;
  left: 10px; }

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'currentColor'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0); }
  .Slider-pagination.is-hidden {
    opacity: 0; }
  .Slider-pagination.is-locked {
    display: none; }

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transform: scale(0.33);
    position: relative; }
  .Slider-pagination--bullets-dynamic .is-active {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-main {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-prev {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-prev-prev {
    transform: scale(0.33); }
  .Slider-pagination--bullets-dynamic .is-active-next {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-next-next {
    transform: scale(0.33); }

.Slider-pagination-item {
  height: 8px;
  width: 8px;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2; }
  button.Slider-pagination-item {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .is-clickable .Slider-pagination-item {
    cursor: pointer; }
  .Slider-pagination-item.is-active {
    background-color: currentColor;
    opacity: 1; }

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
    display: block;
    margin-right: 6px;
    margin-left: 6px; }
  .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
      display: inline-block;
      transition-property: transform, top;
      transition-duration: 200ms; }

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px; }

.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transition-property: transform, left;
    transition-duration: 200ms; }

.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms; }

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .Slider-pagination--progressbar .Slider-pagination-fill {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transform: scale(0);
    transform-origin: left top; }
  .Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
    transform-origin: right top; }
  .Slider--horizontal > .Slider-pagination--progressbar,
  .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 4px;
    width: 100%;
    left: 0;
    top: 0; }
  .Slider--vertical > .Slider-pagination--progressbar,
  .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 100%;
    width: 4px;
    left: 0;
    top: 0; }

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none; }
  .Slider--horizontal > .Slider-scrollbar {
    height: 5px;
    width: 98%;
    position: absolute;
    bottom: 3px;
    left: 1%;
    z-index: 50; }
  .Slider--vertical > .Slider-scrollbar {
    height: 98%;
    width: 5px;
    position: absolute;
    top: 1%;
    right: 3px;
    z-index: 50; }
  .Slider-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; }
  .Slider-scrollbar-cursor-drag {
    cursor: move; }
  .Slider-scrollbar .is-locked {
    display: none; }

/* Slider: thumbs */
/* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Slider--zoom > img,
  .Slider--zoom > svg,
  .Slider--zoom > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .Slider--zoom .is-zoomed {
    cursor: move; }

/* General */
small {
  font-size: 87.5%; }

strong, b {
  font-weight: 600; }

body:not(.is-tabbing) button:focus,
body:not(.is-tabbing) input:focus,
body:not(.is-tabbing) select:focus,
body:not(.is-tabbing) textarea:focus {
  outline: none; }

.LazyLoad,
.LazyLoading {
  opacity: 0; }

.LazyLoaded {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  opacity: 1; }

@keyframes button {
  0% {
    transform: rotate(0deg); }
  25%,
  30%,
  50% {
    transform: rotate(-5.14deg); }
  50% {
    transform: rotate(5.14deg); }
  75% {
    transform: rotate(-5.14deg); }
  100% {
    transform: rotate(5.14deg); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(30px); }
  60% {
    transform: translateY(15px); } }

@keyframes callToAction {
  0% {
    transform: rotate(0deg); }
  25%,
  30%,
  50% {
    transform: rotate(-1.82deg); }
  50% {
    transform: rotate(1.82deg); }
  75% {
    transform: rotate(-1.82deg); }
  100% {
    transform: rotate(1.82deg); } }

@keyframes icon {
  0% {
    transform: translateX(0); }
  14.28571% {
    transform: translateX(60%); }
  28.57143% {
    transform: translateX(-60%); }
  42.85714% {
    transform: translateX(40%); }
  57.14286% {
    transform: translateX(-40%); }
  71.42857% {
    transform: translateX(20%); }
  85.71429% {
    transform: translateX(-20%); }
  100% {
    transform: translateX(0); } }

@keyframes icon-reverse {
  0% {
    transform: translateY(0); }
  14.28571% {
    transform: translateY(60%); }
  28.57143% {
    transform: translateY(-60%); }
  42.85714% {
    transform: translateY(40%); }
  57.14286% {
    transform: translateY(-40%); }
  71.42857% {
    transform: translateY(20%); }
  85.71429% {
    transform: translateY(-20%); }
  100% {
    transform: translateY(0); } }

.Fancybox {
  position: relative;
  display: block; }
  .Fancybox path {
    transition: .4s all; }
  @media screen and (max-width: 48em) {
    .Fancybox-lookingBack {
      padding-bottom: 0 !important; } }
  .Fancybox-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 75px;
    z-index: 10; }
  .Fancybox-lastchild {
    margin-top: 48px;
    margin-left: 24px; }
    @media screen and (max-width: 48em) {
      .Fancybox-lastchild {
        margin-top: 0; } }
  .Fancybox:hover path {
    fill: #fad018; }

ul:not([class]) {
  list-style-type: none; }
  ul:not([class]) > li {
    margin-bottom: 0;
    padding-left: 24px; }
    ul:not([class]) > li + li {
      margin-top: 16px; }
      .Content-item ul:not([class]) > li + li {
        margin-top: 8px; }
      @media screen and (min-width: 48em) {
        ul:not([class]) > li + li {
          margin-top: 24px; }
          .Content-item ul:not([class]) > li + li {
            margin-top: 8px; } }
    ul:not([class]) > li::before {
      height: 8px;
      width: 8px;
      background-color: #fad018;
      border-radius: 100%;
      content: '';
      display: inline-block;
      margin-top: 9px;
      margin-right: 16px;
      margin-left: -24px;
      vertical-align: top; }
      .Info ul:not([class]) > li::before {
        background-color: #ffffff; }
      .Block--service:nth-of-type(3n-1) ul:not([class]) > li::before {
        background-color: #ffffff; }
        @media screen and (min-width: 48em) {
          .Block--service:nth-of-type(3n-1) ul:not([class]) > li::before {
            background-color: #00d198; } }

.CasesDetailPage h3,
.BlogDetailPage h3 {
  line-height: 1.4375; }
  .CasesDetailPage h3:not(.Related-title):not(.LookingBack-title),
  .BlogDetailPage h3:not(.Related-title):not(.LookingBack-title) {
    color: #fad018;
    margin-bottom: 16px; }

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
  margin-top: -8px; }

.Slider--media .Picture,
.Slider--related .Picture {
  position: relative;
  z-index: 1;
  overflow: hidden; }

.Slider--media .Slider-item:first-child .Picture:not([class*="u-radius"]),
.Slider--related .Slider-item:first-child .Picture:not([class*="u-radius"]) {
  border-top-left-radius: 48px;
  border-bottom-left-radius: 48px; }

.Slider--media .Slider-item:last-child .Picture:not([class*="u-radius"]),
.Slider--related .Slider-item:last-child .Picture:not([class*="u-radius"]) {
  border-top-right-radius: 48px;
  border-bottom-right-radius: 48px; }

.DefaultPage .Main > .Container > p,
.DefaultPage .Main > .Container > ol,
.DefaultPage .Main > .Container > ul,
.DefaultPage .Main > .Container > h2 {
  max-width: 824px; }

.DefaultPage .Main > .Container > .Media--left ~ p,
.DefaultPage .Main > .Container > .Media--left ~ ol,
.DefaultPage .Main > .Container > .Media--left ~ ul,
.DefaultPage .Main > .Container > .Media--left ~ h2 {
  max-width: none; }

.DefaultPage .Main > .Container > h2 {
  clear: both; }
  .DefaultPage .Main > .Container > h2:not(:first-child) {
    margin-top: 48px; }

.Main .gitlab-embed-snippets {
  margin: 0; }

.Code {
  max-width: 800px; }

.Date {
  font-weight: 400;
  color: #bababa;
  font-size: 14px; }

.Picture {
  display: inline-block; }
  .Picture > img,
  .Slider-item .Picture {
    display: block; }
  .Picture.is-test {
    display: block;
    padding-bottom: 100%; }
  .Picture:not(.without-placeholder) {
    background-image: url("../img/default.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%; }
    .Picture:not(.without-placeholder) .Picture-image {
      background-color: #ffffff;
      width: 100%; }

/* Benefits */
@media screen and (max-width: 47.9375em) {
  .Benefits-title {
    text-align: center; } }

.Benefit {
  background-color: #f5f5f5;
  display: flex;
  align-items: flex-end;
  max-width: 420px; }
  .Benefit + .Benefit {
    margin-top: 40px; }
  .Benefit-body {
    padding: 24px; }
    @media screen and (min-width: 48em) {
      .Benefit-body {
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 40px; }
        .Benefit:first-of-type .Benefit-body {
          padding-bottom: 16px; } }
  .Benefit-object {
    flex-shrink: 0; }
    @media screen and (min-width: 48em) {
      .Benefit-object {
        padding-right: 24px;
        padding-left: 24px; } }
  .Benefit:nth-of-type(odd) {
    margin-right: 48px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px; }
    @media screen and (max-width: 47.9375em) {
      .Benefit:nth-of-type(odd) .Benefit-body {
        padding-right: 8px; } }
    @media screen and (min-width: 48em) {
      .Benefit:nth-of-type(odd) {
        margin-right: 96px; } }
    .Benefit:nth-of-type(odd) .Benefit-object {
      margin-right: -32px; }
      @media screen and (min-width: 48em) {
        .Benefit:nth-of-type(odd) .Benefit-object {
          margin-right: -48px; } }
  .Benefit:nth-of-type(even) {
    flex-direction: row-reverse;
    margin-left: 48px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px; }
    @media screen and (max-width: 47.9375em) {
      .Benefit:nth-of-type(even) .Benefit-body {
        padding-left: 8px; } }
    @media screen and (min-width: 48em) {
      .Benefit:nth-of-type(even) {
        margin-left: 96px; } }
    .Benefit:nth-of-type(even) .Benefit-object {
      margin-left: -32px; }
      @media screen and (min-width: 48em) {
        .Benefit:nth-of-type(even) .Benefit-object {
          margin-left: -48px; } }

.Form--jobs::before {
  display: none; }

@media screen and (max-width: 47.9375em) {
  .Form--jobs-title, .Form--jobs-subtitle {
    font-size: 20px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Form--jobs-title, .Form--jobs-subtitle {
    font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }

@media screen and (min-width: 64em) {
  .Form--jobs-title, .Form--jobs-subtitle {
    font-size: 38px; } }

.Form--jobs-title {
  color: #fad018;
  margin-bottom: -15px; }
  @media screen and (max-width: 47.9375em) {
    .Form--jobs-title {
      margin-bottom: 0px; } }

.Form--jobs-subtitle {
  margin-bottom: 40px; }

.Form--jobs .Form-item {
  position: relative; }
  .Form--jobs .Form-item-icon {
    width: 80px;
    margin-bottom: 12px; }

.Form--jobs .Form-field--file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.Form--jobs .File-label {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 10px 30px;
  color: #d6d6d6;
  font-size: 14px; }

.Form-wrapper {
  max-width: 1100px;
  background-color: #fad018;
  padding: 55px 60px;
  border-radius: 60px;
  width: 100%; }
  @media screen and (max-width: 47.9375em) {
    .Form-wrapper {
      padding: 30px 20px; } }

.Form-body {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative; }
  .Form-body::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    width: calc(100% + 24px); }
    @media screen and (min-width: 48em) and (max-width: 82.9375em) {
      .Form-body::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 83em) {
      .Form-body::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  @media screen and (max-width: 47.9375em) {
    .Form-body {
      padding-right: 24px; } }
  @media screen and (min-width: 48em) {
    .Form-body {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 48px; }
      .Form-body::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        content: '';
        width: calc(100% + 24px); } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 82.9375em) {
      .Form-body::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 48em) and (min-width: 83em) {
      .Form-body::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  .Form-body::before {
    background-color: #fad018; }
    @media screen and (max-width: 47.9375em) {
      .Form-body::before {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px; } }
    @media screen and (min-width: 48em) {
      .Form-body::before {
        border-top-left-radius: 77px;
        border-bottom-left-radius: 77px; } }
  .Form-body a:not([class]) {
    color: #ffffff;
    text-decoration: none; }
    .Form-body a:not([class]):hover {
      text-decoration: underline; }

.Form-object {
  background-color: #f5f5f5;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
  max-width: 506px;
  margin-left: auto;
  padding: 24px; }

@media screen and (max-width: 47.9375em) {
  .Form {
    position: relative;
    margin-top: 24px; }
    .Form::before {
      position: absolute;
      top: 48px;
      right: -24px;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: #f5f5f5;
      border-bottom-left-radius: 32px;
      content: ''; } }

@media screen and (min-width: 48em) {
  form .Button {
    max-width: 100%;
    width: 160px; } }

#brochure {
  max-width: 360px; }
  #brochure .Form-label {
    display: none; }

/* Blocks */
.Block {
  margin-top: 72px; }
  @media screen and (min-width: 48em) {
    .Block {
      margin-top: 72px; } }
  @media screen and (max-width: 47.9375em) {
    .Block-header--mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 16px; }
      .Block-header--mobile .Block-title {
        border-radius: 40px;
        padding: 16px 24px;
        margin-bottom: 0;
        line-height: 1.27778; }
      .Block-header--mobile::before {
        position: absolute;
        top: 0;
        right: -24px;
        bottom: 50%;
        left: -24px;
        z-index: -1;
        content: ''; } }
  .Block--customers {
    padding-bottom: 25px; }
    @media screen and (max-width: 48em) {
      .Block--customers {
        padding-bottom: 50px; } }
  .Block--serviceBanner {
    margin-top: 48px; }
  .Block--bannerNew {
    margin-top: 130px; }
    @media screen and (max-width: 64em) {
      .Block--bannerNew {
        margin-top: 70px; } }
  .Block--bgBanner + .Block--testimonials {
    margin-top: -240px; }
  .Block--highlight {
    position: relative;
    padding-bottom: 90px; }
    .Block--highlight::after {
      position: absolute;
      top: 24px;
      right: 0;
      bottom: 0;
      background-color: rgba(252, 224, 100, 0.3);
      border-bottom-left-radius: 48px;
      content: '';
      display: block; }
      @media screen and (min-width: 48em) {
        .Block--highlight::after {
          width: 71.25vw; } }
      @media screen and (max-width: 47.9375em) {
        .Block--highlight::after {
          left: 36px; } }
  .Block--workflow {
    padding-bottom: 40px; }
    @media screen and (max-width: 64em) {
      .Block--workflow {
        padding-bottom: 0; } }
  .Block--homeBanner, .Block--bgBanner {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    margin-top: -104px; }
  .Block--homeExpertise {
    position: relative;
    padding-bottom: 50px; }
    .Block--homeExpertise::after {
      position: absolute;
      top: 24px;
      right: 0;
      bottom: 0;
      background-color: rgba(252, 224, 100, 0.3);
      border-bottom-left-radius: 48px;
      content: '';
      display: block; }
      @media screen and (min-width: 48em) {
        .Block--homeExpertise::after {
          width: 71.25vw; } }
      @media screen and (max-width: 47.9375em) {
        .Block--homeExpertise::after {
          left: 36px; } }
  .Block--banner {
    position: relative;
    z-index: 10; }
  .Block--caseBanner {
    margin-top: 100px; }
    @media screen and (max-width: 48em) {
      .Block--caseBanner {
        margin-top: 48px; } }
  .Block--contact {
    margin-top: 0; }
    @media screen and (max-width: 48em) {
      .Block--contact {
        margin-top: 24px !important; } }
  .Block--mission {
    margin-top: 120px; }
    @media screen and (max-width: 48em) {
      .Block--mission {
        margin-top: 48px; } }

/* Banner */
@media screen and (max-width: 47.9375em) {
  .is-compact .Block--banner {
    background-color: transparent;
    padding-top: 96px; }
    .is-compact .Block--banner .Banner-object {
      display: none; }
    .is-compact .Block--banner .Info-intro::before, .is-compact .Block--banner .Info-intro::after {
      content: none; } }

.Block--banner.has-body {
  background-color: #fad018; }

.Block--banner-service {
  padding-bottom: 48px; }

.Block--banner:not(.has-body) {
  padding-bottom: 24px; }
  @media screen and (max-width: 48em) {
    .Block--banner:not(.has-body).is-compact {
      padding-bottom: 0; } }

@media screen and (min-width: 48em) {
  .Block--banner {
    background-color: #fce064;
    border-bottom-right-radius: 48px; }
    .Block--banner.has-body {
      border-bottom-right-radius: 80px; } }

@media screen and (max-width: 47.9375em) {
  .CasesDetailPage .Block--banner.has-body,
  .BlogDetailPage .Block--banner.has-body,
  .JobsDetailPage .Block--banner.has-body {
    background-color: transparent; }
  .Block--banner + .Block--articles,
  .Block--banner + .Block--service {
    margin-top: 0; } }

.Block--banner :last-child {
  margin-bottom: 0; }

.Banner-object, .Banner-image {
  display: block; }

.Banner-object {
  position: relative; }
  .Banner-object::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    content: ''; }
  .HomePage .Banner-object.object-fit-polyfill {
    height: 73.275vh; }
  body:not(.HomePage) .Banner-object.object-fit-polyfill {
    min-height: 336px;
    max-height: 552px; }
    @media screen and (max-width: 47.9375em) {
      body:not(.HomePage) .Banner-object.object-fit-polyfill {
        height: 336px; } }

.Banner-image {
  object-fit: cover;
  width: 100%; }
  .HomePage .Banner-image {
    height: 73.275vh; }
  body:not(.HomePage) .Banner-image {
    min-height: 336px;
    max-height: 552px; }
    @media screen and (max-width: 47.9375em) {
      body:not(.HomePage) .Banner-image {
        height: 336px; } }

@media screen and (min-width: 48em) {
  .Info {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, auto);
    align-items: start;
    margin-left: -24px; }
    .Info > .Info-intro,
    .Info > .Info-body,
    .Info > .Info-object,
    .Info > .Info-objects {
      margin-left: 24px; } }

.Info-intro {
  grid-column: 1/4;
  grid-row: 1/2;
  position: relative;
  z-index: 5; }
  .Info-intro::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    width: calc(100% + 24px); }
    @media screen and (min-width: 48em) and (max-width: 82.9375em) {
      .Info-intro::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 83em) {
      .Info-intro::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  .Info-intro.has-columns {
    grid-column-end: 6; }
  body:not(.is-compact) .Block--banner .Info-intro {
    margin-top: -100px; }
    @media screen and (max-width: 47.9375em) {
      body:not(.is-compact) .Block--banner .Info-intro {
        padding-top: 40px;
        padding-right: 24px;
        padding-bottom: 24px; } }
  @media screen and (min-width: 48em) {
    .Block--banner .Info-intro {
      padding-top: 48px;
      padding-right: 48px;
      padding-bottom: 48px; }
      .is-compact .Block--banner .Info-intro {
        margin-top: -100px; }
      body:not(.is-compact) .Block--banner .Info-intro {
        margin-top: -168px; } }
  @media screen and (min-width: 48em) {
    .is-compact .Info-intro {
      justify-self: start; } }
  .Info-intro::before {
    background-color: #ffffff;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px; }
  @media screen and (max-width: 47.9375em) {
    .Info-intro {
      margin-bottom: 24px; }
      .Info-intro::after {
        position: absolute;
        top: 100px;
        right: -24px;
        bottom: -24px;
        z-index: -2;
        background-color: #fce064;
        border-bottom-left-radius: 48px;
        content: '';
        display: block;
        width: 64vw; }
        .ContactPage .Info-intro::after {
          background-color: #fad018;
          border-bottom-left-radius: 0;
          width: calc(100% + 24px); } }

.Info-body {
  position: relative;
  z-index: 1;
  grid-column: 4/7;
  grid-row: 1/3;
  justify-self: end;
  align-self: center;
  max-width: 506px;
  padding-top: 48px;
  padding-bottom: 48px; }
  .has-object--1 .Info-body {
    grid-column-start: 5; }
  .has-object--2 .Info-body {
    grid-column: 1/4;
    grid-row: 2/3;
    justify-self: inherit; }
  .ServicesPage .Info-body,
  .BlocksPage .Info-body {
    grid-column: 1/7;
    grid-row: 2/3;
    max-width: inherit;
    justify-self: inherit; }
    @media screen and (max-width: 47.9375em) {
      .ServicesPage .Info-body,
      .BlocksPage .Info-body {
        padding-top: 0; } }
  .JobsDetailPage .Info-body {
    max-width: inherit;
    justify-self: inherit; }
    .JobsDetailPage .Info-body ul:not([class]) + li {
      margin-top: 8px; }
    @media screen and (min-width: 48em) {
      .JobsDetailPage .Info-body ul:not([class]) {
        column-count: 2;
        margin-top: -24px; }
        .JobsDetailPage .Info-body ul:not([class]) > li {
          display: inline-block;
          break-inside: avoid;
          width: 100%; }
          .JobsDetailPage .Info-body ul:not([class]) > li:first-child {
            margin-top: 24px; } }
    @media screen and (max-width: 47.9375em) {
      .JobsDetailPage .Info-body {
        background-color: #fad018;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        padding: 24px;
        margin-top: 72px;
        margin-bottom: 16px;
        position: relative; }
        .JobsDetailPage .Info-body::after {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 100%;
          background-color: #fad018;
          content: '';
          width: 24px; } }

.Info-object {
  justify-self: start; }
  .has-object--1 .Info-object {
    grid-column: 1/5;
    grid-row: 2/3;
    margin-top: 48px;
    align-self: end; }
  .has-object--2 .Info-object:last-child {
    margin-top: 48px;
    margin-left: 24px; }

.Info-objects {
  display: flex;
  align-items: flex-start; }
  @media screen and (max-width: 47.9375em) {
    .Info-objects {
      margin-top: 32px;
      margin-left: -8px; } }
  @media screen and (min-width: 48em) {
    .has-object--2 .Info-objects {
      grid-column: 4/7;
      grid-row: 2/3;
      position: absolute;
      top: 100%;
      right: 0;
      transform: translateY(-50%);
      max-width: calc(100% / 1248 * 612); } }

.Intro-header {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start; }
  @media screen and (max-width: 82.9375em) {
    .Intro-header ~ .Intro-author,
    .Intro-header ~ .Intro-body {
      padding-left: 40px; } }
  .Intro-header .Button {
    transform: translateY(7px); }
    .Intro-header .Button + h1 {
      flex-grow: 1; }
    @media screen and (min-width: 48em) and (max-width: 82.9375em) {
      .Intro-header .Button {
        transform: translateY(12px); } }
    @media screen and (min-width: 83em) {
      .Intro-header .Button {
        transform: translateY(16.5px); } }
    .Intro-header .Button:hover svg {
      animation: icon 1s linear 1; }
  @media screen and (max-width: 82.9375em) {
    .Intro-header .Button {
      margin-right: 16px; }
    .Intro-header + .Intro-body {
      padding-left: 40px; } }
  @media screen and (min-width: 83em) {
    .Intro-header {
      margin-left: -48px; }
      .Intro-header .Button {
        margin-right: 24px; } }

.Intro-object {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%); }

/* Articles */
@media screen and (min-width: 37.5em) {
  .Articles.is-unloaded,
  .Articles.is-unloaded .Article {
    opacity: 0; }
  .Articles-status {
    display: none; } }

.Articles:not(.Articles--slider):not(.Articles--alt) {
  margin-top: -48px;
  margin-left: -24px; }
  .Articles:not(.Articles--slider):not(.Articles--alt) > .Article {
    padding-top: 48px;
    padding-left: 24px; }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Articles > .Article {
    width: 50%; } }

@media screen and (min-width: 64em) {
  .Articles > .Article {
    width: 33.33333%; } }

@media screen and (min-width: 48em) {
  .ArticlesPage .Articles > .Article:nth-child(1) {
    margin-top: 48px; } }

@media screen and (min-width: 64em) {
  .ArticlesPage .Articles > .Article:nth-child(3) {
    margin-top: 48px; } }

.Articles--alt {
  margin-top: -24px;
  margin-left: -24px; }
  .Articles--alt > .Article--alt {
    padding-top: 24px;
    padding-left: 24px; }

.Articles:not(.has-masonry) {
  display: flex;
  flex-wrap: wrap; }

@media screen and (min-width: 48em) {
  .Articles--jobs > .Article {
    width: 50%; } }

@media screen and (min-width: 48em) {
  .Block--articles > .Container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto; }
  .Block--articles .Block-title {
    grid-column: 1/2;
    grid-row: 1/2;
    margin-bottom: 32px; }
  .Block--articles .Button--bare {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: start; }
  .Block--articles .Articles {
    grid-column: 1/3;
    grid-row: 2/3; } }

.Block--articles .Slider:not(.Slider--initialized) {
  margin-left: -16px; }
  .Block--articles .Slider:not(.Slider--initialized) .Slider-item {
    padding-left: 16px; }

@media screen and (min-width: 48em) {
  .Block--articles .Slider:not(.Slider--initialized) {
    margin-left: -24px; }
    .Block--articles .Slider:not(.Slider--initialized) .Slider-item {
      padding-left: 24px; } }

@media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--articles .Slider .Slider-item {
    width: 50%; } }

@media screen and (min-width: 48em) and (min-width: 64em) {
  .Block--articles .Slider .Slider-item {
    width: 33.33333%; } }

@media screen and (max-width: 47.9375em) {
  .Block--articles .Articles {
    margin-right: -24px;
    margin-left: -24px;
    padding-left: 24px;
    overflow: hidden; }
    .Block--articles .Articles .Slider {
      overflow: visible;
      width: calc(100% - 24px); } }

@media screen and (max-width: 47.9375em) {
  .Block--cases .Block-title {
    background-color: #ffffff; }
  .Block--cases .Block-header--mobile::before {
    background-color: #fad018; } }

/* Columns */
.Block--columns {
  position: relative;
  padding-bottom: 24px; }
  .Block--columns::after {
    position: absolute;
    top: 24px;
    right: 0;
    bottom: 0;
    background-color: rgba(252, 224, 100, 0.3);
    border-bottom-left-radius: 48px;
    content: '';
    display: block; }
    @media screen and (min-width: 48em) {
      .Block--columns::after {
        width: 71.25vw; } }
    @media screen and (max-width: 47.9375em) {
      .Block--columns::after {
        left: 36px; } }

.Columns--promises {
  position: relative;
  z-index: 1;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 24px; }
  .Columns--promises::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    width: calc(100% + 24px); }
    @media screen and (min-width: 48em) and (max-width: 82.9375em) {
      .Columns--promises::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 83em) {
      .Columns--promises::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  .Columns--promises::before {
    background-color: #fad018;
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px; }
  @media screen and (min-width: 48em) {
    .Columns--promises {
      max-width: 83.01282%;
      padding-top: 48px;
      padding-right: 96px;
      padding-bottom: 48px; }
      .Columns--promises-object {
        color: #ffffff; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .Columns--promises-image {
      height: 32px;
      width: 32px; } }

@media screen and (min-width: 48em) {
  .Columns {
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px; }
    .Columns .Block-title {
      flex: 1 1 100%; }
    .Columns .Column {
      width: 33.33333%; }
    .Columns .Column,
    .Columns .Block-title {
      padding-left: 24px; } }

@media screen and (max-width: 47.9375em) {
  .Column {
    display: flex;
    align-items: baseline; }
    .Column + .Column {
      margin-top: 16px; } }

.Column-body :last-child {
  margin-bottom: 0; }

.Column-object {
  color: #fad018; }
  .Columns--promises .Column-object {
    color: #ffffff; }
  @media screen and (max-width: 47.9375em) {
    .Column-object {
      flex-shrink: 0;
      margin-right: 16px; } }
  @media screen and (min-width: 48em) {
    .Column-object {
      margin-bottom: 16px; } }

.Column-image {
  height: 16px;
  width: 16px;
  fill: currentColor; }
  @media screen and (min-width: 48em) {
    .Column-image {
      height: 24px;
      width: 24px; } }

.BgBanner {
  position: relative;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 180px;
  padding-bottom: 300px; }
  .BgBanner-header {
    margin: 0 auto;
    position: relative; }
  .BgBanner-dot {
    color: #fad018;
    display: inline-block; }
  .BgBanner-title {
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    margin-bottom: 10px;
    display: inline-flex;
    position: relative; }
    @media screen and (max-width: 47.9375em) {
      .BgBanner-title {
        font-size: 26px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .BgBanner-title {
        font-size: calc(26px + 32 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .BgBanner-title {
        font-size: 58px; } }
    @media screen and (max-width: 48em) {
      .BgBanner-title {
        width: 100%; } }
  .BgBanner-intro {
    max-width: 500px; }
    @media screen and (max-width: 47.9375em) {
      .BgBanner-intro {
        font-size: 16px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .BgBanner-intro {
        font-size: calc(16px + 8 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .BgBanner-intro {
        font-size: 24px; } }
    .BgBanner-intro p:last-of-type {
      margin-bottom: 0; }
  .BgBanner-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.3; }

/* Feed */
.Feed {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto repeat(2, 1fr); }
  @media screen and (max-width: 47.9375em) {
    .Feed {
      margin-top: -8px;
      margin-left: -8px; }
      .Feed .Nav--socialMedia,
      .Feed .Feed-item {
        margin-top: 8px;
        margin-left: 8px; } }
  .Feed .Nav--socialMedia {
    grid-column: 1/3;
    grid-row: 1/2;
    justify-self: start;
    margin-bottom: 16px; }
    @media screen and (min-width: 48em) {
      .Feed .Nav--socialMedia {
        grid-column: 1 / 5;
        margin-bottom: 24px;
        padding-left: 24px; } }
  .Feed-item {
    display: block; }
    .Feed-item:nth-of-type(1) {
      grid-column: 1/2;
      grid-row: 2/3; }
    .Feed-item:nth-of-type(2) {
      grid-column: 2/3;
      grid-row: 2/3; }
      @media screen and (max-width: 47.9375em) {
        .Feed-item:nth-of-type(2) {
          transform: translateY(-48px); } }
    .Feed-item:nth-of-type(3) {
      grid-column: 1/2;
      grid-row: 3/4; }
      @media screen and (min-width: 48em) {
        .Feed-item:nth-of-type(3) {
          grid-column: 3/4;
          grid-row: 2/3; } }
    .Feed-item:nth-of-type(4) {
      grid-column: 2/3;
      grid-row: 3/4; }
      @media screen and (max-width: 47.9375em) {
        .Feed-item:nth-of-type(4) {
          transform: translateY(-48px); } }
      @media screen and (min-width: 48em) {
        .Feed-item:nth-of-type(4) {
          grid-column: 4/5;
          grid-row: 2/3; } }
  @media screen and (min-width: 48em) {
    .Feed {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto auto;
      margin-left: -24px; }
      .Feed-item {
        margin-left: 24px; }
        .Feed-item:nth-of-type(odd) {
          margin-top: 48px; } }
  .Feed-image {
    width: 100%; }

/* Customers */
.Customer {
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: linear;
  height: 66px;
  width: 66px;
  background-color: #f5f5f5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: relative; }
  @media screen and (min-width: 48em) {
    .Customer {
      padding: 24px; } }
  .Customer--small {
    padding: 8px;
    height: 48px;
    width: 48px; }
  .Customer-object {
    display: block; }
  .Customer-object, .Customer-image {
    width: 100%; }
  @media screen and (min-width: 48em) {
    .Customer {
      height: 136px;
      width: 136px; }
      .Customer--small {
        height: 72px;
        width: 72px;
        padding: 10px; } }
  .Customer:hover {
    transform: scale(1.2); }
  .Customer-button {
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    top: calc(50% + 33px);
    transform: translateY(-50%);
    z-index: 5; }
    .Customer-button path {
      transition: .4s all; }
    .Customer-button:hover path {
      fill: #fad018; }
    .Customer-button.is-disabled {
      display: none; }
    .Customer-button.is-locked {
      display: none; }
  .Customer-prev {
    left: 0; }
    @media screen and (max-width: 64em) {
      .Customer-prev {
        bottom: -50px;
        top: initial;
        right: initial;
        left: calc(50% - 15px);
        transform: translateX(-50%); } }
  .Customer-next {
    right: 0; }
    @media screen and (max-width: 64em) {
      .Customer-next {
        bottom: -50px;
        top: initial;
        left: calc(50% + 15px);
        transform: translateX(-50%); } }

.CustomerSlider {
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: linear;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: relative; }
  .CustomerSlider .Customer-image {
    transition: .4s all;
    filter: grayscale(1); }
  .CustomerSlider:hover .Customer-image {
    filter: grayscale(0); }
  @media screen and (min-width: 48em) {
    .CustomerSlider {
      padding: 24px; } }
  .CustomerSlider-object {
    display: block; }
  .CustomerSlider-object, .CustomerSlider-image {
    width: 100%; }

/* Services */
.Block--service {
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 47.9375em) {
    .Block--service.is-odd .Block-title {
      background-color: #ffffff; }
    .Block--service.is-odd .Block-header--mobile::before {
      background-color: #fad018; } }
  .Block--service.is-even {
    position: relative; }
    @media screen and (max-width: 47.9375em) {
      .Block--service.is-even {
        padding-bottom: 48px; }
        .Block--service.is-even::before {
          position: absolute;
          top: 27.5px;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background-color: #fad018;
          border-top-left-radius: 48px;
          content: ''; }
        .Block--service.is-even .Block-title {
          background-color: #fad018; }
        .Block--service.is-even .Block-header--mobile::before {
          background-color: #ffffff; }
        .Block--service.is-even .CallToAction-object {
          background-color: #ffffff;
          color: #fad018; }
        .Block--service.is-even .CallToAction-objectItem {
          background-color: #fad018; }
        .Block--service.is-even .CallToAction-icon {
          color: #ffffff; }
        .Block--service.is-even .CallToAction-arrow {
          color: #fad018; } }
  .Block--service .Button--toggle {
    color: #fad018; }
  @media screen and (max-width: 47.9375em) {
    .Block--service .Block-footer {
      text-align: center; } }

@media screen and (min-width: 48em) {
  .Service-intro {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-left: -24px; }
    .Service-intro > .Block-header,
    .Service-intro > .ServiceIntro-object,
    .Service-intro > .ServiceIntro-body {
      margin-left: 24px; }
    .Block--service.is-odd .Service-intro .Block-header {
      grid-column: 1/2;
      grid-row: 1/2; }
    .Block--service.is-even .Service-intro {
      padding-bottom: 32px; }
      .Block--service.is-even .Service-intro .Block-header {
        grid-column: 2/3;
        grid-row: 1/2; } }

.ServiceIntro-title {
  counter-increment: i;
  word-break: keep-all; }
  .ServicesPage .ServiceIntro-title::before {
    content: counter(i) ". "; }
  @media screen and (min-width: 48em) {
    .Block--service.is-even .ServiceIntro-title {
      text-align: right; } }

.ServiceIntro-object {
  align-self: start;
  justify-self: start;
  position: relative; }
  @media screen and (min-width: 48em) {
    .Block--service.is-odd .ServiceIntro-object {
      grid-column: 1/2;
      grid-row: 2/3; }
    .Block--service.is-even .ServiceIntro-object {
      grid-column: 2/3;
      grid-row: 2/3; } }
  @media screen and (max-width: 47.9375em) {
    .ServiceIntro-object {
      margin-bottom: 24px; } }

.ServiceIntro-body {
  max-width: 534px;
  position: relative; }
  .Block--service.is-expanded .ServiceIntro-body .Button {
    display: none; }
  @media screen and (min-width: 48em) {
    .Block--service.is-odd .ServiceIntro-body {
      grid-column: 2/3;
      grid-row: 2/3;
      padding-right: 48px;
      padding-left: 24px;
      justify-self: end;
      align-self: center; } }
  @media screen and (min-width: 48em) {
    .Block--service.is-even .ServiceIntro-body {
      grid-column: 1/2;
      grid-row: 2/3;
      margin-right: 24px;
      max-width: 505px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .Block--service.is-even .ServiceIntro-body::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        content: '';
        width: calc(100% + 24px); } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 82.9375em) {
      .Block--service.is-even .ServiceIntro-body::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 48em) and (min-width: 83em) {
      .Block--service.is-even .ServiceIntro-body::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  .Block--service.is-even .ServiceIntro-body .Button {
    color: #ffffff; }
  @media screen and (min-width: 83em) {
    .Block--service.is-even .ServiceIntro-body::before {
      margin-left: calc((100vw - 1248px) / -2);
      width: calc((100vw - 1248px) / 2 + 160%); } }
  @media screen and (min-width: 48em) and (max-width: 82.9375em) {
    .Block--service.is-even .ServiceIntro-body::before {
      margin-left: -40px;
      width: calc(40px + 160%); } }
  @media screen and (min-width: 48em) {
    .Block--service.is-even .ServiceIntro-body::before {
      background-color: #fad018;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      height: calc(100% + 64px);
      margin-top: -32px;
      left: 0; } }

.Service-toggle {
  transition-property: opacity, visibility;
  transition-duration: 500ms;
  transition-timing-function: linear;
  margin-left: -24px; }
  @media screen and (min-width: 48em) {
    .Block--service.has-toggle.is-expanded .Service-toggle,
    .Block--service:not(.has-toggle) .Service-toggle {
      margin-top: 48px; } }
  .Block--service.has-toggle:not(.is-expanded) .Service-toggle {
    overflow: hidden;
    height: 0;
    opacity: 0;
    visibility: hidden; }
  .Service-toggle > .Service-item,
  .Service-toggle > .CallToAction,
  .Service-toggle > .Block-footer {
    margin-left: 24px; }
  .Service-toggle > .Service-item :last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 47.9375em) {
    .Service-toggle > .Service-item + .Service-item {
      margin-top: 24px; } }
  .Service-toggle .CallToAction {
    margin-top: 48px; }
    @media screen and (min-width: 48em) {
      .Service-toggle .CallToAction {
        margin-top: 72px; } }
    .Service-toggle .CallToAction--right {
      justify-self: end;
      margin-left: auto; }
  @media screen and (max-width: 47.9375em) {
    .Block--service:nth-of-type(3n-1) .Service-toggle .WhatWeDo {
      position: relative;
      margin-top: -16px;
      padding-bottom: 16px; }
      .Block--service:nth-of-type(3n-1) .Service-toggle .WhatWeDo::before {
        position: absolute;
        top: 16px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: #ffffff;
        border-radius: 171.5px;
        content: ''; }
    .Block--service.is-even .Service-toggle .Button--toggle {
      color: #ffffff; } }
  @media screen and (min-width: 48em) {
    .Service-toggle .Block-footer {
      grid-column: 1/3;
      grid-row: 3/4;
      justify-self: center;
      text-align: center; }
    .Block--service.has-text.has-text:nth-of-type(3n-2) .Service-toggle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, auto); }
      .Block--service.has-text.has-text:nth-of-type(3n-2) .Service-toggle .WhatYouGet {
        margin-left: auto; }
      .Block--service.has-text.has-text:nth-of-type(3n-2) .Service-toggle .Service-item--whatWeDo {
        grid-column: 1/2;
        grid-row: 1/2; }
      .Block--service.has-text.has-text:nth-of-type(3n-2) .Service-toggle .Service-item--whatYouGet {
        grid-column: 2/3;
        grid-row: 1/2; }
      .Block--service.has-text.has-text:nth-of-type(3n-2) .Service-toggle .CallToAction {
        grid-column: 1/3;
        grid-row: 2/3; }
      .Block--service.has-text.has-text:nth-of-type(3n-2) .Service-toggle .Block-footer {
        grid-column: 1/3;
        grid-row: 3/4; }
    .Block--service.has-text.has-text:nth-of-type(3n-1) .Service-toggle {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: repeat(3, auto); }
      .Block--service.has-text.has-text:nth-of-type(3n-1) .Service-toggle .Service-item--whatWeDo {
        grid-column: 2/3;
        grid-row: 1/2;
        justify-self: end; }
      .Block--service.has-text.has-text:nth-of-type(3n-1) .Service-toggle .Service-item--whatYouGet {
        grid-column: 1/2;
        grid-row: 1/2; }
      .Block--service.has-text.has-text:nth-of-type(3n-1) .Service-toggle .CallToAction {
        grid-column: 1/3;
        grid-row: 2/3; }
    .Block--service.has-text:nth-of-type(3n) .Service-toggle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, auto); }
      .Block--service.has-text:nth-of-type(3n) .Service-toggle .WhatWeDo {
        margin-bottom: 48px; }
      .Block--service.has-text:nth-of-type(3n) .Service-toggle .Service-item--whatWeDo {
        grid-column: 1/3;
        grid-row: 1/2; }
      .Block--service.has-text:nth-of-type(3n) .Service-toggle .Service-item--whatYouGet {
        grid-column: 1/2;
        grid-row: 2/3; }
      .Block--service.has-text:nth-of-type(3n) .Service-toggle .CallToAction {
        grid-column: 2/3;
        grid-row: 2/3;
        align-self: center;
        margin-top: 0; } }

.ServicesPage .Main {
  counter-reset: i; }

.WhatWeDo {
  display: flex;
  flex-wrap: wrap;
  margin-top: -24px;
  margin-left: -8px; }
  @media screen and (min-width: 48em) {
    .WhatWeDo {
      margin-top: -16px;
      margin-left: -16px; } }
  @media screen and (max-width: 47.9375em) {
    .WhatWeDo {
      justify-content: center; } }
  .WhatWeDo-item {
    padding-right: 8px;
    padding-left: 8px;
    max-width: 159px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
    margin-left: 8px; }
    @media screen and (min-width: 48em) {
      .WhatWeDo-item {
        margin-top: 16px;
        margin-left: 16px; } }
  .WhatWeDo-image {
    margin-bottom: 8px;
    max-width: 120px;
    margin-right: auto;
    margin-left: auto;
    width: 100%; }

@media screen and (min-width: 48em) {
  .WhatYouGet {
    max-width: 534px;
    padding-right: 48px;
    padding-left: 24px; }
    .Block--service:nth-of-type(3n-1) .WhatYouGet {
      padding-left: 0; } }

@media screen and (min-width: 48em) {
  .Info .Services {
    counter-reset: i;
    max-width: 1140px; }
    .Info .Services .Grid-cell,
    .Info .Services .Service-item {
      display: flex;
      flex-direction: column; }
    .Info .Services .Service-item {
      flex-grow: 1; }
      .Info .Services .Service-item .Button {
        margin-top: auto; } }

.Info .Service-item {
  counter-increment: i;
  max-width: 294px; }
  .Info .Service-item::before {
    height: 39px;
    width: 39px;
    background-color: #ffffff;
    border-radius: 100%;
    content: counter(i);
    color: #fad018;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "IBM Plex Serif", serif;
    font-weight: 700;
    margin-bottom: 32px; }
  .Info .Service-item .Button {
    color: #ffffff;
    font-weight: 700; }

.Info .Service-title {
  margin-bottom: 16px; }

/* Mission */
.Slider--mission {
  margin-right: -24px;
  margin-left: -24px; }
  .Slider--mission .Mission-body {
    padding-left: 24px;
    padding-right: 24px; }

@media screen and (max-width: 47.9375em) {
  .Mission-heading {
    font-size: 18px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Mission-heading {
    font-size: calc(18px + 2 * ((100vw - 768px) / 256)); } }

@media screen and (min-width: 64em) {
  .Mission-heading {
    font-size: 20px; } }

.Mission-items {
  position: relative; }
  @media screen and (min-width: 83em) {
    .Mission-items {
      display: grid;
      grid-template-columns: 200px 1fr auto 1fr auto auto 458px;
      grid-template-rows: auto 82px auto 80px auto; } }
  @media screen and (min-width: 64em) and (max-width: 82.9375em) {
    .Mission-items {
      display: grid;
      grid-template-columns: 200px 1fr auto auto auto auto 458px;
      grid-template-rows: auto 82px auto 80px auto; } }
  @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
    .Mission-items {
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(5, auto); } }

.Mission-title {
  color: #fad018;
  margin-bottom: 16px; }

.Mission-item {
  display: flex; }

.Mission-image {
  display: block; }

@media screen and (max-width: 47.9375em) {
  .Mission-object {
    display: block;
    overflow: hidden; }
  .Slider-item:nth-child(1) .Mission-image path:last-child {
    animation: smar 2s linear infinite; }
  .Slider-item:nth-child(2) .Mission-image path:last-child {
    animation: smar2 2s linear infinite; }
  @keyframes smar {
    0% {
      transform: translateX(0); }
    50% {
      transform: translateX(5%); }
    100% {
      transform: translateX(0); } }
  @keyframes smar2 {
    0% {
      transform: translateX(-5%); }
    50% {
      transform: translateX(0); }
    100% {
      transform: translateX(-5%); } }
  .Slider-item:first-child .Mission-image {
    margin-top: 5.4px; }
  .Slider-item:nth-child(2) .Mission-image {
    position: relative;
    left: 50%;
    transform: translateX(-47%); }
  .Slider-item:nth-child(3) .Mission-image {
    position: relative;
    left: 100%;
    transform: translateX(-100%);
    margin-top: 0.5px; }
  .Slider-item:nth-child(2) .Mission-body {
    text-align: center; }
  .Slider-item:nth-child(3) .Mission-body {
    text-align: right; } }

@media screen and (min-width: 48em) {
  .Mission-object {
    display: block;
    flex-shrink: 0; } }
  @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
    .Mission-object {
      align-self: center;
      margin-top: 24px;
      margin-bottom: 24px; } }

@media screen and (min-width: 48em) {
    .Mission-object--1 {
      justify-self: end; } }
    @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
      .Mission-object--1 {
        grid-column: 5/6;
        grid-row: 1/2; } }
    @media screen and (min-width: 48em) and (min-width: 64em) {
      .Mission-object--1 {
        grid-column: 7/8;
        grid-row: 1/3; } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
      .Mission-object--1 .Mission-image {
        width: 252px; } }

@media screen and (min-width: 48em) {
    .Mission-object--2 {
      margin-top: 5px; } }
    @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
      .Mission-object--2 {
        grid-column: 2/3;
        grid-row: 2/3; } }
    @media screen and (min-width: 48em) and (min-width: 64em) {
      .Mission-object--2 {
        grid-column: 2/8;
        grid-row: 2/4; } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
      .Mission-object--2 .Mission-image {
        width: 330px; } }

@media screen and (min-width: 48em) {
    .Mission-object--3 {
      margin-top: -2px; } }
    @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
      .Mission-object--3 {
        grid-column: 5/6;
        grid-row: 3/4; } }
    @media screen and (min-width: 48em) and (min-width: 64em) {
      .Mission-object--3 {
        grid-column: 1/3;
        grid-row: 5/6; } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
      .Mission-object--3 .Mission-image {
        width: 227px; } }

@media screen and (min-width: 48em) {
  .Mission-body {
    max-width: 506px; }
    .Mission-body :last-child {
      margin-bottom: 0; } }
  @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
    .Mission-body {
      align-self: center; } }

@media screen and (min-width: 48em) {
    .Mission-body--1 {
      align-self: center;
      justify-self: end;
      text-align: right; } }
    @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
      .Mission-body--1 {
        grid-column: 1/5;
        grid-row: 1/2;
        margin-right: 48px; } }
    @media screen and (min-width: 48em) and (min-width: 64em) {
      .Mission-body--1 {
        grid-column: 1/7;
        grid-row: 1/2;
        margin-right: 68px;
        margin-top: 82px; } }
  @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
    .Mission-body--2 {
      grid-column: 3/6;
      grid-row: 2/3;
      margin-left: 48px; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .Mission-body--2 {
      grid-column: 6/8;
      grid-row: 3/4;
      align-self: end;
      justify-self: end; } }
  @media screen and (min-width: 48em) and (min-width: 64em) and (max-width: 82.9375em) {
    .Mission-body--2 {
      margin-top: 400px;
      margin-bottom: -175px; } }
  @media screen and (min-width: 48em) and (min-width: 37.5em) and (max-width: 63.9375em) {
    .Mission-body--3 {
      grid-column: 1/5;
      grid-row: 3/4;
      justify-self: end;
      text-align: right;
      margin-right: 48px; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .Mission-body--3 {
      grid-column: 4/8;
      grid-row: 5/6;
      align-self: center; } }

@media screen and (min-width: 48em) {
    .Mission-item:nth-of-type(1) .Mission-body {
      align-self: center;
      margin-right: 68px;
      margin-left: auto;
      text-align: right; } }

@media screen and (max-width: 63.9375em) {
  .Mission-pipe {
    display: none; } }

@media screen and (min-width: 64em) {
  .Mission-pipe {
    position: absolute; }
    .Mission-pipe--1 {
      top: 222.6px;
      right: -0.5px;
      display: flex; }
      .Mission-pipe--1 .Mission-pipeImage {
        height: 403px; } }
    @media screen and (min-width: 64em) and (min-width: 48em) and (max-width: 68.75em) {
      .Mission-pipe--1 {
        max-width: 310px;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        overflow: hidden; } }

@media screen and (min-width: 64em) {
    .Mission-pipe--2 {
      top: 460px;
      left: 13px; }
      .Mission-pipe--2 .Mission-pipeImage {
        height: 524px; }
  .Mission-pipeImage {
    max-width: none; } }
  @media screen and (min-width: 64em) and (min-width: 48em) and (max-width: 63.9375em) {
    .Mission-pipe--1 .Mission-pipeImage {
      width: 231px; }
    .Mission-pipe--2 .Mission-pipeImage {
      width: 110px; } }

@media screen and (max-width: 47.9375em) {
  .Brands-heading {
    font-size: 18px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Brands-heading {
    font-size: calc(18px + 2 * ((100vw - 768px) / 256)); } }

@media screen and (min-width: 64em) {
  .Brands-heading {
    font-size: 20px; } }

.Brands-items {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 48em) {
    .Brands-items {
      margin-top: 3rem; } }
  .Brands-items .Brands-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 48px; }
    @media screen and (max-width: 48em) {
      .Brands-items .Brands-item {
        flex-direction: column;
        gap: 0; } }
    .Brands-items .Brands-item:not(:last-of-type) {
      margin-bottom: 48px; }
      @media screen and (min-width: 64em) {
        .Brands-items .Brands-item:not(:last-of-type) {
          margin-bottom: 6rem; } }
    .Brands-items .Brands-item picture {
      width: 300px;
      height: auto;
      padding: 0 48px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .Brands-items .Brands-item picture img {
        width: 100%;
        max-width: 300px; }
      @media screen and (max-width: 48em) {
        .Brands-items .Brands-item picture {
          width: 100%;
          margin-bottom: 24px; } }
    .Brands-items .Brands-item .Brands-body {
      width: calc(100% - 300px); }
      @media screen and (max-width: 48em) {
        .Brands-items .Brands-item .Brands-body {
          width: 100%; } }
      .Brands-items .Brands-item .Brands-body .Brands-title {
        color: #fad018; }
      .Brands-items .Brands-item .Brands-body .Brands-link {
        color: #343434;
        font-weight: 600; }

/* Story */
.Story-intro {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 48px auto;
  margin-bottom: 32px; }
  @media screen and (min-width: 48em) {
    .Story-intro {
      margin-bottom: 72px; } }

.StoryIntro-object {
  grid-column: 1/2;
  grid-row: 1/3; }
  @media screen and (min-width: 48em) {
    .StoryIntro-object {
      grid-row: 1 / 4; } }

.StoryIntro-object, .StoryIntro-image {
  display: block;
  width: 100%; }

.StoryIntro-body {
  grid-column: 1/2;
  grid-row: 2/4; }

.StoryIntro-innerBody {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 16px;
  max-width: 616px;
  padding: 24px 16px 0px; }
  @media screen and (min-width: 48em) {
    .StoryIntro-innerBody {
      padding: 24px;
      margin-bottom: 24px; } }
  .StoryIntro-innerBody :last-child {
    margin-bottom: 0; }

.Story-item {
  margin-top: -32px;
  margin-left: -24px; }
  @media screen and (min-width: 48em) {
    .Story-item {
      display: flex;
      justify-content: space-between; } }
  .Story-item + .Story-item {
    padding-top: 32px; }
    @media screen and (min-width: 48em) {
      .Story-item + .Story-item {
        padding-top: 72px; } }
  @media screen and (min-width: 48em) {
    .Story-item:nth-child(odd) {
      flex-direction: row-reverse; }
      .Story-item:nth-child(odd) .StoryItem-body {
        max-width: 530px; }
      .Story-item:nth-child(odd) .StoryItem-objects {
        max-width: 660px; }
    .Story-item:nth-child(even) .StoryItem-objects {
      max-width: 530px; }
    .Story-item:nth-child(even) .StoryItem-body {
      max-width: 660px; }
      .Story-item:nth-child(even) .StoryItem-body > p,
      .Story-item:nth-child(even) .StoryItem-body > ul,
      .Story-item:nth-child(even) .StoryItem-body > ol {
        max-width: 469px; } }
  .Story-item :last-child {
    margin-bottom: 0; }

.StoryItem-body, .StoryItem-objects {
  padding-top: 32px;
  padding-left: 24px; }
  @media screen and (min-width: 48em) {
    .StoryItem-body, .StoryItem-objects {
      width: 50%; } }

.StoryItem-body {
  align-self: center; }

.StoryItem-objects {
  display: flex;
  align-self: start;
  margin-left: -8px; }
  @media screen and (min-width: 48em) {
    .StoryItem-objects {
      margin-left: -24px; } }
  @media screen and (min-width: 48em) {
    .StoryItem-objects.has-multiple {
      width: 100%; } }
  .StoryItem-objects.has-multiple > .StoryItem-object {
    width: 50%; }

.StoryItem-object {
  margin-left: 8px;
  align-self: flex-start; }
  @media screen and (min-width: 48em) {
    .StoryItem-object {
      margin-left: 24px; }
      .StoryItem-object + .StoryItem-object {
        margin-top: 24px; } }
  @media screen and (max-width: 47.9375em) {
    .StoryItem-object:first-child:not(:only-child) {
      margin-top: 24px; } }

.StoryItem-object, .StoryItem-image {
  display: block;
  width: 100%; }

/* Team */
.Members {
  display: flex;
  flex-wrap: wrap;
  margin-top: -24px;
  margin-left: -24px; }

.Member {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding-top: 24px;
  padding-left: 24px;
  width: 50%; }
  .Member-object {
    padding-bottom: 100%;
    position: relative; }
  .Member-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
    .Member {
      width: 33.33333%; } }
  @media screen and (min-width: 64em) {
    .Member {
      width: 25%; } }
  .Member.u-shape::after {
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    animation: 0;
    border-radius: 8px;
    top: 24px;
    left: 24px; }
  .Member:hover.u-shape::after {
    animation: button 800ms linear 1;
    transform: rotate(5.14deg); }
  .Member.is-jobs {
    color: currentColor;
    text-decoration: none; }
  .Member:not(.is-jobs):not(:hover) .Member-body {
    opacity: 0;
    visibility: hidden; }
  .Member-object {
    grid-column: 1/2;
    grid-row: 1/2; }
  .Member-body {
    position: relative;
    z-index: 1;
    grid-column: 1/2;
    grid-row: 1/2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    background-color: rgba(5, 5, 5, 0.5);
    border-radius: 8px;
    color: #ffffff;
    text-align: center;
    padding: 8px; }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .Member-body {
        padding: 16px; } }
    @media screen and (min-width: 48em) {
      .Member-body {
        padding: 24px; } }
  .Member-title {
    grid-column: 1/2;
    grid-row: 2/3;
    color: #fad018; }
  .Member-function {
    font-size: 14px;
    grid-column: 1/2;
    grid-row: 3/4;
    align-self: end;
    margin-top: 16px; }
    @media screen and (min-width: 48em) {
      .Member-function {
        margin-top: 24px; } }

.Member-large {
  width: calc(100% / 2);
  pointer-events: none; }
  .Member-large::after {
    display: none; }
  .Member-large .Members-image {
    border-radius: 0px 48px; }
  @media screen and (max-width: 64em) {
    .Member-large {
      width: 66.66667%; } }
  @media screen and (max-width: 48em) {
    .Member-large {
      width: 100%; } }

.Team-object {
  border-top-right-radius: 48px;
  border-bottom-left-radius: 48px;
  overflow: hidden; }

.Team .Play-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

/* Contact */
.Block--contact {
  position: relative;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 220px auto auto auto auto;
  margin-left: -8px; }
  @media screen and (min-width: 48em) {
    .Block--contact {
      margin-left: -24px;
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      grid-template-rows: repeat(4, auto); } }
  @media screen and (max-width: 47.9375em) {
    .Block--contact {
      margin-top: -56px; } }

.Contact-title, .Contact-item, .Contact-object, .Contact-form {
  margin-left: 8px; }
  @media screen and (min-width: 48em) {
    .Contact-title, .Contact-item, .Contact-object, .Contact-form {
      margin-left: 24px; } }

.Contact-map {
  position: relative;
  z-index: -1;
  grid-column: 1/3;
  grid-row: 2/7; }
  @media screen and (max-width: 47.9375em) {
    .Contact-map {
      margin-right: -24px;
      margin-left: -24px; } }
  @media screen and (min-width: 37.5em) {
    .Contact-map {
      right: 50%;
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      width: 100vw; } }
  @media screen and (min-width: 48em) {
    .Contact-map {
      grid-column: 1/4;
      grid-row: 3/5; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .Contact-map {
      margin-bottom: -120px; } }

.Contact-title {
  position: relative;
  z-index: 1;
  grid-column: 1/3;
  grid-row: 4/5; }
  @media screen and (max-width: 47.9375em) {
    .Contact-title {
      font-size: 16px; } }
  @media screen and (min-width: 48em) {
    .Contact-title {
      grid-column: 1/2;
      grid-row: 1/2; } }

.Contact-item {
  grid-column: 1/3;
  grid-row: 5/6;
  margin-bottom: 24px; }
  @media screen and (min-width: 48em) {
    .Contact-item {
      grid-column: 1/2;
      grid-row: 2/3; } }
  @media screen and (max-width: 47.9375em) {
    .Contact-item {
      position: relative;
      z-index: 0;
      justify-self: start;
      position: relative;
      padding-right: 24px;
      padding-bottom: 24px; }
      .Contact-item :last-child {
        margin-bottom: 0; }
      .Contact-item::before {
        position: absolute;
        top: -61px;
        right: 0;
        bottom: 0;
        left: -24px;
        z-index: -1;
        background-color: #ffffff;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        content: ''; } }
  .Contact-item strong {
    font-weight: 700; }
  .Contact-item a:not([class]) {
    color: currentColor;
    text-decoration: none; }
    .Contact-item a:not([class]):hover {
      text-decoration: underline; }

.Contact-object {
  align-self: end;
  justify-self: start; }
  .Contact-object:first-of-type {
    grid-column: 1/2;
    grid-row: 6/7; }
  .Contact-object:last-of-type {
    grid-column: 2/3;
    grid-row: 6/7; }
    @media screen and (max-width: 47.9375em) {
      .Contact-object:last-of-type {
        margin-top: -48px; } }
    @media screen and (max-width: 47.9375em) {
      .Contact-object:last-of-type {
        margin-bottom: 48px; } }
  @media screen and (min-width: 48em) {
    .Contact-object:first-of-type {
      grid-column: 1/2;
      grid-row: 3/4; }
    .Contact-object:last-of-type {
      grid-column: 2/3;
      grid-row: 2/4; } }

.Contact-form {
  grid-column: 1/3;
  grid-row: 1/3;
  align-self: end;
  background-color: #fad018;
  padding: 32px 24px; }
  .Contact-form a:not([class]) {
    color: #ffffff; }
  @media screen and (min-width: 48em) {
    .Contact-form {
      grid-column: 3/4;
      grid-row: 2/4;
      border-top-left-radius: 48px;
      border-bottom-right-radius: 48px; } }
  @media screen and (max-width: 47.9375em) {
    .Contact-form {
      position: relative;
      border-bottom-left-radius: 48px; }
      .Contact-form::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        background-color: #fad018;
        content: '';
        width: 24px; } }
  @media screen and (max-width: 47.9375em) {
    .Contact-form .Form-field--textarea {
      max-height: 140px; } }
  .Contact-form .Button--secondary {
    background-color: #ffffff; }

.GoogleMaps {
  position: relative;
  height: 725px;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .GoogleMaps {
      height: 960px; } }
  .GoogleMaps::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: 400px; }

/* Looking Back */
.LookingBack {
  align-items: start;
  padding-bottom: 16px; }
  @media screen and (min-width: 48em) {
    .LookingBack {
      margin-left: -24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto; }
      .LookingBack-object, .LookingBack-body,
      .LookingBack .CallToAction {
        margin-left: 24px; } }
  @media screen and (max-width: 47.9375em) {
    .LookingBack .CallToAction {
      margin-top: 48px; } }
  @media screen and (min-width: 48em) {
    .LookingBack .CallToAction {
      grid-column: 1/2;
      grid-row: 2/3;
      align-self: end; } }
  .Grid-items + .LookingBack {
    margin-top: 32px; }
  .LookingBack .Block-header {
    margin-bottom: 0; }
    .LookingBack .Block-header::before {
      background-color: #ffffff;
      z-index: 0; }
  .LookingBack .Block-title:not(.Related-title) {
    background-color: #fad018;
    color: #ffffff;
    z-index: 1; }
  @media screen and (min-width: 48em) {
    .LookingBack-body {
      grid-column: 2/3;
      grid-row: 2/3;
      background-color: #fad018;
      border-bottom-right-radius: 48px;
      border-bottom-left-radius: 48px;
      max-width: 612px;
      padding-right: 24px;
      padding-bottom: 24px;
      padding-left: 24px; } }
  @media screen and (max-width: 47.9375em) {
    .LookingBack-body {
      background-color: #fad018;
      padding-right: 24px;
      padding-bottom: 24px;
      padding-left: 24px;
      margin-top: 32px;
      margin-right: -24px;
      margin-left: -24px;
      padding-right: 24px;
      padding-left: 24px;
      position: relative; }
      .LookingBack-body, .LookingBack-body::before {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px; }
      .LookingBack-body::before {
        position: absolute;
        top: 0;
        right: 24px;
        bottom: -16px;
        left: 24px;
        z-index: -1;
        background-color: #fdefb0;
        content: ''; } }
  .LookingBack-object {
    display: block; }
    @media screen and (min-width: 48em) {
      .LookingBack-object {
        grid-column: 1/3;
        grid-row: 1/2; } }
    @media screen and (max-width: 47.9375em) {
      .LookingBack-object {
        position: relative;
        padding-bottom: 76.4526%;
        border-top-left-radius: 32px;
        border-bottom-right-radius: 32px; }
        .LookingBack-object .LookingBack-image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: cover;
          height: 100%; } }
  @media screen and (min-width: 48em) {
    .LookingBack-object, .LookingBack-object::after,
    .LookingBack-object .LookingBack-image {
      border-top-right-radius: 32px;
      border-bottom-left-radius: 32px; } }
  @media screen and (max-width: 47.9375em) {
    .LookingBack-object, .LookingBack-object::after,
    .LookingBack-object .LookingBack-image {
      border-top-left-radius: 32px;
      border-bottom-right-radius: 32px; } }
  @media screen and (max-width: 47.9375em) {
    .LookingBack-object::after {
      content: none; } }
  @media screen and (min-width: 48em) {
    .LookingBack-object::after {
      right: 5.2% !important; } }
  .LookingBack-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 8px; }
    @media screen and (min-width: 48em) {
      .LookingBack-header {
        height: 24px; } }
    .LookingBack-header .LookingBack-title {
      background-color: #fad018;
      border-radius: 40px;
      color: #ffffff;
      padding: 16px 24px;
      margin-bottom: 0;
      line-height: 1.27778; }
      @media screen and (min-width: 48em) {
        .LookingBack-header .LookingBack-title {
          transform: translateY(-50%);
          padding-right: 48px;
          padding-left: 48px; } }
    @media screen and (max-width: 47.9375em) {
      .LookingBack-header {
        z-index: 1; }
        .LookingBack-header::before {
          position: absolute;
          top: 0;
          right: -24px;
          bottom: 50%;
          left: -24px;
          z-index: -1;
          background-color: #ffffff;
          content: ''; } }

.Grid-items .Foxtrot {
  color: #fcb610;
  margin-bottom: 8px; }

.Related {
  color: currentColor;
  display: flex;
  flex-direction: column-reverse;
  min-width: 272px;
  text-decoration: none; }
  .Related-title {
    margin-bottom: 0;
    margin-top: 16px; }
  .Related-object {
    padding-bottom: 100%; }
    @media screen and (min-width: 48em) {
      .Related-object {
        padding-bottom: 45.42484%; }
        .Container--sm .Related-object {
          padding-bottom: 75%; } }
    .Related-object:not(:empty) {
      position: relative;
      z-index: 1; }
  .Related-image {
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    object-fit: cover; }
  .Related:hover .Related-image {
    transform: scale(1.1); }

@media screen and (min-width: 48em) {
  .Slider--related .Slider-items {
    margin-left: -16px;
    width: auto; }
    .Container--sm .Slider--related .Slider-items {
      margin-left: -48px; }
  .Slider--related .Slider-item {
    padding-left: 16px;
    width: 50%; }
    .Container--sm .Slider--related .Slider-item {
      padding-left: 48px; } }

@media screen and (max-width: 47.9375em) {
  .Slider--related .Slider-item {
    flex-basis: 50%;
    flex-shrink: 1; } }

.ServiceColumns .Grid {
  margin-left: -80px; }

.ServiceColumns .Grid-cell {
  padding-left: 80px; }

.ServiceColumns-title {
  margin-bottom: 8px; }
  @media screen and (max-width: 47.9375em) {
    .ServiceColumns-title {
      font-size: 18px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .ServiceColumns-title {
      font-size: calc(18px + 2 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .ServiceColumns-title {
      font-size: 20px; } }

.ServiceColumns-content {
  position: relative;
  z-index: 2; }

.ServiceColumns-header {
  padding-bottom: 16px; }

.ServiceColumns--grey {
  background: #f5f5f5;
  padding: 48px 97px;
  border-top-left-radius: 48px;
  border-bottom-right-radius: 48px;
  position: relative; }
  @media screen and (max-width: 64em) {
    .ServiceColumns--grey {
      padding: 48px 48px; } }
  @media screen and (max-width: 48em) {
    .ServiceColumns--grey {
      padding: 48px 24px; } }

.ServiceColumns-subTitle {
  font-size: 16px;
  line-height: 25px;
  font-style: italic; }

.ServiceColumns-titleContainer {
  position: relative; }

.ServiceColumns-itemTitle {
  font-weight: bold;
  font-family: "IBM Plex Serif", serif;
  display: inline-block;
  position: relative;
  z-index: 2; }
  .ServiceColumns-itemTitle--grey {
    font-size: 16px;
    line-height: 23px;
    position: relative; }
  .ServiceColumns-itemTitle--white {
    font-size: 16px;
    line-height: 26px;
    color: #00a779; }

.ServiceColumns-backgroundTitle {
  position: absolute;
  top: 5px;
  z-index: 1; }

.ServiceColumns-text p {
  font-size: 16px;
  line-height: 150%; }
  .ServiceColumns-text p strong {
    font-weight: 700; }
  .ServiceColumns-text p:last-of-type {
    margin-bottom: 0; }

.ServiceColumns-item {
  display: flex;
  flex-direction: column;
  height: 100%; }

.ServiceColumns-itemTextBottom {
  margin-top: auto;
  padding-top: 40px; }
  .ServiceColumns-itemTextBottom strong {
    font-weight: 700; }
  .ServiceColumns-itemTextBottom p:last-of-type {
    margin-bottom: 0; }

.ListBlock-itemTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #343434; }

.ListBlock-grid {
  display: flex;
  justify-content: space-evenly; }
  @media screen and (max-width: 47.9375em) {
    .ListBlock-grid {
      padding-left: 0px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .ListBlock-grid {
      padding-left: calc(0px + 150 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .ListBlock-grid {
      padding-left: 150px; } }
  @media screen and (max-width: 48em) {
    .ListBlock-grid {
      flex-direction: column; } }

.ListBlock-item {
  width: 26%; }
  @media screen and (max-width: 48em) {
    .ListBlock-item {
      width: 100%; } }

.ListBlock ul {
  list-style-type: none; }
  .ListBlock ul li::before {
    height: 8px;
    width: 8px;
    background-color: #00d198;
    border-radius: 100%;
    content: '';
    display: inline-block;
    margin-top: 9px;
    margin-right: 16px;
    margin-left: -24px;
    vertical-align: top; }

.Download {
  background-image: url("/assets/img/background-download.jpg");
  background-position: center;
  background-repeat: no-repeat;
  min-height: 217px;
  border-radius: 8px;
  border: dashed 2px #fad018;
  position: relative;
  padding: 60px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .Download-inner {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    border: solid 2px #fad018;
    width: calc(100% - 5px);
    transform: translate(2.5px, 2.5px);
    height: calc(100% - 5px); }
  .Download-title {
    font-family: "IBM Plex Serif", serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: normal;
    color: #050505;
    margin-bottom: 24px;
    text-align: center; }
  .Download-link {
    position: relative;
    z-index: 5; }

.BrochureModal-downloadLink {
  visibility: hidden;
  opacity: 0; }

.BrochureModal-object {
  display: block;
  height: 100%; }

.BrochureModal-imgContainer {
  height: 100%; }

.BrochureModal-image {
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  height: 100%;
  width: 100%;
  object-fit: cover; }
  @media screen and (max-width: 64em) {
    .BrochureModal-image {
      border-radius: 40px; } }

.BrochureModal-content {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 48px;
  padding-top: 24px;
  padding-bottom: 24px; }
  @media screen and (max-width: 64em) {
    .BrochureModal-content {
      padding-left: 0px;
      padding-right: 24px; } }

.BrochureModal-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  font-family: "IBM Plex Serif", serif; }

.TextImage-title {
  margin-bottom: 8px; }
  @media screen and (max-width: 47.9375em) {
    .TextImage-title {
      font-size: 26px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .TextImage-title {
      font-size: calc(26px + 6 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .TextImage-title {
      font-size: 32px; } }

.TextImage-subTitle {
  font-size: 16px;
  line-height: 25px;
  font-style: italic; }

.TextImage-image {
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px; }
  @media screen and (max-width: 64em) {
    .TextImage-image {
      border-radius: 32px;
      width: 100%; } }

@media screen and (max-width: 64em) {
  .TextImage-object {
    width: 100%; } }

.TextImage-titleContainer {
  padding-bottom: 24px;
  position: relative; }

.TextImage-contentContainer {
  display: flex;
  flex-direction: column;
  padding-right: 110px; }
  @media screen and (max-width: 48em) {
    .TextImage-contentContainer {
      padding-right: 0; } }

.TextImage-itemTitle {
  font-weight: bold;
  font-family: "IBM Plex Serif", serif;
  display: inline-block;
  z-index: 2;
  font-size: 18px;
  line-height: 23px;
  position: relative; }

.TextImage-backgroundTitle {
  position: absolute;
  top: 5px;
  z-index: 1; }

.HomeBanner {
  position: relative;
  min-height: 788px;
  max-height: 100vh;
  padding: 48px 0px;
  padding-top: 92px;
  padding-bottom: 170px;
  display: flex;
  justify-content: center;
  align-items: flex-end; }
  @media screen and (max-width: 48em) {
    .HomeBanner {
      padding-bottom: 72px;
      padding-top: 130px; } }
  .HomeBanner:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(52, 52, 52, 0.8), rgba(52, 52, 52, 0.8));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  @media screen and (max-width: 48em) {
    .HomeBanner {
      min-height: auto; } }
  .HomeBanner-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1; }
  .HomeBanner-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1; }
  .HomeBanner-title {
    font-family: "IBM Plex Serif", serif;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #ffffff; }
    @media screen and (max-width: 47.9375em) {
      .HomeBanner-title {
        font-size: 40px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .HomeBanner-title {
        font-size: calc(40px + 24 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .HomeBanner-title {
        font-size: 64px; } }
  .HomeBanner-titleTop {
    font-family: "Barlow", sans-serif;
    font-weight: normal;
    display: inline-block; }
  .HomeBanner-titleBottom {
    font-weight: bold;
    display: inline-block; }
  .HomeBanner-content {
    position: relative;
    z-index: 2;
    text-align: center; }
  .HomeBanner-text {
    margin-bottom: 40px; }
    .HomeBanner-text p {
      font-size: 20px;
      font-weight: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .HomeBanner-text p:last-of-type {
      margin-bottom: 0; }
  .HomeBanner-dot {
    color: #fad018;
    display: inline-block; }

.ServiceBanner {
  height: calc(100vh - (104px));
  position: relative; }
  @media screen and (max-width: 64em) {
    .ServiceBanner {
      height: auto; } }
  .ServiceBanner > .Container {
    height: 100%; }
  .ServiceBanner .Grid {
    height: 100%;
    align-items: center; }
    @media screen and (max-width: 64em) {
      .ServiceBanner .Grid {
        flex-direction: column;
        flex-wrap: nowrap; } }
  @media screen and (max-width: 64em) {
    .ServiceBanner-img {
      order: 2;
      padding-top: 24px !important; } }
  @media screen and (max-width: 64em) {
    .ServiceBanner-content {
      order: 1;
      padding-top: 24px !important; } }
  .ServiceBanner-scrollDown {
    display: flex;
    flex-direction: column;
    color: #00a779;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.56;
    align-items: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    cursor: pointer; }
    @media screen and (max-width: 64em) {
      .ServiceBanner-scrollDown {
        display: none; } }
  .ServiceBanner-arrowDown {
    animation: bounce 2s infinite; }
  .ServiceBanner-scrollDownText {
    margin-bottom: 10px;
    display: inline-block; }
  .ServiceBanner-contentContainer {
    padding-left: 50px; }
    @media screen and (max-width: 64em) {
      .ServiceBanner-contentContainer {
        padding-left: 0; } }
  .ServiceBanner-text {
    max-width: 620px; }
  .ServiceBanner-title {
    margin-bottom: 32px; }

@media screen and (max-width: 47.9375em) {
  .Block--icons .IconsTitle {
    font-size: 20px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--icons .IconsTitle {
    font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }

@media screen and (min-width: 64em) {
  .Block--icons .IconsTitle {
    font-size: 38px; } }

@media screen and (max-width: 47.9375em) {
  .Block--icons .IconsTitle {
    margin-bottom: 40px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--icons .IconsTitle {
    margin-bottom: calc(40px + 30 * ((100vw - 768px) / 256)); } }

@media screen and (min-width: 64em) {
  .Block--icons .IconsTitle {
    margin-bottom: 70px; } }

.Block--icons .IconsTitle strong {
  color: #fad018; }

.Block--icons .IconsItem-icon {
  background-color: #fad018;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-bottom: 30px !important; }
  @media screen and (max-width: 47.9375em) {
    .Block--icons .IconsItem-icon {
      width: 140px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Block--icons .IconsItem-icon {
      width: calc(140px + 5 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .Block--icons .IconsItem-icon {
      width: 145px; } }
  @media screen and (max-width: 47.9375em) {
    .Block--icons .IconsItem-icon {
      height: 140px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Block--icons .IconsItem-icon {
      height: calc(140px + 5 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .Block--icons .IconsItem-icon {
      height: 145px; } }
  @media screen and (max-width: 47.9375em) {
    .Block--icons .IconsItem-icon .Icon {
      width: 60px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Block--icons .IconsItem-icon .Icon {
      width: calc(60px + 10 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .Block--icons .IconsItem-icon .Icon {
      width: 70px; } }

.Block--icons .IconsItem-title {
  font-weight: 600;
  display: flex;
  justify-content: center;
  min-height: 60px;
  text-align: center;
  width: 100%;
  line-height: initial; }
  @media screen and (max-width: 47.9375em) {
    .Block--icons .IconsItem-title {
      font-size: 18px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Block--icons .IconsItem-title {
      font-size: calc(18px + 2 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .Block--icons .IconsItem-title {
      font-size: 20px; } }
  @media screen and (max-width: 47.9375em) {
    .Block--icons .IconsItem-title {
      min-height: auto; } }

.Block--icons .IconsItem-line {
  width: 100%;
  height: 3px;
  background-color: #fad018;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex; }

.Block--icons .IconsItem-text p {
  text-align: center; }

.Block--jobs .JobsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px; }

.Block--jobs .JobsTitle {
  margin-bottom: 40px; }
  @media screen and (max-width: 47.9375em) {
    .Block--jobs .JobsTitle {
      font-size: 20px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Block--jobs .JobsTitle {
      font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .Block--jobs .JobsTitle {
      font-size: 38px; } }
  .Block--jobs .JobsTitle-dot {
    color: #fad018; }

.Block--jobs .JobItem {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 60px;
  border-bottom-left-radius: 60px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 48px !important;
  transition: ease-in-out .5s;
  padding: 20px;
  text-decoration: none; }
  @media screen and (max-width: 47.9375em) {
    .Block--jobs .JobItem {
      width: 200px; } }
  @media screen and (min-width: 48em) and (max-width: 79.9375em) {
    .Block--jobs .JobItem {
      width: calc(200px + 100 * ((100vw - 768px) / 512)); } }
  @media screen and (min-width: 80em) {
    .Block--jobs .JobItem {
      width: 300px; } }
  @media screen and (max-width: 47.9375em) {
    .Block--jobs .JobItem {
      height: 200px; } }
  @media screen and (min-width: 48em) and (max-width: 79.9375em) {
    .Block--jobs .JobItem {
      height: calc(200px + 100 * ((100vw - 768px) / 512)); } }
  @media screen and (min-width: 80em) {
    .Block--jobs .JobItem {
      height: 300px; } }
  @media screen and (max-width: 47.9375em) {
    .Block--jobs .JobItem {
      margin-left: 0;
      margin-right: 0; } }
  .Block--jobs .JobItem--content .JobItem-title {
    max-width: 70%; }
  .Block--jobs .JobItem--black {
    background-color: #343434; }
    .Block--jobs .JobItem--black .JobItem-title {
      color: #fad018; }
  .Block--jobs .JobItem--yellow {
    background-color: #fad018; }
    .Block--jobs .JobItem--yellow .JobItem-title {
      color: #343434; }
  @media screen and (max-width: 47.9375em) {
    .Block--jobs .JobItem {
      width: 100%;
      margin-bottom: 24px !important; } }
  .Block--jobs .JobItem-plus {
    position: absolute;
    right: 20px;
    bottom: 20px; }
    @media screen and (max-width: 47.9375em) {
      .Block--jobs .JobItem-plus {
        width: 80px; } }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .Block--jobs .JobItem-plus {
        width: calc(80px + 40 * ((100vw - 768px) / 512)); } }
    @media screen and (min-width: 80em) {
      .Block--jobs .JobItem-plus {
        width: 120px; } }
    @media screen and (max-width: 47.9375em) {
      .Block--jobs .JobItem-plus {
        height: 80px; } }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .Block--jobs .JobItem-plus {
        height: calc(80px + 40 * ((100vw - 768px) / 512)); } }
    @media screen and (min-width: 80em) {
      .Block--jobs .JobItem-plus {
        height: 120px; } }
    .Block--jobs .JobItem-plus span {
      background-color: #ffffff;
      display: flex;
      position: absolute; }
      .Block--jobs .JobItem-plus span:nth-of-type(1) {
        width: 2px;
        height: 100%;
        left: 0;
        right: 0;
        margin-right: auto;
        margin-left: auto; }
      .Block--jobs .JobItem-plus span:nth-of-type(2) {
        width: 100%;
        height: 2px;
        top: 0;
        bottom: 0;
        margin-bottom: auto;
        margin-top: auto; }
  .Block--jobs .JobItem-object {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0; }
    .Block--jobs .JobItem-object::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #343434;
      background: linear-gradient(180deg, #343434 0%, rgba(52, 52, 52, 0) 60%, rgba(52, 52, 52, 0) 100%); }
    .Block--jobs .JobItem-object::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0;
      transition: ease-in-out .5s; }
  .Block--jobs .JobItem-image {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .Block--jobs .JobItem-label {
    position: relative;
    z-index: 1;
    color: #fad018;
    text-decoration: none;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 60px;
    width: fit-content;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 47.9375em) {
      .Block--jobs .JobItem-label {
        font-size: 14px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Block--jobs .JobItem-label {
        font-size: calc(14px + 2 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .Block--jobs .JobItem-label {
        font-size: 16px; } }
  .Block--jobs .JobItem-title {
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-family: "IBM Plex Serif", serif;
    font-weight: 600;
    text-decoration: none; }
    @media screen and (max-width: 47.9375em) {
      .Block--jobs .JobItem-title {
        font-size: 16px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Block--jobs .JobItem-title {
        font-size: calc(16px + 4 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .Block--jobs .JobItem-title {
        font-size: 20px; } }
  .Block--jobs .JobItem-desc {
    position: relative;
    z-index: 1;
    color: #ffffff;
    text-decoration: none;
    max-width: 70%;
    opacity: 0;
    transition: ease-in-out .5s; }
    @media screen and (max-width: 47.9375em) {
      .Block--jobs .JobItem-desc {
        font-size: 14px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Block--jobs .JobItem-desc {
        font-size: calc(14px + 2 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .Block--jobs .JobItem-desc {
        font-size: 16px; } }
  .Block--jobs .JobItem-arrow {
    position: absolute;
    z-index: 3;
    right: 100%;
    bottom: 20px;
    transition: ease-in-out .5s;
    padding: 5px 25px;
    border-radius: 30px;
    background-color: #00d198; }
    .Block--jobs .JobItem-arrow .Arrow {
      width: 25px; }
  .Block--jobs .JobItem:hover, .Block--jobs .JobItem:active {
    border-radius: 60px;
    transform: scale(1.08);
    padding: 30px; }
    .Block--jobs .JobItem:hover .JobItem-object::before, .Block--jobs .JobItem:active .JobItem-object::before {
      opacity: .3; }
    .Block--jobs .JobItem:hover .JobItem-arrow, .Block--jobs .JobItem:active .JobItem-arrow {
      right: 20px; }
    .Block--jobs .JobItem:hover .JobItem-desc, .Block--jobs .JobItem:active .JobItem-desc {
      opacity: 1; }

.Block--moreJobs {
  margin-bottom: -120px;
  background-color: #fad018;
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  padding-top: 50px;
  padding-bottom: 100px; }
  .Block--moreJobs .Slider {
    overflow: inherit; }
  .Block--moreJobs .MoreJobsTitle {
    margin-bottom: 40px;
    color: #343434; }
    @media screen and (max-width: 47.9375em) {
      .Block--moreJobs .MoreJobsTitle {
        font-size: 20px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Block--moreJobs .MoreJobsTitle {
        font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .Block--moreJobs .MoreJobsTitle {
        font-size: 38px; } }
    .Block--moreJobs .MoreJobsTitle-dot {
      color: #fad018; }
  .Block--moreJobs .MoreJobsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px; }
  .Block--moreJobs .MoreJobsItem {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    margin-bottom: 48px !important;
    margin-left: 24px;
    margin-right: 24px;
    transition: ease-in-out .5s;
    padding: 20px;
    text-decoration: none; }
    @media screen and (max-width: 47.9375em) {
      .Block--moreJobs .MoreJobsItem {
        width: 200px; } }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .Block--moreJobs .MoreJobsItem {
        width: calc(200px + 100 * ((100vw - 768px) / 512)); } }
    @media screen and (min-width: 80em) {
      .Block--moreJobs .MoreJobsItem {
        width: 300px; } }
    @media screen and (max-width: 47.9375em) {
      .Block--moreJobs .MoreJobsItem {
        height: 200px; } }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .Block--moreJobs .MoreJobsItem {
        height: calc(200px + 100 * ((100vw - 768px) / 512)); } }
    @media screen and (min-width: 80em) {
      .Block--moreJobs .MoreJobsItem {
        height: 300px; } }
    @media screen and (max-width: 47.9375em) {
      .Block--moreJobs .MoreJobsItem {
        width: 230px;
        height: 230px;
        margin-bottom: 24px !important;
        margin-right: 0;
        margin-left: 0; } }
    .Block--moreJobs .MoreJobsItem-object {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0; }
      .Block--moreJobs .MoreJobsItem-object::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #343434;
        background: linear-gradient(180deg, #343434 0%, rgba(52, 52, 52, 0) 60%, rgba(52, 52, 52, 0) 100%); }
      .Block--moreJobs .MoreJobsItem-object::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0;
        transition: ease-in-out .5s; }
    .Block--moreJobs .MoreJobsItem-image {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .Block--moreJobs .MoreJobsItem-title {
      position: relative;
      z-index: 1;
      color: #ffffff;
      font-family: "IBM Plex Serif", serif;
      font-weight: 600;
      text-decoration: none; }
      @media screen and (max-width: 47.9375em) {
        .Block--moreJobs .MoreJobsItem-title {
          font-size: 16px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .Block--moreJobs .MoreJobsItem-title {
          font-size: calc(16px + 4 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .Block--moreJobs .MoreJobsItem-title {
          font-size: 20px; } }
    .Block--moreJobs .MoreJobsItem-desc {
      position: relative;
      z-index: 1;
      color: #ffffff;
      text-decoration: none;
      max-width: 70%;
      opacity: 0;
      transition: ease-in-out .5s; }
      @media screen and (max-width: 47.9375em) {
        .Block--moreJobs .MoreJobsItem-desc {
          font-size: 14px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .Block--moreJobs .MoreJobsItem-desc {
          font-size: calc(14px + 2 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .Block--moreJobs .MoreJobsItem-desc {
          font-size: 16px; } }
    .Block--moreJobs .MoreJobsItem-arrow {
      position: absolute;
      z-index: 3;
      right: 100%;
      bottom: 20px;
      transition: ease-in-out .5s;
      padding: 5px 25px;
      border-radius: 30px;
      background-color: #00d198; }
      .Block--moreJobs .MoreJobsItem-arrow .Arrow {
        width: 25px; }
    .Block--moreJobs .MoreJobsItem:hover, .Block--moreJobs .MoreJobsItem:active {
      border-radius: 60px;
      transform: scale(1.08);
      padding: 30px; }
      .Block--moreJobs .MoreJobsItem:hover .MoreJobsItem-object::before, .Block--moreJobs .MoreJobsItem:active .MoreJobsItem-object::before {
        opacity: .3; }
      .Block--moreJobs .MoreJobsItem:hover .MoreJobsItem-arrow, .Block--moreJobs .MoreJobsItem:active .MoreJobsItem-arrow {
        right: 20px; }
      .Block--moreJobs .MoreJobsItem:hover .MoreJobsItem-desc, .Block--moreJobs .MoreJobsItem:active .MoreJobsItem-desc {
        opacity: 1; }

.Block--blogs {
  margin-bottom: -120px;
  background-color: #fad018;
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  padding-top: 50px;
  padding-bottom: 100px; }
  .Block--blogs .Blogs {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 47.9375em) {
      .Block--blogs .Blogs {
        flex-direction: column; } }
    .Block--blogs .BlogsItem {
      overflow: hidden;
      border-radius: 60px;
      height: 400px;
      position: relative;
      text-decoration: none;
      padding: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 20%;
      transition: ease-in-out 1s; }
      @media screen and (max-width: 47.9375em) {
        .Block--blogs .BlogsItem {
          width: 100%;
          margin-bottom: 24px; } }
      .Block--blogs .BlogsItem.active {
        width: 55%;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0; }
        @media screen and (max-width: 47.9375em) {
          .Block--blogs .BlogsItem.active {
            width: 100%; } }
        .Block--blogs .BlogsItem.active .BlogsItem-arrow {
          right: 30px; }
        .Block--blogs .BlogsItem.active .BlogsItem-content {
          opacity: 1; }
        .Block--blogs .BlogsItem.active .BlogsItem-object::before {
          opacity: .3; }
        .Block--blogs .BlogsItem.active .BlogsItem-object::after {
          opacity: 1; }
      .Block--blogs .BlogsItem-detail {
        color: #fad018; }
        @media screen and (max-width: 47.9375em) {
          .Block--blogs .BlogsItem-detail {
            font-size: 14px; } }
        @media screen and (min-width: 48em) and (max-width: 63.9375em) {
          .Block--blogs .BlogsItem-detail {
            font-size: calc(14px + 6 * ((100vw - 768px) / 256)); } }
        @media screen and (min-width: 64em) {
          .Block--blogs .BlogsItem-detail {
            font-size: 20px; } }
      .Block--blogs .BlogsItem-title {
        color: #ffffff;
        font-family: "IBM Plex Serif", serif;
        font-weight: 600;
        text-decoration: none;
        margin-bottom: 10px; }
        @media screen and (max-width: 47.9375em) {
          .Block--blogs .BlogsItem-title {
            font-size: 20px; } }
        @media screen and (min-width: 48em) and (max-width: 63.9375em) {
          .Block--blogs .BlogsItem-title {
            font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }
        @media screen and (min-width: 64em) {
          .Block--blogs .BlogsItem-title {
            font-size: 38px; } }
        @media screen and (max-width: 47.9375em) {
          .Block--blogs .BlogsItem-title {
            line-height: 23px; } }
        @media screen and (min-width: 48em) and (max-width: 63.9375em) {
          .Block--blogs .BlogsItem-title {
            line-height: calc(23px + 22 * ((100vw - 768px) / 256)); } }
        @media screen and (min-width: 64em) {
          .Block--blogs .BlogsItem-title {
            line-height: 45px; } }
      .Block--blogs .BlogsItem-object {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0; }
        .Block--blogs .BlogsItem-object::after {
          content: "";
          position: absolute;
          opacity: 0;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #343434;
          background: linear-gradient(0deg, #343434 0%, rgba(52, 52, 52, 0) 60%, rgba(52, 52, 52, 0) 100%); }
        .Block--blogs .BlogsItem-object::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #000000;
          opacity: 0;
          transition: ease-in-out 1s; }
      .Block--blogs .BlogsItem-image {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .Block--blogs .BlogsItem-content {
        z-index: 2;
        opacity: 0;
        transition: ease-in-out 1s;
        position: absolute;
        padding: 40px;
        bottom: 0;
        left: 0;
        width: 550px; }
        @media screen and (min-width: 47.9375em) and (max-width: 63.9375em) {
          .Block--blogs .BlogsItem-content {
            width: 400px; } }
        @media screen and (max-width: 47.9375em) {
          .Block--blogs .BlogsItem-content {
            max-width: 100%; } }
      .Block--blogs .BlogsItem-arrow {
        position: absolute;
        z-index: 3;
        right: 100%;
        bottom: 20px;
        transition: ease-in-out 1s;
        padding: 5px 25px;
        border-radius: 30px;
        background-color: #00d198; }
        .Block--blogs .BlogsItem-arrow .Arrow {
          width: 25px; }
    .Block--blogs .BlogsTitle {
      margin-bottom: 40px;
      color: #343434; }
      @media screen and (max-width: 47.9375em) {
        .Block--blogs .BlogsTitle {
          font-size: 20px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .Block--blogs .BlogsTitle {
          font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .Block--blogs .BlogsTitle {
          font-size: 38px; } }
      .Block--blogs .BlogsTitle-dot {
        color: #ffffff; }

.HomeExpertise {
  position: relative;
  z-index: 1;
  padding: 80px 0px;
  padding-right: 48px; }
  .HomeExpertise::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    width: calc(100% + 24px); }
    @media screen and (min-width: 48em) and (max-width: 82.9375em) {
      .HomeExpertise::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 83em) {
      .HomeExpertise::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  @media screen and (max-width: 48em) {
    .HomeExpertise {
      padding: 48px 0px;
      padding-right: 24px; } }
  .HomeExpertise::before {
    background-color: #fad018;
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px; }
  @media screen and (max-width: 84.3125em) {
    .HomeExpertise-content {
      padding-left: 0px; } }
  @media screen and (min-width: 84.375em) and (max-width: 96.8125em) {
    .HomeExpertise-content {
      padding-left: calc(0px + 100 * ((100vw - 1350px) / 200)); } }
  @media screen and (min-width: 96.875em) {
    .HomeExpertise-content {
      padding-left: 100px; } }
  .HomeExpertise-header {
    text-align: center;
    position: relative;
    padding-bottom: 50px; }
  .HomeExpertise-title {
    margin-bottom: 8px; }
    @media screen and (max-width: 47.9375em) {
      .HomeExpertise-title {
        font-size: 32px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .HomeExpertise-title {
        font-size: calc(32px + 16 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .HomeExpertise-title {
        font-size: 48px; } }
  @media screen and (max-width: 47.9375em) {
    .HomeExpertise-subTitle {
      font-size: 22px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .HomeExpertise-subTitle {
      font-size: calc(22px + 6 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .HomeExpertise-subTitle {
      font-size: 28px; } }
  .HomeExpertise-dot {
    color: #ffffff;
    display: inline-block; }
  .HomeExpertise-text {
    font-family: "IBM Plex Serif", serif; }
    .HomeExpertise-text p:last-of-type {
      margin-bottom: 0; }
  .HomeExpertise-image {
    height: 150px; }
  .HomeExpertise-object {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 40px;
    transform: translateX(-25px); }
  .HomeExpertise-btnContainer {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(10px); }
    @media screen and (max-width: 48em) {
      .HomeExpertise-btnContainer {
        position: relative; } }
  .HomeExpertise .Grid-cell:nth-child(2) .HomeExpertise-image {
    width: 240px;
    object-fit: contain; }

.CaseBanner-title {
  margin-bottom: 8px; }
  @media screen and (max-width: 47.9375em) {
    .CaseBanner-title {
      font-size: 22px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .CaseBanner-title {
      font-size: calc(22px + 6 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .CaseBanner-title {
      font-size: 28px; } }

.CaseBanner-catList {
  margin-bottom: 10px; }

.CaseBanner-imgContainer {
  padding-right: 120px;
  padding-left: 120px; }
  @media screen and (max-width: 48em) {
    .CaseBanner-imgContainer {
      padding-right: 48px;
      padding-left: 48px; } }

.CaseBanner-cat {
  font-size: 16px;
  font-style: italic;
  line-height: 1.56;
  display: inline-block;
  margin-right: 8px; }

.CaseBanner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }

.CaseBanner-text {
  max-width: 500px; }

.Highlight {
  position: relative;
  z-index: 1;
  padding: 140px 24px; }
  .Highlight::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    width: calc(100% + 24px); }
    @media screen and (min-width: 48em) and (max-width: 82.9375em) {
      .Highlight::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 83em) {
      .Highlight::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  @media screen and (max-width: 48em) {
    .Highlight {
      padding: 70px 0px;
      padding-right: 24px; } }
  .Highlight::before {
    background-color: #fad018;
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px; }
  .Highlight-grid {
    display: flex; }
    @media screen and (max-width: 48em) {
      .Highlight-grid {
        flex-direction: column; } }
  .Highlight-imgContainer {
    width: 40%; }
    @media screen and (max-width: 48em) {
      .Highlight-imgContainer {
        width: 100%; } }
  .Highlight-content {
    width: 60%;
    padding-left: 50px;
    max-width: 500px; }
    @media screen and (max-width: 48em) {
      .Highlight-content {
        width: 100%;
        padding-left: 0;
        padding-top: 24px; } }
  .Highlight-title {
    margin-bottom: 8px; }
    @media screen and (max-width: 47.9375em) {
      .Highlight-title {
        font-size: 32px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Highlight-title {
        font-size: calc(32px + 16 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .Highlight-title {
        font-size: 48px; } }
  .Highlight-subTitle {
    font-family: "IBM Plex Serif", serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.43;
    color: #343434; }
  .Highlight-dot {
    color: #ffffff;
    display: inline-block; }
  .Highlight-text {
    padding-top: 24px; }
    .Highlight-text p:last-of-type {
      margin-bottom: 0; }
  .Highlight-btnContainer {
    padding-top: 55px; }

.Testimonials {
  max-width: 1100px; }
  .TestimonialsItem {
    position: relative; }
    @media screen and (max-width: 47.9375em) {
      .TestimonialsItem {
        margin-bottom: 40px; } }
    .TestimonialsItem-button {
      position: absolute;
      bottom: -20px; }
      @media screen and (max-width: 47.9375em) {
        .TestimonialsItem-button {
          right: 30px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .TestimonialsItem-button {
          right: calc(30px + 70 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .TestimonialsItem-button {
          right: 100px; } }
      @media screen and (max-width: 47.9375em) {
        .TestimonialsItem-button {
          left: 0;
          right: 0;
          margin: auto;
          width: fit-content; } }
    .TestimonialsItem-wrapper {
      display: none;
      background-color: #343434;
      border-radius: 60px;
      overflow: hidden; }
      .TestimonialsItem-wrapper.active-item {
        display: flex; }
        @media screen and (max-width: 47.9375em) {
          .TestimonialsItem-wrapper.active-item {
            flex-direction: column; } }
    .TestimonialsItem-avatar {
      border-radius: 60px;
      overflow: hidden;
      min-height: 100%;
      max-width: 320px; }
      @media screen and (max-width: 47.9375em) {
        .TestimonialsItem-avatar {
          max-width: 100%; } }
      .TestimonialsItem-avatar-object, .TestimonialsItem-avatar-image {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .TestimonialsItem-arrow {
      position: absolute;
      right: 40px;
      top: 30px; }
      .TestimonialsItem-arrow .Arrow {
        width: 30px; }
    .TestimonialsItem-content {
      padding: 50px 40px;
      max-width: 60%; }
      @media screen and (max-width: 47.9375em) {
        .TestimonialsItem-content {
          max-width: 100%;
          padding: 30px 20px 50px 20px; } }
      .TestimonialsItem-content .Hashtags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 12px; }
        .TestimonialsItem-content .HashtagsItem {
          margin-right: 12px;
          color: #ffffff;
          opacity: .4; }
          @media screen and (max-width: 47.9375em) {
            .TestimonialsItem-content .HashtagsItem {
              font-size: 12px; } }
          @media screen and (min-width: 48em) and (max-width: 63.9375em) {
            .TestimonialsItem-content .HashtagsItem {
              font-size: calc(12px + 4 * ((100vw - 768px) / 256)); } }
          @media screen and (min-width: 64em) {
            .TestimonialsItem-content .HashtagsItem {
              font-size: 16px; } }
      .TestimonialsItem-content .Text {
        margin-bottom: 12px; }
        .TestimonialsItem-content .Text p {
          color: #ffffff;
          font-family: "IBM Plex Serif", serif;
          margin-bottom: 0; }
          @media screen and (max-width: 47.9375em) {
            .TestimonialsItem-content .Text p {
              font-size: 16px; } }
          @media screen and (min-width: 48em) and (max-width: 63.9375em) {
            .TestimonialsItem-content .Text p {
              font-size: calc(16px + 8 * ((100vw - 768px) / 256)); } }
          @media screen and (min-width: 64em) {
            .TestimonialsItem-content .Text p {
              font-size: 24px; } }
      .TestimonialsItem-content .Author {
        color: #fad018; }
        @media screen and (max-width: 47.9375em) {
          .TestimonialsItem-content .Author {
            font-size: 15px; } }
        @media screen and (min-width: 48em) and (max-width: 63.9375em) {
          .TestimonialsItem-content .Author {
            font-size: calc(15px + 3 * ((100vw - 768px) / 256)); } }
        @media screen and (min-width: 64em) {
          .TestimonialsItem-content .Author {
            font-size: 18px; } }
  .TestimonialsPagination {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .TestimonialsPagination-bullet {
      display: flex;
      width: 15px;
      height: 15px;
      border: 2px solid #fad018;
      border-radius: 100%;
      margin: 0 10px;
      cursor: pointer; }
      .TestimonialsPagination-bullet.active-bullet {
        background-color: #fad018; }

.Workflow-foldout {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  transition: ease-in-out .3s;
  right: -200%; }
  .Workflow-foldout::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    width: calc(100% + 24px); }
    @media screen and (min-width: 48em) and (max-width: 82.9375em) {
      .Workflow-foldout::before {
        width: calc(100% + 40px); } }
    @media screen and (min-width: 83em) {
      .Workflow-foldout::before {
        width: calc((100vw - 1248px) / 2 + 100%); } }
  @media screen and (max-width: 48em) {
    .Workflow-foldout {
      padding: 70px 0px;
      padding-left: 24px; } }
  .Workflow-foldout::before {
    background-color: #fdefb0;
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
    right: auto !important; }
  .Workflow-foldout p {
    margin: 0; }

.Workflow-cta {
  position: relative;
  right: -200%;
  z-index: 1;
  transition: ease-in-out .3s; }

.Workflow-more {
  height: 0; }
  .Workflow-more.visible {
    display: block;
    height: auto;
    transition: ease-in-out .3s; }
    .Workflow-more.visible .Workflow-foldout {
      right: 0;
      padding: 24px 24px;
      margin: 24px 0 8px 0; }
      @media screen and (max-width: 48em) {
        .Workflow-more.visible .Workflow-foldout {
          margin: 24px -20px 8px -20px; } }
    .Workflow-more.visible .Workflow-cta {
      right: 0; }

.Workflow-cta {
  margin: 16px 0;
  font-family: "IBM Plex Serif", serif;
  font-weight: bold; }
  .Workflow-cta .Button {
    margin-left: 8px; }

.Workflow .Grid-reverse {
  flex-direction: row-reverse; }
  @media screen and (max-width: 64em) {
    .Workflow .Grid-reverse {
      flex-direction: column-reverse; } }

@media screen and (max-width: 64em) {
  .Workflow .Grid {
    padding-bottom: 48px;
    flex-direction: column-reverse; } }

.Workflow-stepCount {
  font-family: "IBM Plex Serif", serif;
  background: #00a779;
  font-weight: bold;
  font-size: 16px;
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 100%;
  margin-bottom: 12px; }

.Workflow-title {
  margin-bottom: 8px; }

.Workflow-text {
  max-width: 475px;
  margin-bottom: 8px; }

.Workflow-content {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (max-width: 64em) {
    .Workflow-content {
      padding-bottom: 28px;
      padding-top: 48px; } }
  .Workflow-content--reverse {
    padding-left: 125px; }
    @media screen and (max-width: 64em) {
      .Workflow-content--reverse {
        padding-left: 0; } }

@media screen and (max-width: 47.9375em) {
  .Workflow-image1 {
    width: 500px; } }

@media screen and (min-width: 48em) and (max-width: 84.3125em) {
  .Workflow-image1 {
    width: calc(500px + 425 * ((100vw - 768px) / 582)); } }

@media screen and (min-width: 84.375em) {
  .Workflow-image1 {
    width: 925px; } }

@media screen and (max-width: 47.9375em) {
  .Workflow-image1 {
    height: 300px; } }

@media screen and (min-width: 48em) and (max-width: 84.3125em) {
  .Workflow-image1 {
    height: calc(300px + 100 * ((100vw - 768px) / 582)); } }

@media screen and (min-width: 84.375em) {
  .Workflow-image1 {
    height: 400px; } }

@media screen and (max-width: 64em) {
  .Workflow-image1 {
    display: none; } }

.Workflow-image2 {
  margin-left: 45%;
  margin-top: 24px;
  margin-bottom: 24px; }
  @media screen and (max-width: 47.9375em) {
    .Workflow-image2 {
      width: 300px; } }
  @media screen and (min-width: 48em) and (max-width: 84.3125em) {
    .Workflow-image2 {
      width: calc(300px + 149 * ((100vw - 768px) / 582)); } }
  @media screen and (min-width: 84.375em) {
    .Workflow-image2 {
      width: 449px; } }
  @media screen and (max-width: 64em) {
    .Workflow-image2 {
      display: none; } }

.BannerNew {
  padding-bottom: 40px; }
  .BannerNew-header {
    margin: 0 auto;
    position: relative; }
  .BannerNew-dot {
    color: #fad018;
    display: inline-block; }
  .BannerNew-title {
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    margin-bottom: 10px;
    display: inline-flex;
    position: relative; }
    @media screen and (max-width: 47.9375em) {
      .BannerNew-title {
        font-size: 26px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .BannerNew-title {
        font-size: calc(26px + 2 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .BannerNew-title {
        font-size: 28px; } }
    @media screen and (max-width: 48em) {
      .BannerNew-title {
        width: 100%; } }
  .BannerNew-intro {
    max-width: 850px; }
    .BannerNew-intro p:last-of-type {
      margin-bottom: 0; }
  .BannerNew-icon {
    position: absolute;
    top: -100px;
    right: -220px;
    width: 290px;
    height: 140px; }
    @media screen and (max-width: 48em) {
      .BannerNew-icon {
        right: 0; } }

.Breadcrumbs {
  color: #ffffff;
  padding-bottom: 65px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #343434;
  position: absolute;
  width: 100%;
  z-index: 10;
  padding-top: 15px;
  padding-left: 20px; }
  @media screen and (max-width: 64em) {
    .Breadcrumbs {
      position: relative; } }
  .Breadcrumbs--paddingBn {
    padding-bottom: 0; }
  @media screen and (max-width: 48em) {
    .Breadcrumbs {
      padding-bottom: 30px;
      padding-top: 30px; } }
  .Breadcrumbs-list {
    list-style-type: none;
    display: flex;
    margin-bottom: 0;
    padding-left: 0; }
    @media screen and (max-width: 48em) {
      .Breadcrumbs-list {
        display: block; } }
  .Breadcrumbs-item a {
    color: #343434;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    transition: .4s all;
    text-decoration: none; }
    .Breadcrumbs-item a:hover {
      color: #00d198;
      text-decoration: none; }
  .Breadcrumbs-item--last {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    color: #00d198; }
  .Breadcrumbs-slash {
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
    color: #00d198; }

.Cases-header,
.BlogPosts-header {
  display: flex;
  justify-content: space-between; }

.Cases .Button,
.BlogPosts .Button {
  margin-bottom: 24px; }

.Cta {
  background: #fad018;
  padding: 88px 24px;
  border-top-left-radius: 48px;
  border-bottom-right-radius: 48px;
  text-align: center;
  position: relative; }
  @media screen and (max-width: 48em) {
    .Cta {
      padding: 48px 24px; } }
  @media screen and (max-width: 47.9375em) {
    .Cta-title {
      font-size: 18px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Cta-title {
      font-size: calc(18px + 2 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .Cta-title {
      font-size: 20px; } }
  .Cta-text {
    margin: 0 auto;
    max-width: 475px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.56;
    color: #343434;
    text-align: center; }
  .Cta-link {
    position: relative;
    z-index: 5; }
  .Cta::after {
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    animation: 0;
    background-color: #fdefb0;
    width: 100%;
    height: 100%;
    border-top-left-radius: 48px;
    border-bottom-right-radius: 48px;
    content: ''; }
  .Cta:hover::after {
    animation: callToAction 800ms linear 1;
    transform: rotate(1.82deg); }

.MediaBlock {
  text-align: center; }
  .MediaBlock-title {
    margin-bottom: 8px;
    max-width: 650px;
    margin: 0 auto; }
    @media screen and (max-width: 47.9375em) {
      .MediaBlock-title {
        font-size: 26px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .MediaBlock-title {
        font-size: calc(26px + 6 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .MediaBlock-title {
        font-size: 32px; } }
  .MediaBlock-text {
    max-width: 650px;
    margin: 0 auto; }
    .MediaBlock-text p:last-of-type {
      margin-bottom: 0; }
  .MediaBlock-media {
    padding-top: 48px; }

.Steps {
  text-align: center; }
  .Steps-gridContainer {
    justify-content: center; }
  .Steps-item {
    display: flex;
    flex-direction: column; }
  .Steps-aboveText {
    font-size: 18px;
    display: inline-block;
    min-height: 50px; }
  .Steps-step {
    display: inline-block;
    background: #343434;
    font-family: "IBM Plex Serif", serif;
    font-size: 18px;
    color: #ffffff;
    padding: 12px 27px;
    font-weight: bold; }
  .Steps-imgContainer {
    margin-bottom: 50px; }
  .Steps-stepContainer {
    margin-bottom: 30px; }
  .Steps-itemTitle {
    font-weight: bold;
    font-family: "IBM Plex Serif", serif;
    font-size: 18px;
    line-height: 23px;
    background-image: url("/assets/img/title-background.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top 11px right 0px; }
  .Steps-title {
    margin-bottom: 8px;
    max-width: 650px;
    margin: 0 auto; }
    @media screen and (max-width: 47.9375em) {
      .Steps-title {
        font-size: 26px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Steps-title {
        font-size: calc(26px + 6 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .Steps-title {
        font-size: 32px; } }
  .Steps-text {
    max-width: 650px;
    margin: 0 auto; }
    .Steps-text p:last-of-type {
      margin-bottom: 0; }
  .Steps-container {
    padding-top: 75px; }
  .Steps-image {
    max-height: 150px; }

.ProcessTitle {
  margin-bottom: 80px; }
  @media screen and (max-width: 47.9375em) {
    .ProcessTitle {
      font-size: 20px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .ProcessTitle {
      font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }
  @media screen and (min-width: 64em) {
    .ProcessTitle {
      font-size: 38px; } }
  @media screen and (max-width: 47.9375em) {
    .ProcessTitle {
      margin-bottom: 40px; } }

.ProcessMap {
  position: relative;
  max-width: 1100px; }

.ProcessSteps-item {
  margin-bottom: 10px;
  background-color: #343434;
  border-bottom-left-radius: 60px;
  border-top-right-radius: 60px;
  padding: 40px;
  position: relative;
  cursor: pointer; }
  .ProcessSteps-item:last-of-type {
    margin-bottom: 0; }
  .ProcessSteps-item-icon {
    position: absolute;
    right: 40px;
    bottom: 0;
    top: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 50px;
    width: auto; }
  .ProcessSteps-item-title {
    color: #ffffff;
    margin-bottom: 0 !important; }
    @media screen and (max-width: 47.9375em) {
      .ProcessSteps-item-title {
        font-size: 18px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .ProcessSteps-item-title {
        font-size: calc(18px + 14 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .ProcessSteps-item-title {
        font-size: 32px; } }

.ProcessModal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ProcessModal-close {
    position: absolute;
    right: 50px;
    top: 50px;
    padding: 0 !important; }
  .ProcessModal-wrapper {
    background-color: #343434;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    padding: 30px 60px 150px 60px;
    position: relative; }
  .ProcessModal-content {
    max-width: 640px; }
    .ProcessModal-content-title {
      color: #fad018;
      margin-bottom: 40px; }
      @media screen and (max-width: 47.9375em) {
        .ProcessModal-content-title {
          font-size: 20px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .ProcessModal-content-title {
          font-size: calc(20px + 18 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .ProcessModal-content-title {
          font-size: 38px; } }
    .ProcessModal-content-text p {
      color: #ffffff; }
      @media screen and (max-width: 47.9375em) {
        .ProcessModal-content-text p {
          font-size: 14px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .ProcessModal-content-text p {
          font-size: calc(14px + 4 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .ProcessModal-content-text p {
          font-size: 18px; } }
    .ProcessModal-content-icon {
      position: absolute;
      right: 50px;
      bottom: 50px;
      height: 110px; }
      @media screen and (max-width: 47.9375em) {
        .ProcessModal-content-icon {
          height: 70px; } }

.ProcessPoint {
  width: 25px;
  height: 25px;
  background-color: #fad018;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  position: absolute;
  cursor: pointer; }
  .ProcessPoint:nth-of-type(1) {
    left: 100px;
    top: 2px; }
  .ProcessPoint:nth-of-type(2) {
    left: 46%;
    top: 2px; }
  .ProcessPoint:nth-of-type(3) {
    right: 100px;
    top: 2px; }
  .ProcessPoint:nth-of-type(4) {
    left: 28%;
    bottom: 5px; }
  .ProcessPoint:nth-of-type(5) {
    right: 31%;
    bottom: 5px; }

.ImageText {
  display: flex;
  flex-direction: column;
  gap: 2rem; }
  @media screen and (min-width: 48em) {
    .ImageText {
      flex-direction: row;
      gap: 4rem;
      align-items: center; } }
  @media screen and (min-width: 64em) {
    .ImageText {
      gap: 6rem; } }
  @media screen and (min-width: 48em) {
    .ImageText--inverted {
      flex-direction: row-reverse; } }

.ImageText-col {
  flex-basis: 100%; }
  @media screen and (min-width: 48em) {
    .ImageText-col {
      flex: 0 1 50%; } }

.ImageText-image {
  border-top-right-radius: 92.5px;
  border-bottom-left-radius: 92.5px;
  overflow: hidden;
  width: 100%; }
  .ImageText-image picture, .ImageText-image img {
    width: 100%; }

@media screen and (min-width: 48em) {
  .CasesDetailPage .Grid-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);
    margin-top: -72px;
    margin-left: -24px; }
    .CasesDetailPage .Grid-items + .LookingBack {
      margin-top: 72px; }
  .CasesDetailPage .Grid-item {
    margin-top: 72px;
    padding-left: 24px;
    width: 100%; }
    .CasesDetailPage .Grid-item--text:not(:last-child) {
      max-width: 529px; }
    .CasesDetailPage .Grid-item--center {
      align-self: center; }
    .CasesDetailPage .Grid-item--media {
      max-width: 636px; } }
    @media screen and (min-width: 48em) and (min-width: 48em) {
      .CasesDetailPage .Grid-item--media .Slider-items {
        margin-left: -24px; }
      .CasesDetailPage .Grid-item--media .Slider-item {
        flex-shrink: 1;
        margin-left: 24px;
        width: 100%; } }

@media screen and (min-width: 48em) {
    .CasesDetailPage .Grid-item:nth-child(1) {
      grid-column: 1/2;
      grid-row: 1/2;
      justify-self: start; }
    .CasesDetailPage .Grid-item:nth-child(2) {
      grid-column: 2/3;
      grid-row: 2/3;
      justify-self: end; }
    .CasesDetailPage .Grid-item:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2/3;
      justify-self: start; }
    .CasesDetailPage .Grid-item:nth-child(4) {
      grid-column: 1/2;
      grid-row: 3/5;
      justify-self: start; }
    .CasesDetailPage .Grid-item:nth-child(5) {
      grid-column: 2/3;
      grid-row: 3/4;
      justify-self: end; }
    .CasesDetailPage .Grid-item:nth-child(6) {
      grid-column: 2/3;
      grid-row: 4/5;
      justify-self: end; } }

@media screen and (max-width: 47.9375em) {
  .CasesDetailPage .Grid-item + .Grid-item {
    margin-top: 32px; }
  .CasesDetailPage .Grid-item--text + .Grid-item--text {
    margin-top: 24px; } }

/* Blog */
.BlogDetailPage .Container + .Container {
  margin-top: 36px; }
  @media screen and (max-width: 47.9375em) {
    .BlogDetailPage .Container + .Container {
      margin-top: 32px; } }

.Content-item .Slider:not(:only-child) {
  margin-bottom: 24px; }

@media screen and (max-width: 47.9375em) {
  .Content-item .Slider-item {
    max-width: 85%; } }

@media screen and (min-width: 48em) {
  .Content-item .Slider-items {
    margin-left: -48px;
    width: auto; }
  .Content-item .Slider-item {
    flex-shrink: 1;
    margin-left: 48px; } }

@media screen and (min-width: 48em) {
  .Content-item {
    clear: both;
    display: flex;
    margin-left: -48px; }
    .BlogDetailPage.is-advanced .Content-item {
      margin-left: -24px; }
    .Container:nth-child(odd) .Content-item:not(.is-full) {
      flex-direction: row-reverse; }
    .Content-item > .Content-objects,
    .Content-item > .Content-body {
      padding-left: 48px; }
      .BlogDetailPage.is-advanced .Content-item > .Content-objects, .BlogDetailPage.is-advanced
      .Content-item > .Content-body {
        padding-left: 24px; } }

.Content-objects {
  display: block; }
  @media screen and (max-width: 47.9375em) {
    .Content-objects {
      margin-bottom: 32px; } }
  @media screen and (min-width: 48em) {
    .Content-objects {
      flex-basis: 50%;
      flex-shrink: 0; }
      .BlogDetailPage.is-advanced .Content-objects.is-odd {
        flex-basis: 40.46474%;
        margin-left: auto; }
      .BlogDetailPage.is-advanced .Content-objects.is-even {
        flex-basis: 32.05128%; } }

.Content-object {
  display: block;
  overflow: hidden; }
  .BlogDetailPage.is-advanced .Content-object {
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px; }
  @media screen and (min-width: 48em) {
    .Content-object {
      position: relative;
      height: 100%; }
      .BlogDetailPage.is-advanced .Content-objects.is-odd .Content-object {
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px; } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 82.9375em) {
      .BlogDetailPage.is-advanced .Content-objects.is-odd .Content-object {
        margin-right: -40px; } }
    @media screen and (min-width: 48em) and (min-width: 83em) {
      .BlogDetailPage.is-advanced .Content-objects.is-odd .Content-object {
        margin-right: calc((100vw - 1248px) / -2); } }
  @media screen and (min-width: 48em) {
      .BlogDetailPage.is-advanced .Content-objects.is-even .Content-object {
        border-top-right-radius: 48px;
        border-bottom-right-radius: 48px; } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 82.9375em) {
      .BlogDetailPage.is-advanced .Content-objects.is-even .Content-object {
        margin-left: -40px; } }
    @media screen and (min-width: 48em) and (min-width: 83em) {
      .BlogDetailPage.is-advanced .Content-objects.is-even .Content-object {
        margin-left: calc((100vw - 1248px) / -2); } }

.Content-image {
  width: 100%; }
  @media screen and (min-width: 48em) {
    .Content-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      height: 100%; } }

.Container:nth-child(odd) .Content-item:not(.is-full) .Content-body {
  max-width: 616px; }

.Content-body :last-child {
  margin-bottom: 0; }

.Media {
  margin-bottom: 24px; }
  .Media:not(.Media--right):not(.Media--left) > .Picture {
    display: block; }
  @media screen and (max-width: 47.9375em) {
    .Media {
      margin-bottom: 16px; } }
  @media screen and (min-width: 48em) {
    .Media--right, .Media--left {
      max-width: 506px; }
    .Media--json, .Media--video {
      width: 100%; }
    .Media--right {
      margin-left: 40px;
      float: right; }
    .Media--left {
      margin-right: 40px;
      float: left; } }

@media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
  .Media--right, .Media--left {
    max-width: 250px; } }

.Author-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.32;
  color: #343434;
  margin-bottom: 2px; }
  @media screen and (max-width: 47.9375em) {
    .Author-title {
      font-size: 14px; } }

.Author-body {
  display: flex;
  flex-direction: column; }

.Author-object {
  height: 48px;
  width: 48px;
  flex-shrink: 0;
  margin-right: 8px; }
  @media screen and (min-width: 48em) {
    .Author-object {
      margin-right: 8px; } }
  .Author-object--small {
    height: 37px;
    width: 37px; }
    @media screen and (max-width: 47.9375em) {
      .Author-object--small {
        height: 37px;
        width: 37px; } }

.JobsContent {
  position: relative; }
  .JobsContent-mainTitle {
    color: #fad018;
    margin-bottom: -10px; }
    @media screen and (max-width: 47.9375em) {
      .JobsContent-mainTitle {
        font-size: 20px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .JobsContent-mainTitle {
        font-size: calc(20px + 12 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .JobsContent-mainTitle {
        font-size: 32px; } }
  .JobsContent-subTitle {
    color: #ffffff;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    line-height: initial; }
    @media screen and (max-width: 47.9375em) {
      .JobsContent-subTitle {
        font-size: 32px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .JobsContent-subTitle {
        font-size: calc(32px + 20 * ((100vw - 768px) / 256)); } }
    @media screen and (min-width: 64em) {
      .JobsContent-subTitle {
        font-size: 52px; } }
  .JobsContent-down {
    position: absolute;
    bottom: 50px;
    right: 50px; }
    @media screen and (max-width: 47.9375em) {
      .JobsContent-down {
        right: 20px;
        bottom: 20px; } }
    .JobsContent-down .Arrow {
      width: 40px; }
  .JobsContent::before {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: #fad018;
    width: 100%;
    max-width: 800px;
    height: 80%;
    left: 150px;
    top: 100px;
    border-bottom-left-radius: 60px;
    border-top-right-radius: 60px; }
  .JobsContent-contact {
    background-color: #343434;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    overflow: hidden; }
    .JobsContent-contact .ContactTitle {
      color: #fad018;
      font-weight: 400; }
      @media screen and (max-width: 47.9375em) {
        .JobsContent-contact .ContactTitle {
          font-size: 14px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .JobsContent-contact .ContactTitle {
          font-size: calc(14px + 7 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .JobsContent-contact .ContactTitle {
          font-size: 21px; } }
    .JobsContent-contact .ContactName {
      color: #ffffff;
      margin-bottom: 0;
      margin-top: -5px;
      margin-bottom: -5px; }
      @media screen and (max-width: 47.9375em) {
        .JobsContent-contact .ContactName {
          font-size: 18px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .JobsContent-contact .ContactName {
          font-size: calc(18px + 8 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .JobsContent-contact .ContactName {
          font-size: 26px; } }
    .JobsContent-contact .ContactFunction {
      color: #ffffff;
      font-weight: 200; }
      @media screen and (max-width: 47.9375em) {
        .JobsContent-contact .ContactFunction {
          font-size: 14px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .JobsContent-contact .ContactFunction {
          font-size: calc(14px + 7 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .JobsContent-contact .ContactFunction {
          font-size: 21px; } }
    .JobsContent-contact .ContactItems {
      margin-top: 30px;
      display: flex;
      flex-direction: column; }
      .JobsContent-contact .ContactItems-href {
        color: #ffffff;
        text-decoration: none; }
        @media screen and (max-width: 47.9375em) {
          .JobsContent-contact .ContactItems-href {
            font-size: 16px; } }
        @media screen and (min-width: 48em) and (max-width: 63.9375em) {
          .JobsContent-contact .ContactItems-href {
            font-size: calc(16px + 2 * ((100vw - 768px) / 256)); } }
        @media screen and (min-width: 64em) {
          .JobsContent-contact .ContactItems-href {
            font-size: 18px; } }
        .JobsContent-contact .ContactItems-href:hover, .JobsContent-contact .ContactItems-href:active, .JobsContent-contact .ContactItems-href:focus {
          color: #fad018; }
    .JobsContent-contact-object {
      width: 80%;
      border-bottom-right-radius: 60px;
      overflow: hidden; }
    .JobsContent-contact-content {
      padding: 30px 50px; }
      @media screen and (max-width: 47.9375em) {
        .JobsContent-contact-content {
          padding: 50px 30px; } }
  .JobsContent-item {
    margin-bottom: 40px; }
    .JobsContent-item:last-of-type {
      margin-bottom: 0; }
    .JobsContent-item--image {
      overflow: hidden;
      border-radius: 60px; }
    .JobsContent-item--video {
      overflow: hidden;
      border-radius: 60px; }
      .JobsContent-item--video .video-js {
        width: 100% !important;
        height: 300px !important; }
        .JobsContent-item--video .video-js .vjs-big-play-button {
          position: absolute !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          top: 0 !important;
          margin: auto !important;
          border: none !important;
          background-color: #fad018;
          border-radius: 50% !important;
          width: 60px !important;
          height: 60px !important; }
          .JobsContent-item--video .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
            content: "" !important;
            background-image: url("/assets/img/playbutton.png") !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 15px !important; }
      .JobsContent-item--video .vjs-has-started .vjs-control-bar {
        padding: 0 60px !important; }
        @media screen and (max-width: 47.9375em) {
          .JobsContent-item--video .vjs-has-started .vjs-control-bar {
            padding: 0px 30px !important; } }
    .JobsContent-item--content {
      color: #ffffff; }
      .JobsContent-item--content li {
        margin-top: 10px !important; }
      .JobsContent-item--content h1,
      .JobsContent-item--content h2,
      .JobsContent-item--content h3,
      .JobsContent-item--content h4,
      .JobsContent-item--content h5,
      .JobsContent-item--content h6 {
        color: #fad018;
        font-family: "Barlow", sans-serif;
        font-weight: 500;
        max-width: 90%; }
  .JobsContent-tab {
    display: none;
    position: relative; }
    .JobsContent-tab.active {
      display: flex;
      flex-direction: column;
      padding: 40px 80px;
      border-radius: 60px;
      background-color: #343434; }
      @media screen and (max-width: 47.9375em) {
        .JobsContent-tab.active {
          padding: 50px 30px; } }
  .JobsContent-tabs {
    background-color: #343434;
    padding: 30px 50px;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 47.9375em) {
      .JobsContent-tabs {
        padding: 20px 0px;
        margin-bottom: -40px !important;
        background-color: transparent;
        flex-direction: row;
        margin-bottom: 0;
        flex-wrap: wrap; } }
    .JobsContent-tabs-item {
      font-family: "IBM Plex Serif", serif;
      color: #ffffff;
      font-weight: 600;
      margin-bottom: 0px;
      text-decoration: none;
      display: flex;
      align-items: center; }
      @media screen and (max-width: 47.9375em) {
        .JobsContent-tabs-item {
          font-size: 20px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .JobsContent-tabs-item {
          font-size: calc(20px + 12 * ((100vw - 768px) / 256)); } }
      @media screen and (min-width: 64em) {
        .JobsContent-tabs-item {
          font-size: 32px; } }
      @media screen and (max-width: 47.9375em) {
        .JobsContent-tabs-item {
          background-color: #343434;
          border-radius: 30px;
          padding: 10px 20px;
          margin: 0 5px 0 0 !important;
          font-size: 15px; } }
      .JobsContent-tabs-item::before {
        content: "•";
        margin-right: 5px;
        display: none; }
      .JobsContent-tabs-item.active, .JobsContent-tabs-item:hover, .JobsContent-tabs-item:active, .JobsContent-tabs-item:focus {
        color: #fad018; }
        .JobsContent-tabs-item.active::before, .JobsContent-tabs-item:hover::before, .JobsContent-tabs-item:active::before, .JobsContent-tabs-item:focus::before {
          display: flex; }
      .JobsContent-tabs-item:last-of-type {
        margin-bottom: 0; }

/* Core */
body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0s; }
  body[data-aos-delay='50'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='50'].is-animated {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0s; }
  body[data-aos-delay='100'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='100'].is-animated {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0s; }
  body[data-aos-delay='150'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='150'].is-animated {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0s; }
  body[data-aos-delay='200'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='200'].is-animated {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0s; }
  body[data-aos-delay='250'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='250'].is-animated {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0s; }
  body[data-aos-delay='300'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='300'].is-animated {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0s; }
  body[data-aos-delay='350'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='350'].is-animated {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0s; }
  body[data-aos-delay='400'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='400'].is-animated {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0s; }
  body[data-aos-delay='450'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='450'].is-animated {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0s; }
  body[data-aos-delay='500'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='500'].is-animated {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0s; }
  body[data-aos-delay='550'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='550'].is-animated {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0s; }
  body[data-aos-delay='600'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='600'].is-animated {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0s; }
  body[data-aos-delay='650'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='650'].is-animated {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0s; }
  body[data-aos-delay='700'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='700'].is-animated {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0s; }
  body[data-aos-delay='750'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='750'].is-animated {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0s; }
  body[data-aos-delay='800'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='800'].is-animated {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0s; }
  body[data-aos-delay='850'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='850'].is-animated {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0s; }
  body[data-aos-delay='900'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='900'].is-animated {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0s; }
  body[data-aos-delay='950'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='950'].is-animated {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0s; }
  body[data-aos-delay='1000'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1000'].is-animated {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0s; }
  body[data-aos-delay='1050'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1050'].is-animated {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0s; }
  body[data-aos-delay='1100'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1100'].is-animated {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0s; }
  body[data-aos-delay='1150'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1150'].is-animated {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0s; }
  body[data-aos-delay='1200'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1200'].is-animated {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0s; }
  body[data-aos-delay='1250'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1250'].is-animated {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0s; }
  body[data-aos-delay='1300'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1300'].is-animated {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0s; }
  body[data-aos-delay='1350'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1350'].is-animated {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0s; }
  body[data-aos-delay='1400'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1400'].is-animated {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0s; }
  body[data-aos-delay='1450'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1450'].is-animated {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0s; }
  body[data-aos-delay='1500'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1500'].is-animated {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0s; }
  body[data-aos-delay='1550'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1550'].is-animated {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0s; }
  body[data-aos-delay='1600'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1600'].is-animated {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0s; }
  body[data-aos-delay='1650'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1650'].is-animated {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0s; }
  body[data-aos-delay='1700'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1700'].is-animated {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0s; }
  body[data-aos-delay='1750'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1750'].is-animated {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0s; }
  body[data-aos-delay='1800'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1800'].is-animated {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0s; }
  body[data-aos-delay='1850'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1850'].is-animated {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0s; }
  body[data-aos-delay='1900'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1900'].is-animated {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0s; }
  body[data-aos-delay='1950'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='1950'].is-animated {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0s; }
  body[data-aos-delay='2000'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2000'].is-animated {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0s; }
  body[data-aos-delay='2050'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2050'].is-animated {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0s; }
  body[data-aos-delay='2100'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2100'].is-animated {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0s; }
  body[data-aos-delay='2150'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2150'].is-animated {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0s; }
  body[data-aos-delay='2200'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2200'].is-animated {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0s; }
  body[data-aos-delay='2250'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2250'].is-animated {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0s; }
  body[data-aos-delay='2300'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2300'].is-animated {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0s; }
  body[data-aos-delay='2350'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2350'].is-animated {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0s; }
  body[data-aos-delay='2400'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2400'].is-animated {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0s; }
  body[data-aos-delay='2450'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2450'].is-animated {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0s; }
  body[data-aos-delay='2500'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2500'].is-animated {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0s; }
  body[data-aos-delay='2550'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2550'].is-animated {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0s; }
  body[data-aos-delay='2600'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2600'].is-animated {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0s; }
  body[data-aos-delay='2650'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2650'].is-animated {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0s; }
  body[data-aos-delay='2700'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2700'].is-animated {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0s; }
  body[data-aos-delay='2750'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2750'].is-animated {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0s; }
  body[data-aos-delay='2800'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2800'].is-animated {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0s; }
  body[data-aos-delay='2850'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2850'].is-animated {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0s; }
  body[data-aos-delay='2900'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2900'].is-animated {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0s; }
  body[data-aos-delay='2950'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='2950'].is-animated {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0s; }
  body[data-aos-delay='3000'] [data-aos].is-animated, [data-aos][data-aos][data-aos-delay='3000'].is-animated {
    transition-delay: 3000ms; }

/* Easing */
body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/* Animations */
@media screen {
  html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
    opacity: 0;
    transition-property: opacity, transform; }
    html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].is-animated {
      opacity: 1;
      transform: none; }
  html:not(.no-js) [data-aos='fade-up'] {
    transform: translate3d(0, 32px, 0); }
  html:not(.no-js) [data-aos='fade-down'] {
    transform: translate3d(0, -32px, 0); }
  html:not(.no-js) [data-aos='fade-right'] {
    transform: translate3d(-32px, 0, 0); }
  html:not(.no-js) [data-aos='fade-left'] {
    transform: translate3d(32px, 0, 0); }
  html:not(.no-js) [data-aos='fade-up-right'] {
    transform: translate3d(-32px, 32px, 0); }
  html:not(.no-js) [data-aos='fade-up-left'] {
    transform: translate3d(32px, 32px, 0); }
  html:not(.no-js) [data-aos='fade-down-right'] {
    transform: translate3d(-32px, -32px, 0); }
  html:not(.no-js) [data-aos='fade-down-left'] {
    transform: translate3d(32px, -32px, 0); } }

/* Footer */
.Footer {
  position: relative;
  z-index: 3;
  margin-top: 48px; }
  @media screen and (min-width: 48em) {
    .Footer {
      margin-top: 72px; } }
  .Footer-items {
    background-color: #343434;
    color: #ffffff;
    padding-top: 24px;
    padding-bottom: 8px; }
    @media screen and (min-width: 48em) {
      .Footer-items {
        padding-top: 48px;
        padding-bottom: 48px; } }
    @media screen and (max-width: 47.9375em) {
      .Footer-items {
        font-size: 14px; } }
    @media screen and (min-width: 48em) {
      .Footer-items > .Container {
        display: flex;
        flex-wrap: wrap; }
        @supports (display: grid) {
          .Footer-items > .Container {
            display: grid;
            grid-template-columns: repeat(4, auto);
            grid-template-rows: repeat(2, auto); } } }
    @media screen and (min-width: 64em) {
      .Footer-items > .Container {
        justify-content: space-between; }
        .Footer-items > .Container > .Footer-item:last-child {
          flex: 1 1 100%; } }
    @media screen and (max-width: 47.9375em) {
      .Footer-items > .Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center; } }
  .Footer a:not([class]), .Footer a:not([class]):active, .Footer a:not([class]):focus, .Footer a:not([class]):hover {
    color: currentColor;
    text-decoration: none; }
  .Footer a:not([class]):hover {
    text-decoration: underline; }
  .Footer-items, .Footer::before {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px; }
  .Footer::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 80%;
    left: 0;
    z-index: -1;
    background-color: #f5f5f5;
    content: '';
    transform: rotate(-0.9deg); }
  .Footer-item :last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 48em) {
    .Footer-item:nth-child(1) {
      grid-column: 1/2;
      grid-row: 1/2;
      max-width: 351px; }
    .Footer-item:nth-child(2) {
      grid-column: 2/3;
      grid-row: 1/3; }
    .Footer-item:nth-child(3) {
      grid-column: 3/4;
      grid-row: 1/3; }
    .Footer-item:nth-child(4) {
      grid-column: 4/5;
      grid-row: 1/3; }
    .Footer-item:nth-child(5) {
      grid-column: 5/6;
      grid-row: 1/3; }
    .Footer-item:nth-child(6) {
      grid-column: 1/2;
      grid-row: 2/3; } }
  @media screen and (max-width: 47.9375em) {
    .Footer-item br:first-of-type,
    .Footer-item p:not(:first-of-type) {
      display: none; } }

.JobsPage .Footer::before {
  display: none; }

.JobsDetailPage .Footer::before {
  display: none; }

/* DigitalPulse */
.DigitalPulse {
  color: currentColor;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em; }
  .DigitalPulse-icon, .DigitalPulse-body {
    display: block;
    fill: currentColor; }
  .DigitalPulse-label {
    margin-right: .3em;
    flex-shrink: 0; }
  .DigitalPulse-object {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .DigitalPulse-icon {
    flex-shrink: 0;
    height: 1.85743em;
    transform: translateY(5%);
    width: 1.5em; }
  @media screen and (min-width: 64em) {
    .DigitalPulse-object {
      transition-property: transform, right;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      min-width: 5.56167em;
      position: relative;
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(1.01) translateY(-50%); }
    .DigitalPulse:hover .DigitalPulse-object {
      color: currentColor;
      right: 100%; }
    .DigitalPulse-body {
      transition-property: width;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      margin-left: .3em;
      transform: translateY(12.5%);
      display: flex;
      justify-content: flex-end;
      height: 0.94828em;
      width: 5.56167em; } }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #bababa;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 12pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 12pt;
    text-transform: none; }
  h1 {
    font-size: 24pt; }
  h2 {
    font-size: 18pt; }
  h3 {
    font-size: 14pt; } }

.cookie-though {
  --ct-primary-400: #fcb610 !important;
  --ct-primary-300: #000 !important;
  --ct-primary-200: #fdefb0;
  --ct-primary-100: #F7F7F7;
  --ct-text: #6b7280;
  --ct-subtitle: #000;
  --ct-title: #000;
  --ct-cookie: var(--ct-primary-400);
  --ct-policy: #fff;
  --ct-policy-enabled: #fdefb0;
  --ct-policy-text: #000;
  --ct-policy-text-enabled: #000;
  --ct-policy-essential: var(--ct-primary-200);
  --ct-slider-enabled-primary: #fad018;
  --ct-slider-enabled-secondary: #fcb610;
  --ct-primary-font: "Barlow", sans-serif;
  --ct-secondary-font: "Barlow", sans-serif; }
