.Related {
    color: currentColor;
    display: flex;
    flex-direction: column-reverse;
    min-width: u(272);
    text-decoration: none;

    &-title {
        margin-bottom: 0;
        margin-top: u($spacing-unit--sm);
    }

    &-object {
        padding-bottom: 100%;

        @include mq($from: $viewport--md) {
            padding-bottom: (100% / 306 * 139);

            .Container--sm & {
                padding-bottom: (100% / 4 * 3);
            }
        }

        &:not(:empty) {
            @include position(relative, $z: 1);
        }
    }

    &-image {
        @include transition(transform);
        @include position(absolute, 0, 0, 0, 0);
        height: 100%;
        object-fit: cover;
    }

    &:hover {

        .Related-image {
            transform: scale(1.1);
        }
    }
}

.Slider--related {

    @include mq($from: $viewport--md) {

        .Slider-items {
            margin-left: u($spacing-unit--sm * -1);
            width: auto;

            .Container--sm & {
                margin-left: u($spacing-unit--xl * -1);
            }
        }

        .Slider-item {
            padding-left: u($spacing-unit--sm);
            width: 50%;

            .Container--sm & {
                padding-left: u($spacing-unit--xl);
            }
        }
    }

    @include mq($until: $viewport--md - 1) {

        .Slider-item {
            flex-basis: 50%;
            flex-shrink: 1;
        }
    }
}
