$gridItems: (
    1: ( gcs: 1, gce: 2, grs: 1, gre: 2),
    2: ( gcs: 2, gce: 3, grs: 2, gre: 3),
    3: ( gcs: 1, gce: 2, grs: 2, gre: 3),
    4: ( gcs: 1, gce: 2, grs: 3, gre: 5),
    5: ( gcs: 2, gce: 3, grs: 3, gre: 4),
    6: ( gcs: 2, gce: 3, grs: 4, gre: 5)
);

.CasesDetailPage {

    .Grid {

        @include mq($from: $viewport--md) {

            &-items {
                @include grid(1fr 1fr, repeat(4, auto));
                margin-top: u($spacing-unit * -3);
                margin-left: u($spacing-unit * -1);

                + .LookingBack {
                    margin-top: u($spacing-unit * 3);
                }
            }

            &-item {
                margin-top: u($spacing-unit * 3);
                padding-left: u($spacing-unit);
                width: 100%;

                &--text:not(:last-child) {
                    max-width: u(505 + $spacing-unit);
                }

                &--center {
                    align-self: center;
                }

                &--media {
                    max-width: u(612 + $spacing-unit);

                    @include mq($from: $viewport--md) {

                        .Slider-items {
                            margin-left: u($spacing-unit * -1);
                        }

                        .Slider-item {
                            flex-shrink: 1;
                            margin-left: u($spacing-unit);
                            width: 100%;
                        }
                    }
                }

                @each $i, $area in $gridItems {

                    &:nth-child(#{$i}) {
                        @include grid-area(
                            #{map-deep-get($gridItems, $i, gcs)},
                            #{map-deep-get($gridItems, $i, gce)},
                            #{map-deep-get($gridItems, $i, grs)},
                            #{map-deep-get($gridItems, $i, gre)}
                        );

                        @if(map-deep-get($gridItems, $i, gcs) > 1) {
                            justify-self: end;
                        } @else {
                            justify-self: start;
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--md - 1) {

            &-item {

                + .Grid-item {
                    margin-top: u($spacing-unit--lg);
                }

                &--text {

                    + .Grid-item--text {
                        margin-top: u($spacing-unit);
                    }
                }
            }
        }
    }
}
