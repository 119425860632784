/* Team */
.Members {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit * -1);
    margin-left: u($spacing-unit * -1);
}

.Member {
    @include grid(1fr, 1fr);
    padding-top: u($spacing-unit);
    padding-left: u($spacing-unit);
    width: (100% / 2);

    &-object {
        padding-bottom: 100%;
        position: relative;
    }

    &-image {
        @include position(absolute, 0, 0, 0, 0);
    }

    @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
        width: (100% / 3);
    }

    @include mq($from: $viewport--lg) {
        width: (100% / 4);
    }
    &.u-shape::after {
        @include transition(transform);
        animation: 0;
        border-radius: u($spacing-unit--xs);
        top: u($spacing-unit);
        left: u($spacing-unit);
    }

    &:hover {

        &.u-shape::after {
            animation: button 800ms linear 1;
            transform: rotate(5.14deg);
        }
    }

    &.is-jobs {
        color: currentColor;
        text-decoration: none;
    }

    &:not(.is-jobs) {

        &:not(:hover) {

            .Member-body {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &-object {
        @include grid-area(1, 2, 1, 2);
    }

    &-body {
        @include position(relative, $z: 1);
        @include grid-area(1, 2, 1, 2);
        @include grid(1fr, 1fr auto 1fr);
        @include transition(#{opacity, visibility});
        background-color: rgba($grey--600, 0.5);
        border-radius: u($spacing-unit--xs);
        color: $white;
        text-align: center;
        padding: u($spacing-unit--xs);

        @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
            padding: u($spacing-unit--sm);
        }

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit);
        }
    }

    &-title {
        @include grid-area(1, 2, 2, 3);
        color: $yellow--300;
    }

    &-function {
        @include font-size(14, false);
        @include grid-area(1, 2, 3, 4);
        align-self: end;
        margin-top: u($spacing-unit--sm);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit);
        }
    }
}

.Member-large{
    width: calc(100% / 2);
    pointer-events: none;

    &::after{
        display: none;
    }

    .Members-object{
        // max-height: calc(100% - 10px);
    }

    .Members-image{
        border-radius: 0px 48px;
        // width: 100%;
        // object-fit: cover;
        // max-height: calc(100% - 10px);
    }


    @include mq($until: $viewport--lg) {
        width: ((100% / 3) * 2);
    }

    @include mq($until: $viewport--md) {
        width: 100%;
    }
}

.Team {
    &-object {
        border-top-right-radius: u($spacing-unit--xl);
        border-bottom-left-radius: u($spacing-unit--xl);
        overflow: hidden;
    }  
    
    .Play-icon {
        @include position(absolute, $left: 0, $right: 0, $top: 0, $bottom: 0);
        margin: auto;
    }
}