/* Articles */
.Articles {

    @include mq($from: $viewport--sm) {

        &.is-unloaded,
        &.is-unloaded .Article {
            opacity: 0;
        }

        &-status {
            display: none;
        }
    }

    &:not(.Articles--slider):not(.Articles--alt) {
        margin-top: u($spacing-unit--xl * -1);
        margin-left: u($spacing-unit * -1);

        > .Article {
            padding-top: u($spacing-unit--xl);
            padding-left: u($spacing-unit);
        }
    }

    > .Article {

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            width: (100% / 2);
        }

        @include mq($from: $viewport--lg) {
            width: (100% / 3);
        }

        .ArticlesPage & {

            @include mq($from: $viewport--md) {

                &:nth-child(1) {
                    margin-top: u($spacing-unit--xl);
                }
            }

            @include mq($from: $viewport--lg) {

                &:nth-child(3) {
                    margin-top: u($spacing-unit--xl);
                }
            }
        }
    }

    &--alt {
        margin-top: u($spacing-unit * -1);
        margin-left: u($spacing-unit * -1);

        > .Article--alt {
            padding-top: u($spacing-unit);
            padding-left: u($spacing-unit);
        }
    }

    &:not(.has-masonry) {
        display: flex;
        flex-wrap: wrap;
    }
}

.Articles--jobs {

    > .Article {

        @include mq($from: $viewport--md) {
            width: (100% / 2);
        }
    }
}

.Block--articles {

    @include mq($from: $viewport--md) {

        > .Container {
            @include grid(1fr auto, auto);
        }

        .Block-title {
            @include grid-area(1, 2, 1, 2);
            margin-bottom: u(32);
        }

        .Button--bare {
            @include grid-area(2, 3, 1, 2);
            align-self: start;
        }

        .Articles {
            @include grid-area(1, 3, 2, 3);
        }
    }

    .Slider {

        &:not(.Slider--initialized) {
            margin-left: u($spacing-unit / -1.5);

            .Slider-item {
                padding-left: u($spacing-unit--sm);
            }
        }

        @include mq($from: $viewport--md) {

            &:not(.Slider--initialized) {
                margin-left: u($spacing-unit * -1);

                .Slider-item {
                    padding-left: u($spacing-unit);
                }
            }

            .Slider-item {

                @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                    width: (100% / 2);
                }

                @include mq($from: $viewport--lg) {
                    width: (100% / 3);
                }
            }
        }
    }

    @include mq($until: $viewport--md - 1) {

        .Articles {
            margin-right: u($spacing-unit * -1);
            margin-left: u($spacing-unit * -1);
            padding-left: u($spacing-unit);
            overflow: hidden;

            .Slider {
                overflow: visible;
                width: calc(100% - #{u($spacing-unit)});
            }
        }
    }
}

.Block--cases {

    @include mq($until: $viewport--md - 1) {

        .Block-title {
            background-color: $white;
        }

        .Block-header--mobile {

            &::before {
                background-color: $yellow--300;
            }
        }
    }
}
