/* Components: Chip */

// Namespace
$chip-namespace:                      $namespace !default;

// Padding
$chip-padding--t:                     8  !default;
$chip-padding--r:                     $spacing-unit / 1.5 !default;
$chip-padding--b:                     $chip-padding--t !default;
$chip-padding--l:                     $chip-padding--r !default;

// Typography
$chip-font-size:                      $font-size !default;
$chip-text-align:                     center !default;

// Borders
$chip-border-width:                   0 !default;
$chip-border-radius:                  20.5 !default;

// Disabled
$chip-disabled-opacity:               60% !default;
