.BannerNew{
    padding-bottom: u(40);

    &-header{
        margin: 0 auto;
       position: relative;
    }

    &-dot{
        color: $yellow--300;
        display: inline-block;
    }

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,26px,28px);
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        color: $grey--500;
        margin-bottom: u(10);
        display: inline-flex;
        position: relative;

        @include mq($until: $viewport--md) {
            width: 100%;
        }

    }
    &-intro{
        max-width: u(850);

        p:last-of-type{
            margin-bottom: 0;
        }
    }

    &-icon{
        @include position(absolute,$right: -220px,$top: -100px);
        width: u(290);
        height: u(140);

        @include mq($until: $viewport--md) {
            right: 0;
        }
    }
}