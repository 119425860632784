/* Components: Notification */

// Namespace
$notification-namespace:                      $namespace !default;

// Typography
$notification-font-size:                      12 !default;
$notification-text-align:                     center !default;

// Borders
$notification-border-width:                   0 !default;
$notification-border-radius:                  100% 100% 100% 0 !default;
