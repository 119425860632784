.Highlight{
    @include position(relative, $z: 1);
    @include breakout();
    padding: u(140,24);

    @include mq($until: $viewport--md) {
        padding: u(70,0);
        padding-right: u(24);
    }

    &::before {
        background-color: $yellow--300;
        border-top-right-radius: u($spacing-unit--xl);
        border-bottom-right-radius: u($spacing-unit--xl);
    }

    &-grid{
        display: flex;

        @include mq($until: $viewport--md) {
            flex-direction: column;
        }
    }

    &-imgContainer{
        width: 40%;

        @include mq($until: $viewport--md) {
            width: 100%;
        }
    }

    &-content{
            width: 60%;
            padding-left: u(50);
            max-width: u(500);

            @include mq($until: $viewport--md) {
                width: 100%;
                padding-left: 0;
                padding-top: u(24);
            }
    }

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-1,$heading-size-1--lg);
        margin-bottom: u(8);
    }

    &-subTitle{
        font-family: $font-ibm;
        font-size: u(16);
        font-weight: bold;
        line-height: 1.43;
        color: $grey--500;
    }

    &-dot{
        color: $white;
        display: inline-block;
    }

    &-text{
        padding-top: u(24);

        p:last-of-type{
            margin-bottom: 0;
        }
    }

    &-btnContainer{
        padding-top: u(55);
    }
}