.Download{
    background-image: url('/assets/img/background-download.jpg');
    background-position: center;
    background-repeat: no-repeat;
    min-height: u(217px);
    border-radius: 8px;
    border: dashed 2px #fad018;
    position: relative;
    padding: u(60,24);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-inner{
        position: absolute;
        top:0;
        left: 0;
        border-radius: 8px;
        border: solid 2px #fad018;
        width: calc(100% - 5px);
        transform: translate(2.5px,2.5px);
        height: calc(100% - 5px);
    }

    &-title{
        font-family: $font-ibm;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: normal;
        color: $grey--600;
        margin-bottom: u(24);
        text-align: center;
    }

    &-link{
        position: relative;
        z-index: 5;
    }
}
.BrochureModal{

    &-downloadLink{
        visibility: hidden;
        opacity: 0;
    }

    &-object{
        display: block;
        height: 100%;
    }

    &-imgContainer{
        height: 100%;

        // @include mq($until: $viewport--lg) {
        //     padding-right: u(24);
        // }
    }

    &-image{
        border-bottom-left-radius: 40px;
        border-top-left-radius: 40px;
        height: 100%;
        width: 100%;
        object-fit: cover;

        @include mq($until: $viewport--lg) {
            border-radius: 40px;
        }
    }

    &-content{
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: u(48);
        padding-top: u(24px);
        padding-bottom: u(24px);

        @include mq($until: $viewport--lg) {
            padding-left: u(0);
            padding-right: u(24);
        }
    }

    &-title{
        font-size: 24px;
        font-weight: bold;
        letter-spacing: normal;
        font-family: $font-ibm;
    }
}