.Block--icons {

    .Icons {

        &Title {
            @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
            @include fluid-type(margin-bottom,$viewport--md,$viewport--lg,40px,70px);
    
            strong {
                color: $yellow--300;
            }
        }

        &Item {

            &-icon {
                background-color: $yellow--300;
                display: flex;
                justify-content: center;
                align-items: center;
                @include fluid-type(width,$viewport--md,$viewport--lg,140px,145px);
                @include fluid-type(height,$viewport--md,$viewport--lg,140px,145px);
                border-radius: 100%;
                margin-bottom: u(30) !important;

                .Icon {
                    @include fluid-type(width,$viewport--md,$viewport--lg,60px,70px);
                }
            }

            &-title {
                @include fluid-type(font-size,$viewport--md,$viewport--lg,18px,20px);
                font-weight: 600;
                display: flex;
                justify-content: center;
                min-height: u(60);
                text-align: center;
                width: 100%;
                line-height: initial;

                @include mq($until: $viewport--md - 1) {
                    min-height: auto;
                }
            }

            &-line {
                width: 100%;
                height: u(3);
                background-color: $yellow--300;
                margin-bottom: u(15);
                margin-top: u(15);
                display: flex;
            }

            &-text {

                p {
                    text-align: center;
                }
            }
        }
    }
}