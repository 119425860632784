/* Fields: select */
select {
    cursor: pointer;
    width: 100% !important;

    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}

.#{$form-namespace}Form-field--select {
    @extend %Form-field--reset;
    @extend %Form-field--shadow;
    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -8 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="jam jam-chevron-down Button-icon"><path d="M7.071 5.314l4.95-4.95a1 1 0 1 1 1.414 1.414L7.778 7.435a1 1 0 0 1-1.414 0L.707 1.778A1 1 0 1 1 2.121.364l4.95 4.95z"></path></svg>');
    background-position: right u(9.3) center;
    background-repeat: no-repeat;
    background-size: 1em;
    box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow);
    // line-height: normal;
    overflow: hidden;
    padding: em($form-element-padding--t, $form-select-element-icon-width, $form-element-padding--b, $form-element-padding--l);

    // Placeholder
    &.is-placeholder {
        color: $grey--300;
    }

    // Icon
    .with-icon & {
        padding-left: u(16 + 9.3 + $form-element-padding--l);
    }
}

.#{$form-namespace}Form-field--select[multiple] {
    @extend %Form-field--reset;
    height: auto;

    option {
        padding: em(halve($form-element-padding--t), $form-element-padding--r, halve($form-element-padding--b), $form-element-padding--l);

        + option {
            border-top: 1px solid $form-element-border-color;
        }
    }
}
