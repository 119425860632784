/* Blog */
.BlogDetailPage {

    .Container {

        + .Container {
            margin-top: u($spacing-unit * 1.5);

            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit--lg);
            }
        }
    }
}

.Content {

    &-item {

        .Slider:not(:only-child) {
            margin-bottom: u($spacing-unit);
        }

        @include mq($until: $viewport--md - 1) {

            .Slider-item {
                max-width: 85%;
            }
        }

        @include mq($from: $viewport--md) {

            .Slider-items {
                margin-left: u($spacing-unit--xl * -1);
                width: auto;
            }

            .Slider-item {
                flex-shrink: 1;
                margin-left: u($spacing-unit--xl);
            }
        }

        @include mq($from: $viewport--md) {
            clear: both;
            display: flex;
            margin-left: u($spacing-unit--xl * -1);

            .BlogDetailPage.is-advanced & {
                margin-left: u($spacing-unit * -1);
            }

            .Container:nth-child(odd) &:not(.is-full) {
                flex-direction: row-reverse;
            }

            > .Content-objects,
            > .Content-body {
                padding-left: u($spacing-unit--xl);

                .BlogDetailPage.is-advanced & {
                    padding-left: u($spacing-unit);
                }
            }
        }
    }

    &-objects {
        display: block;

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--md) {
            flex-basis: 50%;
            flex-shrink: 0;

            .BlogDetailPage.is-advanced & {

                &.is-odd {
                    flex-basis: (100% / 1248 * 505);
                    margin-left: auto;
                }

                &.is-even {
                    flex-basis: (100% / 1248 * 400);
                }
            }
        }
    }

    &-object {
        display: block;
        overflow: hidden;

        .BlogDetailPage.is-advanced & {
            border-top-left-radius: u($spacing-unit--lg);
            border-bottom-right-radius: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--md) {
            position: relative;
            height: 100%;

            .BlogDetailPage.is-advanced .Content-objects.is-odd & {
                border-top-left-radius: u($spacing-unit--xl);
                border-bottom-left-radius: u($spacing-unit--xl);

                @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                    margin-right: u($structure-gutter--l * -1);
                }

                @include mq($from: $structure-responsive-breakpoint) {
                    margin-right: calc((100vw - #{u($structure-width)}) / -2);
                }
            }

            .BlogDetailPage.is-advanced .Content-objects.is-even & {
                border-top-right-radius: u($spacing-unit--xl);
                border-bottom-right-radius: u($spacing-unit--xl);

                @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                    margin-left: u($structure-gutter--l * -1);
                }

                @include mq($from: $structure-responsive-breakpoint) {
                    margin-left: calc((100vw - #{u($structure-width)}) / -2);
                }
            }
        }
    }

    &-image {
        width: 100%;

        @include mq($from: $viewport--md) {
            @include position(absolute, 0, 0, 0, 0);
            object-fit: cover;
            height: 100%;
        }
    }

    &-body {

        .Container:nth-child(odd) .Content-item:not(.is-full) & {
            max-width: u(616);
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}

.Media {
    margin-bottom: u($spacing-unit);

    &:not(.Media--right):not(.Media--left) {

        > .Picture {
            display: block;
        }
    }

    @include mq($until: $viewport--md - 1) {
        margin-bottom: u($spacing-unit--sm);
    }

    @include mq($from: $viewport--md) {

        &--right,
        &--left {
            max-width: u(506);
        }

        &--json,
        &--video {
            width: 100%;
        }

        &--right {
            margin-left: u(40);
            float: right;
        }

        &--left {
            margin-right: u(40);
            float: left;
        }

        &--right,
        &--left {

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                max-width: u(250);
            }
        }
    }
}

.Author {

    &-title {
        font-size: u(14);
        font-weight: 600;
        line-height: 1.32;
        color: $grey--500;

        margin-bottom: u($spacing-unit--xs / 4);

        @include mq($until: $viewport--md - 1) {
            @include font-size(14, false);
        }
    }

    &-body{
        display: flex;
        flex-direction: column;
    }

    &-object {
        @include dimensions(48);
        flex-shrink: 0;
        margin-right: u(8);

        @include mq($from: $viewport--md) {
            margin-right: u(8);
        }

        &--small {
            @include dimensions(37);

            @include mq($until: $viewport--md - 1) {
                @include dimensions(37);
            }
        }
    }
}
