/* Services */
.Block--service {
    @include position(relative, $z: 1);

    &.is-odd {

        @include mq($until: $viewport--md - 1) {

            .Block-title {
                background-color: $white;
            }

            .Block-header {

                &--mobile {

                    &::before {
                        background-color: $yellow--300;
                    }
                }
            }
        }
    }

    &.is-even {
        position: relative;

        @include mq($until: $viewport--md - 1) {
            padding-bottom: u($spacing-unit--xl);

            &::before {
                @include position(absolute, u(55 / 2), 0, 0, 0, -1);
                background-color: $yellow--300;
                border-top-left-radius: px($spacing-unit--xl);
                content: '';
            }

            .Block-title {
                background-color: $yellow--300;
            }

            .Block-header--mobile {

                &::before {
                    background-color: $white;
                }
            }

            .CallToAction {

                &-object {
                    background-color: $white;
                    color: $yellow--300;
                }

                &-objectItem {
                    background-color: $yellow--300;
                }

                &-icon {
                    color: $white;
                }

                &-arrow {
                    color: $yellow--300;
                }
            }
        }
    }

    .Button--toggle {
        color: $yellow--300;
    }

    .Block-footer {

        @include mq($until: $viewport--md - 1) {
            text-align: center;
        }
    }
}

.Service {

    &-intro {

        @include mq($from: $viewport--md) {
            @include grid(1fr 1fr, auto auto);
            margin-left: u($spacing-unit * -1);

            > .Block-header,
            > .ServiceIntro-object,
            > .ServiceIntro-body {
                margin-left: u($spacing-unit);
            }

            .Block--service.is-odd & {

                .Block-header {
                    @include grid-area(1, 2, 1, 2);
                }
            }

            .Block--service.is-even & {
                padding-bottom: u($spacing-unit--lg);

                .Block-header {
                    @include grid-area(2, 3, 1, 2);
                }
            }
        }
    }

    &Intro {

        &-title {
            counter-increment: i;
            word-break: keep-all;

            .ServicesPage & {

                &::before {
                    content: counter(i) ". ";
                }
            }

            @include mq($from: $viewport--md) {

                .Block--service.is-even & {
                    text-align: right;
                }
            }
        }

        &-object {
            align-self: start;
            justify-self: start;
            position: relative;

            @include mq($from: $viewport--md) {

                .Block--service.is-odd & {
                    @include grid-area(1, 2, 2, 3);
                }

                .Block--service.is-even & {
                    @include grid-area(2, 3, 2, 3);
                }
            }

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit);
            }

            // Hover
            // .Block--service.is-odd & {

            //     @include mq($from: $viewport--md) {

            //         &::after {
            //             @include position(absolute, 0, 0, 50%, 1%, $z: -1);
            //             background-color: $yellow--100;
            //             border-top-left-radius: px(61.5);
            //             border-bottom-left-radius: px(61.5);
            //             content: '';
            //             transform: rotate(1.25deg);
            //             transform-origin: top right;
            //         }
            //     }
            // }
        }

        &-body {
            max-width: u(462 + $spacing-unit--xl + $spacing-unit);
            position: relative;

            .Block--service.is-expanded & {

                .Button {
                    display: none;
                }
            }

            .Block--service.is-odd & {

                @include mq($from: $viewport--md) {
                    @include grid-area(2, 3, 2, 3);
                    padding-right: u($spacing-unit--xl);
                    padding-left: u($spacing-unit);
                    justify-self: end;
                    align-self: center;
                }
            }

            .Block--service.is-even & {

                @include mq($from: $viewport--md) {
                    @include breakout();
                    @include grid-area(1, 2, 2, 3);
                    margin-right: u($spacing-unit);
                    // padding-left: u($spacing-unit--xl);
                    max-width: u(505);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .Button {
                    color: $white;
                }

                &::before {

                    @include mq($from: $structure-responsive-breakpoint) {
                        margin-left: calc((100vw - #{u($structure-width)}) / -2);
                        width: calc((100vw - #{u($structure-width)}) / 2 + 160%);
                    }

                    @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                        margin-left: u($structure-gutter--l * -1);
                        width: calc(#{u($structure-gutter--l)} + 160%);
                    }

                    @include mq($from: $viewport--md) {
                        background-color: $yellow--300;
                        border-top-right-radius: px($spacing-unit--lg);
                        border-bottom-right-radius: px($spacing-unit--lg);
                        height: calc(100% + #{u($spacing-unit--lg * 2)});
                        margin-top: u($spacing-unit--lg * -1);
                        left: 0;
                    }
                }
            }
        }
    }

    &-toggle {
        @include transition(#{opacity, visibility}, 500ms);
        margin-left: u($spacing-unit * -1);

        .Block--service.has-toggle.is-expanded &,
        .Block--service:not(.has-toggle) & {

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit--xl);
            }
        }

        .Block--service.has-toggle:not(.is-expanded) & {
            overflow: hidden;
            height: 0;
            opacity: 0;
            visibility: hidden;
        }

        > .Service-item,
        > .CallToAction,
        > .Block-footer {
            margin-left: u($spacing-unit);
        }

        > .Service-item {

            :last-child {
                margin-bottom: 0;
            }

            @include mq($until: $viewport--md - 1) {

                + .Service-item {
                    margin-top: u($spacing-unit)
                }
            }
        }

        .CallToAction {
            margin-top: u($spacing-unit--xl);

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit * 3);
            }

            &--right {
                justify-self: end;
                margin-left: auto;
            }
        }

        @include mq($until: $viewport--md - 1) {

            .Block--service:nth-of-type(3n-1) & {

                .WhatWeDo {
                    position: relative;
                    margin-top: u($spacing-unit--sm * -1);
                    padding-bottom: u($spacing-unit--sm);

                    &::before {
                        @include position(absolute, u($spacing-unit--sm), 0, 0, 0, -1);
                        background-color: $white;
                        border-radius: px(171.5);
                        content: '';
                    }
                }
            }

            .Block--service.is-even & {

                .Button--toggle {
                    color: $white;
                }
            }
        }

        @include mq($from: $viewport--md) {

            .Block-footer {
                @include grid-area(1, 3, 3, 4);
                justify-self: center;
                text-align: center;
            }

            .Block--service.has-text.has-text:nth-of-type(3n-2) & {
                @include grid(1fr 1fr, repeat(3, auto));

                .WhatYouGet {
                    margin-left: auto;
                }

                .Service-item {

                    &--whatWeDo {
                        @include grid-area(1, 2, 1, 2);
                    }

                    &--whatYouGet {
                        @include grid-area(2, 3, 1, 2);
                    }
                }

                .CallToAction {
                    @include grid-area(1, 3, 2, 3);
                }

                .Block-footer {
                    @include grid-area(1, 3, 3, 4);
                }
            }

            .Block--service.has-text.has-text:nth-of-type(3n-1) & {
                @include grid(auto 1fr, repeat(3, auto));

                .Service-item {

                    &--whatWeDo {
                        @include grid-area(2, 3, 1, 2);
                        justify-self: end;
                    }

                    &--whatYouGet {
                        @include grid-area(1, 2, 1, 2);
                    }
                }

                .CallToAction {
                    @include grid-area(1, 3, 2, 3);
                }
            }

            .Block--service.has-text:nth-of-type(3n) & {
                @include grid(1fr 1fr, repeat(3, auto));

                .WhatWeDo {
                    margin-bottom: u($spacing-unit--xl);
                }

                .Service-item {

                    &--whatWeDo {
                        @include grid-area(1, 3, 1, 2);
                    }

                    &--whatYouGet {
                        @include grid-area(1, 2, 2, 3);
                    }
                }

                .CallToAction {
                    @include grid-area(2, 3, 2, 3);
                    align-self: center;
                    margin-top: 0;
                }
            }
        }
    }
}

.ServicesPage .Main {
    counter-reset: i;
}

.WhatWeDo {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit * -1);
    margin-left: u($spacing-unit--xs * -1);

    @include mq($from: $viewport--md) {
        margin-top: u($spacing-unit--sm * -1);
        margin-left: u($spacing-unit--sm * -1);
    }

    @include mq($until: $viewport--md - 1) {
        justify-content: center;
    }

    &-item {
        padding-right: u($spacing-unit--xs);
        padding-left: u($spacing-unit--xs);
        max-width: u(159);
        text-align: center;
        width: 100%;
        margin-top: u($spacing-unit);
        margin-left: u($spacing-unit--xs);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--sm);
            margin-left: u($spacing-unit--sm);
        }
    }

    &-image {
        margin-bottom: u($spacing-unit--xs);
        max-width: u(120);
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }
}

.WhatYouGet {

    @include mq($from: $viewport--md) {
        max-width: u(462 + $spacing-unit--xl + $spacing-unit);
        padding-right: u($spacing-unit--xl);
        padding-left: u($spacing-unit);

        .Block--service:nth-of-type(3n-1) & {
            padding-left: 0;
        }
    }
}

.Info {

    .Services {

        @include mq($from: $viewport--md) {
            counter-reset: i;
            max-width: u(1140);

            .Grid-cell,
            .Service-item {
                display: flex;
                flex-direction: column;
            }

            .Service-item {
                flex-grow: 1;

                .Button {
                    margin-top: auto;
                }
            }
        }
    }

    .Service {

        &-item {
            counter-increment: i;
            max-width: u(294);

            &::before {
                @include dimensions(39);
                background-color: $white;
                border-radius: 100%;
                content: counter(i);
                color: $yellow--300;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $heading-font-stack;
                font-weight: 700;
                margin-bottom: u($spacing-unit / 0.75);
            }

            .Button {
                color: $white;
                font-weight: 700;
            }
        }

        &-title {
            margin-bottom: u($spacing-unit--sm);
        }
    }
}
