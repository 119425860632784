.BgBanner {
    position: relative;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: u(180);
    padding-bottom: u(300);

    &-header{
        margin: 0 auto;
       position: relative;
    }

    &-dot{
        color: $yellow--300;
        display: inline-block;
    }

    &-title{
        @include fluid-type(font-size,$viewport--md,$viewport--lg,26px,58px);
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        color: $grey--500;
        margin-bottom: u(10);
        display: inline-flex;
        position: relative;

        @include mq($until: $viewport--md) {
            width: 100%;
        }
    }
    
    &-intro{
        max-width: u(500);
        @include fluid-type(font-size,$viewport--md,$viewport--lg,16px,24px);

        p:last-of-type{
            margin-bottom: 0;
        }
    }

    &-video{
        @include position(absolute,$top:0,$left:0);
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 0;
        opacity: 0.3;
    }
}