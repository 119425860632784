/* Navs */
.Navs {

    @include mq($from: $viewport--md) {
        flex-grow: 1;
    }

    @include mq($until: $viewport--md - 1) {
        @include position(fixed, 0, 0, 0, 0, 4);
        @include transition(transform);
        display: flex;
        color: $grey--500;
        cursor: pointer;

        html:not(.show-nav) & {
            pointer-events: none;
            transform: translateX(100%) translateX(#{u(-58 - $spacing-unit)});

            .Nav-toggle {
                pointer-events: auto;
            }
        }
    }

    .Nav-toggle {

        @include mq($until: $viewport--md - 1) {
            align-self: start;
            background-color: $white;
            border-top-left-radius: u(70px);
            border-bottom-left-radius: u(70px);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
            flex-shrink: 0;
            margin-top: u($spacing-unit);
            padding-right: u($spacing-unit--sm);
            padding-left: u($spacing-unit--xs);
            margin-left: u($spacing-unit);

            .Toggle-icon {
                @include dimensions(34);
            }
        }
    }

    &-body {
        display: flex;
        align-items: center;

        @include mq($until: $viewport--md - 1) {
            @include transition(box-shadow);
            background-color: $white;
            flex-direction: column;
            flex-grow: 1;
            overflow-y: auto;
            padding: u($spacing-unit--xl, $spacing-unit / 0.75, $spacing-unit, $spacing-unit / 0.75);
            cursor: default;

            .show-nav & {
                box-shadow: -2px 0px 8px rgba($black, 0.25);
            }
        }
    }
}

/* Nav */
.Nav {

    // Main
    &--main {

        @include mq($from: $viewport--md) {
            margin-left: auto;
        }

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--xl);
            margin-bottom: u($spacing-unit--xl);
        }

        .Nav-list {

            @include mq($until: $viewport--md - 1) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .Nav-item {

            &--jobs {
                position: relative;
            }

            &--hasDropdown{
                &:hover{
                    .Nav--dropdown{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            + .Nav-item {

                @include mq($until: $viewport--md - 1) {
                    margin-top: u($spacing-unit / 0.75);
                }
            }
        }

        .Nav-link {
            &,
            &:active,
            &:focus,
            &:hover {
                color: $grey--500;
            }

            &.is-active {
                display: flex;
                align-items: center;
                font-weight: 700;

                // &::before {
                //     @include dimensions(11);
                //     background-color: currentColor;
                //     border-radius: 0 px(5.5);
                //     content: '';
                //     display: block;
                //     flex-shrink: 0;
                //     margin-right: u($spacing-unit--xs);
                // }

                // @include mq($until: $viewport--md - 1) {
                //     color: $green--300;

                //     &::before {
                //         margin-left: u(-11 + $spacing-unit--xs * -1);
                //     }
                // }
            }
        }

        .Nav-list {

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit * -1);
            }

            @include mq($from: $viewport--lg, $until: $viewport--xl - 1) {
                margin-left: u($spacing-unit--xl * -1);
            }

            @include mq($from: $viewport--xl) {
                margin-left: u($spacing-unit * -2.5);
            }
        }

        .Nav-item {
            position: relative;

            @include mq($from: $viewport--md) {
                padding-left: u($spacing-unit);
            }

            @include mq($from: $viewport--lg, $until: $viewport--xl - 1) {
                padding-left: u($spacing-unit--lg);
            }

            @include mq($from: $viewport--xl) {
                padding-left: u($spacing-unit * 2.5);
            }
        }

        @include mq($from: $viewport--md) {


            .Nav-list {
                justify-content: flex-end;
            }

            .Nav-link {

                body.HomePage & {
                    color: $white;

                    &:hover {
                        color: $yellow--300;
                    }
                }

                &:hover {
                    color: $yellow--300;
                }
            }
        }
    }


    // Social media
    &--socialMedia {
        position: relative;

        .Main & {
            @include breakout();
            display: inline-block;

            &::before {
                background-color: $grey--500;
                border-top-right-radius: px(27);
                border-bottom-right-radius: px(27);

                @include mq($from: $viewport--md) {
                    border-top-right-radius: px(50);
                    border-bottom-right-radius: px(50);
                }
            }

            .Nav-list {
                color: $white;
                padding-top: u(15);
                padding-right: u($spacing-unit);
                padding-bottom: u(15);

                @include mq($until: $viewport--md - 1) {
                    margin-left: u($spacing-unit * -1);
                }

                @include mq($from: $viewport--md) {
                    padding-top: u(34);
                    padding-right: u(76);
                    padding-bottom: u(34);
                    justify-content: flex-end;
                }
            }

            .Nav-item {
                margin-left: u($spacing-unit);
            }

            .Nav-link {
                position: relative;

                &:hover {

                    .Nav-icon {
                        transition-delay: 0ms;

                        &:not(.Nav-icon--hover) {
                            opacity: 0;
                        }

                        &--hover {
                            opacity: 1;
                            transform: scale(1.25);
                            transition-delay: 100ms;
                        }
                    }
                }

                &,
                &:active,
                &.is-active,
                &:focus,
                &.is-focused,
                &:hover,
                &.is-hovered {
                    color: $white;
                }
            }

            .Nav-icon {
                @include transition(#{opacity, transform});
                fill: currentColor;
                display: block;
                transition-delay: 100ms;

                @include mq($from: $viewport--md) {
                    @include dimensions(32);
                }

                &--hover {
                    @include position(absolute, 0, 0, 0, 0, 1);
                    fill: $green--300;
                    animation: 0;
                    opacity: 0;
                }
            }
        }

        .Header & {
            margin-top: auto;

            .Nav-item {

                + .Nav-item {
                    margin-left: u($spacing-unit / 0.75);
                }
            }
        }
    }

    // Dropdown

    &--dropdown{
        position: absolute;
        opacity: 0;
        list-style: none;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;
        padding: 0;
        top: calc(100% + 10px);
        left: 60px;
        width: calc(100% + 80px);
        margin-top: 3px;
        border-top: none;
        background-color: $grey--200;
        padding: u(16);
        border-radius: 0px 16px;

        @include mq($until: $viewport--md) {
            display: none;
        }
    }

    &-list--dropdown{
        list-style: none;
    }

    &-item--dropdown:not(:last-of-type){
        margin-bottom: u(16);
    }

    &-link--dropdown{
        color: $grey--500;
        transition: .4s all;
        text-decoration: none;

        &:hover{
            color: $yellow--300;
        }

        &.is-active{
            font-weight: bold;
            color: $grey--500;
        }
    }

    // Footer
    &--footer {
        font-size: u(10);
        line-height: (14 / 10);

        @include mq($from: $viewport--md) {
            font-size: u(14);
            line-height: (18 / 14);
        }

        .Nav-item {

            + .Nav-item {
                display: flex;
                align-items: center;

                &::before {
                    content: '|';
                    flex-shrink: 0;
                    margin-right: u($spacing-unit--xs);
                    margin-left: u($spacing-unit--xs);
                }
            }
        }

        .Nav-link {

            &,
            &:active,
            &:focus,
            &:hover {
                color: $white;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    // Column
    &--column {

        .Nav-item {

            + .Nav-item {
                margin-top: u($spacing-unit--xs);
            }
        }

        .Nav-link {

            &,
            &:active,
            &:focus,
            &:hover {
                color: $white;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    // Team
    &--team {
        margin-bottom: u($spacing-unit--xl);

        .Nav-title {
            margin-bottom: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                text-align: center;
            }
        }

        .Nav-list {
            margin-top: u($spacing-unit--sm * -1);
            margin-left: u($spacing-unit--sm * -1);

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit--lg * -1);
                justify-content: center;
            }
        }

        .Nav-item {
            padding-top: u($spacing-unit--sm);
            padding-left: u($spacing-unit--sm);

            @include mq($from: $viewport--md) {
                padding-left: u($spacing-unit--lg);
            }
        }
    }

        // Filter
        &--filter {
            margin-bottom: u($spacing-unit--xl);

            .Nav-title {
                margin-bottom: u($spacing-unit--lg);

                @include mq($from: $viewport--md) {
                    text-align: center;
                }
            }

            .Nav-list {
                margin-top: u($spacing-unit--sm * -1);
                margin-left: u($spacing-unit--sm * -1);

                @include mq($from: $viewport--md) {
                    margin-left: u($spacing-unit--lg * -1);
                }
            }

            .Nav-item {
                padding-top: u($spacing-unit--sm);
                padding-left: u($spacing-unit--sm);

                @include mq($from: $viewport--md) {
                    padding-left: u($spacing-unit--lg);
                }
            }
        }

    // Lang
    &--lang {
        @include position(relative, $z: 1);

        @include mq($from: $viewport--md) {
            margin-left: u($spacing-unit);
        }

        @include mq($from: $viewport--lg, $until: $viewport--xl - 1) {
            margin-left: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--xl) {
            margin-left: u($spacing-unit * 2.5);
        }

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit);
        }

        @include mq($from: $viewport--md) {

            &:not(:hover) {

                .Nav-list {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .Nav-toggle {
            @include transition(color);
            cursor: default;
            font-weight: 600;
            align-items: center;
            display: flex;
            text-transform: uppercase;
            padding-top: u($spacing-unit--xs);
            padding-bottom: u($spacing-unit--xs);
            color: $grey--500;

            body.HomePage & {
                color: $white;
            }

            body.HomePage .Header.is-notTop & {
                color: $grey--500;
            }

            @include mq($from: $viewport--md) {
                margin-right: u($spacing-unit--xs * -1);
            }

            &-icon {
                // margin-left: u($spacing-unit--xs);
                fill: currentColor;
            }
        }

        .Nav-list {

            @include mq($from: $viewport--md) {
                @include transition(#{opacity, visibility});
                @include position(absolute, $top: 100%, $right: 0);
                color: $black;
                background-color: $grey--200;
                border-radius: u($spacing-unit--xs);
                padding-top: u($spacing-unit--xs);
                padding-bottom: u($spacing-unit--xs);
                flex-direction: column;
            }

            @include mq($until: $viewport--md - 1) {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                margin-left: u($spacing-unit * -1);

                > .Nav-item {
                    margin-left: u($spacing-unit);
                }
            }

            @include mq($from: $structure-responsive-breakpoint) {
                transform: translateX(-50%);
                left: 50%;
                right: auto;
            }
        }

        .Nav-link {
            color: currentColor;

            @include mq($from: $viewport--md) {
                padding: u($spacing-unit--xs, $spacing-unit--xs * 2);
            }

            &.is-active {
                color: $green--300;
                font-weight: 700;

                @include mq($from: $viewport--md) {
                    background-color: $green--300;
                    color: $white;
                    font-weight: 700;
                }
            }

            &:not(.is-active):hover {
                color: $green--300;
            }
        }
    }
}
