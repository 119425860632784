.Testimonials {
    max-width: 1100px;

    &Item {
        position: relative;

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u(40);
        }

        &-button {
            position: absolute;
            bottom: u(-20);
            @include fluid-type(right,$viewport--md,$viewport--lg,30px,100px);

            @include mq($until: $viewport--md - 1) {
                left: 0;
                right: 0;
                margin: auto;
                width: fit-content;
            }
        }

        &-wrapper {
            display: none;
            background-color: $grey--500;
            border-radius: u(60);
            overflow: hidden;

            &.active-item {
                display: flex;

                @include mq($until: $viewport--md - 1) {
                    flex-direction: column;
                }
            }
        }

        &-avatar {
            border-radius: u(60);
            overflow: hidden;
            min-height: 100%;
            max-width: u(320);

            @include mq($until: $viewport--md - 1) {
                max-width: 100%;
            }

            &-object, &-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-arrow {
            position: absolute;
            right: u(40);
            top: u(30);

            .Arrow {
                width: u(30);
            }
        }

        &-content {
            padding: u(50) u(40);
            max-width: 60%;

            @include mq($until: $viewport--md - 1) {
                max-width: 100%;
                padding: u(30) u(20) u(50) u(20);
            }

            .Hashtags {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: u($spacing-unit / 2);

                &Item {
                    margin-right: u($spacing-unit / 2);
                    color: $white;
                    opacity: .4;
                    @include fluid-type(font-size,$viewport--md,$viewport--lg,12px,16px);
                }
            }

            .Text {
                margin-bottom: u($spacing-unit /  2);

                p {
                    color: $white;
                    @include fluid-type(font-size,$viewport--md,$viewport--lg,16px,24px);
                    font-family: $font-ibm;
                    margin-bottom: 0;
                }
            }

            .Author {
                color: $yellow--300;
                @include fluid-type(font-size,$viewport--md,$viewport--lg,15px,18px);
            }
        }
    }

    &Pagination {
        margin-top: u($spacing-unit);
        display: flex;
        justify-content: center;
        align-items: center;

        &-bullet {
            display: flex;
            width: u(15);
            height: u(15);
            border: u(2) solid $yellow--300;
            border-radius: 100%;
            margin: 0 u(10);
            cursor: pointer;

            &.active-bullet {
                background-color: $yellow--300;
            }
        }
    }
}