/* Feed */
.Feed {
    @include grid(repeat(2, 1fr), auto repeat(2, 1fr));

    @include mq($until: $viewport--md - 1) {
        margin-top: u($spacing-unit--xs * -1);
        margin-left: u($spacing-unit--xs * -1);

        .Nav--socialMedia,
        .Feed-item {
            margin-top: u($spacing-unit--xs);
            margin-left: u($spacing-unit--xs);
        }
    }

    .Nav--socialMedia {
        @include grid-area(1, 3, 1, 2);
        justify-self: start;
        margin-bottom: u($spacing-unit--sm);

        @include mq($from: $viewport--md) {
            grid-column: 1 / 5;
            margin-bottom: u($spacing-unit);
            padding-left: u($spacing-unit);
        }
    }

    &-item {
        display: block;

        &:nth-of-type(1) {
            @include grid-area(1, 2, 2, 3);
        }

        &:nth-of-type(2) {
            @include grid-area(2, 3, 2, 3);

            @include mq($until: $viewport--md - 1) {
                transform: translateY(#{u($spacing-unit--xl * -1)});
            }
        }

        &:nth-of-type(3) {
            @include grid-area(1, 2, 3, 4);

            @include mq($from: $viewport--md) {
                @include grid-area(3, 4, 2, 3);
            }
        }

        &:nth-of-type(4) {
            @include grid-area(2, 3, 3, 4);

            @include mq($until: $viewport--md - 1) {
                transform: translateY(#{u($spacing-unit--xl * -1)});
            }

            @include mq($from: $viewport--md) {
                @include grid-area(4, 5, 2, 3);
            }
        }
    }

    @include mq($from: $viewport--md) {
        @include grid(repeat(4, 1fr), auto auto);
        margin-left: u($spacing-unit * -1);

        &-item {
            margin-left: u($spacing-unit);

            &:nth-of-type(odd) {
                margin-top: u($spacing-unit--xl);
            }
        }
    }

    &-image {
        width: 100%;
    }
}
