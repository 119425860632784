/* General */

// Small
small {
    font-size: (100% / $font-size * $heading-size-milli);
}

// Strong
strong, b {
    font-weight: 600;
}

// Focus
body:not(.is-tabbing) button:focus,
body:not(.is-tabbing) input:focus,
body:not(.is-tabbing) select:focus,
body:not(.is-tabbing) textarea:focus {
    outline: none;
}

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

// Animation
@keyframes button {
    0% {
        transform: rotate(0deg);
    }

    25%,
    30%,
    50% {
        transform: rotate(-5.14deg);
    }

    50%{
        transform: rotate(5.14deg);
    }

    75% {
        transform: rotate(-5.14deg);
    }

    100% {
        transform: rotate(5.14deg);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(30px);
    }
    60% {
      transform: translateY(15px);
    }
  }

@keyframes callToAction {
    0% {
        transform: rotate(0deg);
    }

    25%,
    30%,
    50% {
        transform: rotate(-1.82deg);
    }

    50%{
        transform: rotate(1.82deg);
    }

    75% {
        transform: rotate(-1.82deg);
    }

    100% {
        transform: rotate(1.82deg);
    }
}

@keyframes icon {
    0% {
        transform: translateX(0);
    }

    #{100% / 7} {
        transform: translateX(60%)
    }

    #{100% / 7 * 2} {
        transform: translateX(-60%)
    }

    #{100% / 7 * 3} {
        transform: translateX(40%)
    }

    #{100% / 7 * 4} {
        transform: translateX(-40%)
    }

    #{100% / 7 * 5} {
        transform: translateX(20%)
    }

    #{100% / 7 * 6} {
        transform: translateX(-20%)
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes icon-reverse {
    0% {
        transform: translateY(0);
    }

    #{100% / 7} {
        transform: translateY(60%)
    }

    #{100% / 7 * 2} {
        transform: translateY(-60%)
    }

    #{100% / 7 * 3} {
        transform: translateY(40%)
    }

    #{100% / 7 * 4} {
        transform: translateY(-40%)
    }

    #{100% / 7 * 5} {
        transform: translateY(20%)
    }

    #{100% / 7 * 6} {
        transform: translateY(-20%)
    }

    100% {
        transform: translateY(0);
    }
}

// Fancybox

.Fancybox{
    position: relative;
    display: block;

    path{
        transition: .4s all;
    }

    &-lookingBack{
        @include mq($until: $viewport--md) {
            padding-bottom: 0 !important;
        }
    }

    &-play{
        @include position(absolute, $top: 50%, $left: 50%);
        transform: translate(-50%,-50%);
        width: u(75);
        height: u(75);
        z-index: 10;
    }

    &-lastchild{
        margin-top: 48px;
        margin-left: 24px;

        @include mq($until: $viewport--md) {
            margin-top: 0;
        }
    }

    &:hover{
        path{
            fill: $yellow--300;
        }
    }
}

// List
ul:not([class]) {
    list-style-type: none;

    > li {
        margin-bottom: 0;
        padding-left: u($spacing-unit);

        + li {
            margin-top: u($spacing-unit--sm);

            .Content-item & {
                margin-top: u($spacing-unit--xs);
            }

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit);

                .Content-item & {
                    margin-top: u($spacing-unit--xs);
                }
            }
        }

        &::before {
            @include dimensions(8);
            background-color: $yellow--300;
            border-radius: 100%;
            content: '';
            display: inline-block;
            margin-top: u(9);
            margin-right: u($spacing-unit--sm);
            margin-left: u($spacing-unit * -1);
            vertical-align: top;

            .Info & {
                background-color: $white;
            }

            .Block--service:nth-of-type(3n-1) & {
                background-color: $white;

                @include mq($from: $viewport--md) {
                    background-color: $green--300;
                }
            }
        }
    }
}

// Colors
.CasesDetailPage,
.BlogDetailPage {

    h3 {
        line-height: (23/ 16);

        &:not(.Related-title):not(.LookingBack-title) {
            color: $yellow--300;
            margin-bottom: u($spacing-unit--sm);
        }
    }
}

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
    margin-top: u($spacing-unit--xs * -1);
}

.Slider--media,
.Slider--related {

    .Picture {
        @include position(relative, $z: 1);
        overflow: hidden;
    }

    .Slider-item:first-child .Picture:not([class*="u-radius"]) {
        border-top-left-radius: u($spacing-unit--xl);
        border-bottom-left-radius: u($spacing-unit--xl);
    }

    .Slider-item:last-child .Picture:not([class*="u-radius"]) {
        border-top-right-radius: u($spacing-unit--xl);
        border-bottom-right-radius: u($spacing-unit--xl);
    }
}

// Default
.DefaultPage {

    .Main {

        > .Container {

            > p,
            > ol,
            > ul,
            > h2 {
                max-width: u(824);
            }

            > .Media--left ~ p,
            > .Media--left ~ ol,
            > .Media--left ~ ul,
            > .Media--left ~ h2 {
                max-width: none;
            }

            > h2 {
                clear: both;

                &:not(:first-child) {
                    margin-top: u($spacing-unit--xl);
                }
            }
        }
    }
}

// Gitlab
.Main .gitlab-embed-snippets {
    margin: 0;
}

.Code {
    max-width: u(800);
}

.Date {
    font-weight: 400;
    color: $grey--400;
    font-size: u(14);
}