/* Elements: Heading */

// Namespace
$heading-namespace:                   $namespace !default;

// Typography
$heading-font-stack:                  $font-ibm !default;

// Sizes
$heading-size-giga:                   96 !default;
$heading-size-mega:                   72 !default;
$heading-size-kilo:                   48 !default;

$heading-size-1:                      32 !default;
$heading-size-1--md:                  40 !default;
$heading-size-1--lg:                  48 !default;
$heading-size-2:                      26 !default;
$heading-size-2--md:                  24 !default;
$heading-size-2--lg:                  36 !default;
$heading-size-3:                      22 !default;
$heading-size-3--md:                  24 !default;
$heading-size-3--lg:                  28 !default;
$heading-size-4:                      20 !default;
$heading-size-5:                      18 !default;
$heading-size-6:                      16 !default;

$heading-size-milli:                  14 !default;
$heading-size-micro:                  12 !default;

// Spacing
$heading-spacing-giga:                true !default;
$heading-spacing-mega:                true !default;
$heading-spacing-kilo:                true !default;

$heading-spacing-1:                   $spacing-unit !default;
$heading-spacing-2:                   $spacing-unit !default;
$heading-spacing-3:                   $spacing-unit !default;
$heading-spacing-4:                   $spacing-unit !default;
$heading-spacing-5:                   $spacing-unit !default;
$heading-spacing-6:                   $spacing-unit !default;

$heading-spacing-milli:               true !default;
$heading-spacing-micro:               true !default;
