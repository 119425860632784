/* Quote */
.Quote {
    text-align: right;
    max-width: u(505);
    margin-left: auto;

    // Body
    &-body {
        @include breakout(right);
        color: $white;
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit);
        padding-left: u($spacing-unit);
        position: relative;

        @include mq($from: $viewport--md) {
            font-family: $font-ibm;
            font-weight: 700;
            padding-top: u(35);
            padding-bottom: u(35);
            padding-left: u(65);
            line-height: (23 / 16);
        }

        &::before {
            background-color: $green--300;
        }

        > :last-child {
            margin-bottom: 0;
        }

        &::before,
        &::after {
            border-top-left-radius: px(61.5);
            border-bottom-left-radius: px(61.5);

            @include mq($from: $viewport--md) {
                border-top-left-radius: px(69.5);
                border-bottom-left-radius: px(69.5);
            }
        }

        // Hover
        &::after {
            @include transition(transform);
            @include position(absolute, 0, -2%, 0, 0, $z: -2);
            animation: 0;
            background-color: $green--100;
            content: '';
            width: calc(100% + #{u($structure-responsive-gutter--l)});

            @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                width: calc(100% + #{u($structure-gutter--l)});
            }

            @include mq($from: $structure-responsive-breakpoint) {
                width: calc((100vw - #{u($structure-width)}) / 2 + 100%);
            }
        }
    }

    // Meta
    &-author {
        display: block;
        margin-top: u($spacing-unit--xs);
        // margin-right: u($spacing-unit);
        margin-left: u($spacing-unit);
    }
}
