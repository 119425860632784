.Cases,
.BlogPosts{

    &-header{
        display: flex;
        justify-content: space-between;
    }

    .Button{
        margin-bottom: u(24);
    }
}