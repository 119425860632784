.Breadcrumbs{
    color: $white;
    padding-bottom: u(65px);
    font-size: u(16);
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: $grey--500;
    position: absolute;
    width: 100%;
    z-index: 10;
    padding-top: u(15);
    padding-left: u(20);

    @include mq($until: $viewport--lg) {
        position: relative;
    }

    &--paddingBn{
        padding-bottom: 0;
    }

    @include mq($until: $viewport--md) {
        padding-bottom: u(30px);
        padding-top: u(30px);
    }

    &-list{
        list-style-type: none;
        display: flex;
        margin-bottom: 0;
        padding-left: 0;

        @include mq($until: $viewport--md) {
            display: block;
        }
    }

    &-item{

        a{
            color: $grey--500;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.5;
            transition: .4s all;
            text-decoration: none;

            &:hover{
                color: $green--300;
                text-decoration: none;
            }
        }

        &--last{
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.5;
            color: $green--300;
        }
    }

    &-slash{
        display: inline-block;
        padding-left: u(4px);
        padding-right: u(4px);
        color: $green--300;
    }
}