/* Banner */
.Block--banner {

    .is-compact & {

        @include mq($until: $viewport--md - 1) {
            background-color: transparent;
            padding-top: u($spacing-unit * 2 + 48);

            .Banner-object {
                display: none;
            }

            .Info-intro {

                &::before,
                &::after {
                    content: none;
                }
            }
        }
    }

    &.has-body {
        background-color: $yellow--300;
    }

    &-service{
        padding-bottom: u(48);
    }

    &:not(.has-body) {
        padding-bottom: u($spacing-unit);

        @include mq($until: $viewport--md) {

            &.is-compact {
                padding-bottom: 0;
            }
        }
    }

    @include mq($from: $viewport--md) {
        background-color: $yellow--200;
        border-bottom-right-radius: px($spacing-unit--xl);

        &.has-body {
            border-bottom-right-radius: px(80);
        }
    }

    @include mq($until: $viewport--md - 1) {

        &.has-body {

            .CasesDetailPage &,
            .BlogDetailPage &,
            .JobsDetailPage & {
                background-color: transparent;
            }
        }

        + .Block--articles,
        + .Block--service {
            margin-top: 0;
        }
    }

    :last-child {
        margin-bottom: 0;
    }
}

.Banner {

    &-object,
    &-image {
        display: block;
    }

    &-object {
        position: relative;

        &::after {
            @include position(absolute, 0, 0, 0, 0, 1);
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
            content: '';
        }

        &.object-fit-polyfill {

            .HomePage & {
                height: 73.275vh;
            }

            body:not(.HomePage) & {
                min-height: u(336);
                max-height: u(552);

                @include mq($until: $viewport--md - 1) {
                    height: u(336);
                }
            }
        }
    }

    &-image {
        object-fit: cover;
        width: 100%;

        .HomePage & {
            height: 73.275vh;
        }

        body:not(.HomePage) & {
            min-height: u(336);
            max-height: u(552);

            @include mq($until: $viewport--md - 1) {
                height: u(336);
            }
        }
    }
}

// Info
.Info {


    @include mq($from: $viewport--md) {
        @include grid(repeat(6, 1fr), repeat(2, auto));
        align-items: start;
        margin-left: u($spacing-unit * -1);

        & > &-intro,
        & > &-body,
        & > &-object,
        & > &-objects {
            margin-left: u($spacing-unit);
        }
    }

    &-intro {
        @include breakout();
        @include grid-area(1, 4, 1, 2);
        @include position(relative, $z: 5);

        &.has-columns {
            grid-column-end: 6;
        }

        // Position
        .Block--banner & {

            body:not(.is-compact) & {
                margin-top: u(-100);

                @include mq($until: $viewport--md - 1) {
                    padding-top: u(40);
                    padding-right: u($spacing-unit);
                    padding-bottom: u($spacing-unit);
                }
            }

            @include mq($from: $viewport--md) {

                .is-compact & {
                    margin-top: u(-100);
                }

                body:not(.is-compact) & {
                    margin-top: u($spacing-unit * -7);
                }

                padding-top: u($spacing-unit--xl);
                padding-right: u($spacing-unit--xl);
                padding-bottom: u($spacing-unit--xl);
            }
        }

        .is-compact & {

            @include mq($from: $viewport--md) {
                justify-self: start;
            }
        }

        // Background
        &::before {
            background-color: $white;
            border-top-right-radius: px($spacing-unit--lg);
            border-bottom-right-radius: px($spacing-unit--lg);
        }

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit);

            &::after {
                @include position(absolute, $top: u(100), $right: u($spacing-unit * -1), $bottom: u($spacing-unit / -1), $z: -2);
                background-color: $yellow--200;
                border-bottom-left-radius: px($spacing-unit--xl);
                content: '';
                display: block;
                width: 64vw;

                .ContactPage & {
                    background-color: $yellow--300;
                    border-bottom-left-radius: 0;
                    width: calc(100% + #{u($structure-responsive-gutter--r)});
                }
            }
        }
    }

    &-body {
        @include position(relative, $z: 1);
        @include grid-area(4, 7, 1, 3);
        justify-self: end;
        align-self: center;
        max-width: u(506);
        padding-top: u($spacing-unit--xl);
        padding-bottom: u($spacing-unit--xl);

        .has-object--1 & {
            grid-column-start: 5;
        }

        .has-object--2 & {
            @include grid-area(1, 4, 2, 3);
            justify-self: inherit;
        }

        .ServicesPage &,
        .BlocksPage & {
            @include grid-area(1, 7, 2, 3);
            max-width: inherit;
            justify-self: inherit;

            @include mq($until: $viewport--md - 1) {
                padding-top: 0;
            }
        }

        .JobsDetailPage & {
            max-width: inherit;
            justify-self: inherit;

            ul:not([class]) {

                + li {
                    margin-top: u($spacing-unit--xs);
                }

                @include mq($from: $viewport--md) {
                    column-count: 2;
                    margin-top: u($spacing-unit * -1);

                    > li {
                        display: inline-block;
                        break-inside: avoid;
                        width: 100%;

                        &:first-child {
                            margin-top: u($spacing-unit);
                        }
                    }
                }
            }

            @include mq($until: $viewport--md - 1) {
                background-color: $yellow--300;
                border-top-left-radius: u($spacing-unit--sm);
                border-bottom-left-radius: u($spacing-unit--sm);
                padding: u($spacing-unit);
                margin-top: u($spacing-unit--xl + $spacing-unit);
                margin-bottom: u($spacing-unit--sm);
                position: relative;

                &::after {
                    @include position(absolute, $top: 0, $bottom: 0, $left: 100%);
                    background-color: $yellow--300;
                    content: '';
                    width: u($structure-responsive-gutter--r);
                }
            }
        }
    }

    &-object {
        justify-self: start;

        .has-object--1 & {
            @include grid-area(1, 5, 2, 3);
            margin-top: u($spacing-unit--xl);
            align-self: end;
        }

        .has-object--2 & {

            &:last-child {
                margin-top: u($spacing-unit--xl);
                margin-left: u($spacing-unit);
            }
        }
    }

    &-objects {
        display: flex;
        align-items: flex-start;

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--lg);
            margin-left: u($spacing-unit--xs * -1);
        }

        @include mq($from: $viewport--md) {

            .has-object--2 & {
                @include grid-area(4, 7, 2, 3);
                @include position(absolute, $top: 100%, $right: 0);
                transform: translateY(-50%);
                max-width: calc(100% / 1248 * 612);
            }
        }
    }
}

// Intro
.Intro {

    &-header {
        margin-bottom: u($spacing-unit);
        display: flex;
        align-items: flex-start;

        @include mq($until: $structure-responsive-breakpoint - 1) {

            & ~ .Intro-author,
            & ~ .Intro-body {
                padding-left: u(40);
            }
        }

        .Button {
            transform: translateY(#{u((38 - 24) / 2)});

            + h1 {
                flex-grow: 1;
            }

            @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                transform: translateY(#{u((48 - 24) / 2)});
            }

            @include mq($from: $structure-responsive-breakpoint) {
                transform: translateY(#{u((57 - 24) / 2)});
            }

            &:hover {

                svg {
                    animation: icon 1s linear 1;
                }
            }
        }

        @include mq($until: $structure-responsive-breakpoint - 1) {

            .Button {
                margin-right: u($spacing-unit--sm);
            }

            + .Intro-body {
                padding-left: u($spacing-unit--sm + $spacing-unit);
            }
        }

        @include mq($from: $structure-responsive-breakpoint) {
            margin-left: u($spacing-unit--xl * -1);

            .Button {
                margin-right: u($spacing-unit);
            }
        }
    }

    &-object {
        @include position(absolute, $top: 0, $left: 0);
        transform: translateY(-50%);
    }
}
