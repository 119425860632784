/* Customers */
.Customer {
    @include transition(transform);
    @include dimensions(66);
    background-color: $grey--200;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: u($spacing-unit / 1.5);
    position: relative;

    @include mq($from: $viewport--md) {
        padding: u($spacing-unit);
    }

    &--small {
        padding: u(8);
        @include dimensions(48);
    }

    &-object {
        display: block;
    }

    &-object,
    &-image {
        width: 100%;
    }

    @include mq($from: $viewport--md) {
        @include dimensions(136);

        &--small {
            @include dimensions(72);
            padding: u(10);
        }
    }

    &:hover {
        transform: scale(1.2);
    }

    &-button{
        @include dimensions(20,20);
        cursor: pointer;
        @include position(absolute, calc(50% + 33px),null,null,null);
        transform: translateY(-50%);
        z-index: 5;

        path{
            transition: .4s all;
        }

        &:hover{
            path{
                fill: $yellow--300;
            }
        }

        &.is-disabled {
            display: none;
        }

        &.is-locked {
            display: none;
        }
    }

    &-prev{

        left: 0;
        @include mq($until: $viewport--lg) {
            bottom: -50px;
            top: initial;
            right: initial;
            left: calc(50% - 15px);
            transform: translateX(-50%);
        }
    }

    &-next{
        right: 0;

        @include mq($until: $viewport--lg) {
            bottom: -50px;
            top: initial;
            left: calc(50% + 15px);
            transform: translateX(-50%);
        }
    }
}

$customers: (
    1: ( top: 294, offset: 10 ),
    2: ( top: 82, offset: 0 ),
    3: ( top: 394, offset: 46 ),
    4: ( top: 168, offset: 0, ),
    5: ( top: 325, offset: 80 ),
    6: ( top: 40, offset: 0 ),
    7: ( top: 282, offset: 68 ),
    8: ( top: 0, offset: 8 )
);

.CustomerSlider{
    @include transition(transform);
    @include dimensions(100%,100%);
    background-color: $grey--200;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: u($spacing-unit / 1.5);
    position: relative;

    .Customer-image{
        transition: .4s all;
        filter: grayscale(1)
    }

    &:hover{
        .Customer-image{
            filter: grayscale(0);
        }
    }

    @include mq($from: $viewport--md) {
        padding: u($spacing-unit);
    }

    &-object {
        display: block;
    }

    &-object,
    &-image {
        width: 100%;
    }
}

.Block--customers {

    // .Slider--free-mode > .Slider-items {
    //     transition-timing-function: linear;
    // }

    // .Slider {
    //     padding-top: u(8);
    //     padding-bottom: u(8);

    //     @include mq($from: $viewport--md) {
    //         padding-top: u(35);
    //         padding-bottom: u(35);
    //     }

    //     @include mq($until: $viewport--md - 1) {
    //         margin-right: u($structure-responsive-gutter--r * -1);
    //         margin-left: u($structure-responsive-gutter--l * -1);
    //     }

    //     @include mq($from: $structure-responsive-breakpoint) {
    //         overflow: visible;
    //     }
    // }

    // .Slider-items {
    //     height: u(275);
    //     position: relative;

    //     @include mq($from: $viewport--md) {
    //         height: u(530);
    //     }
    // }

    // .Slider-item {
    //     height: auto;
    //     width: auto;

    //     @each $i, $css in $customers {

    //         &:nth-child(8n+#{$i}) {
    //             // background-color: rgba($black, ($i + 1) * .05);

    //             @include mq($from: $viewport--md) {
    //                 padding-left: #{u(map-deep-get($customers, $i, offset))};
    //             }

    //             > .Customer {
    //                 top: #{(100% / 530 * map-deep-get($customers, $i, top))};
    //             }
    //         }
    //     }
    // }
}
