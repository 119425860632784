.cookie-though {
    --ct-primary-400: #fcb610 !important;
    --ct-primary-300: #000 !important;
    --ct-primary-200: #fdefb0;
    --ct-primary-100: #F7F7F7;

    --ct-text: #6b7280;

    --ct-subtitle: #000;
    --ct-title: #000;

    --ct-cookie: var(--ct-primary-400);

    --ct-policy: #fff;
    --ct-policy-enabled: #fdefb0;
    --ct-policy-text: #000;
    --ct-policy-text-enabled: #000;
    --ct-policy-essential: var(--ct-primary-200);

    --ct-slider-enabled-primary: #fad018;
    --ct-slider-enabled-secondary: #fcb610;

    --ct-primary-font: "Barlow", sans-serif;
    --ct-secondary-font: "Barlow", sans-serif;
}