.Process {

    &Title {
        @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
        margin-bottom: u(80);

        @include mq($until: $viewport--md -  1) {
            margin-bottom: u(40);
        }
    }

    &Map {
        position: relative;
        max-width: 1100px;
    }

    &Steps {

        &-item {
            margin-bottom: u(10);
            background-color: $grey--500;
            border-bottom-left-radius: u(60);
            border-top-right-radius: u(60);
            padding: u(40);
            position: relative;
            cursor: pointer;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-icon {
                position: absolute;
                right: u(40);
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                height: u(50);
                width: auto;
            }

            &-title {
                @include fluid-type(font-size,$viewport--md,$viewport--lg,18px,32px);
                color: $white;
                margin-bottom: 0 !important;
            }
        }
    }

    &Modal {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;

        &-close {
            position: absolute;
            right: u(50);
            top: u(50);
            padding: 0 !important;
        }

        &-wrapper {
            background-color: $grey--500;
            border-top-left-radius: u(60);
            border-bottom-right-radius: u(60);
            padding: u(30) u(60) u(150) u(60);
            position: relative;
        }

        &-content {
            max-width: u(640);

            &-title {
                @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,38px);
                color: $yellow--300;
                margin-bottom: u(40);
            }

            &-text {

                p {
                    color: $white;
                    @include fluid-type(font-size,$viewport--md,$viewport--lg,14px,18px);
                }
            }

            &-icon {
                position: absolute;
                right: u(50);
                bottom: u(50);
                height: u(110);


                @include mq($until: $viewport--md -  1) {
                    height: u(70);
                }
            }
        }
    }

    &Point {
        width: u(25);
        height: u(25);
        background-color: $yellow--300;
        box-shadow: 0 u(3) u(6) rgba($color: #000000, $alpha: .15);
        border-radius: 100%;
        position: absolute;
        cursor: pointer;

        &:nth-of-type(1) {
            left: u(100);
            top: u(2);
        }

        &:nth-of-type(2) {
            left: 46%;
            top: u(2);
        }

        &:nth-of-type(3) {
            right: u(100);
            top: u(2);
        }

        &:nth-of-type(4) {
            left: 28%;
            bottom: u(5);
        }

        &:nth-of-type(5) {
            right: 31%;
            bottom: u(5);
        }
    }
}