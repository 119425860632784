.CaseBanner{

    &-title{
        margin-bottom: u(8);
        @include fluid-type(font-size,$viewport--md,$viewport--lg,$heading-size-3,$heading-size-3--lg);
    }

    &-catList{
        margin-bottom: u(10);
    }

    &-imgContainer{
        padding-right: u(120);
        padding-left: u(120);

        @include mq($until: $viewport--md) {
            padding-right: u(48);
            padding-left: u(48);
        }
    }

    &-cat{
        font-size: u(16);
        font-style: italic;
        line-height: 1.56;
        display: inline-block;
        margin-right: u(8);
    }

    &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &-text{
        max-width: u(500);
    }
}