.ListBlock{

    &-itemTitle{
        font-size: u(20);
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        color: $grey--500;
    }

    &-grid{
        @include fluid-type(padding-left,$viewport--md,$viewport--lg,0,150px);
        display: flex;
        justify-content: space-evenly;

        @include mq($until: $viewport--md) {
            flex-direction: column;
        }
    }

    &-item{
        width: 26%;

        @include mq($until: $viewport--md) {
            width: 100%;
        }
    }

    ul{
        list-style-type: none;

        li{
            &::before {
                @include dimensions(8);
                background-color: $green--300;
                border-radius: 100%;
                content: '';
                display: inline-block;
                margin-top: u(9);
                margin-right: u($spacing-unit--sm);
                margin-left: u($spacing-unit * -1);
                vertical-align: top;

            }
        }
    }
}