/* Call To Action */
.CallToAction {
    @include position(relative, $z: 1);
    display: flex;
    align-items: center;

    &,
    &:active,
    &.is-active,
    &:focus,
    &.is-focused,
    &:hover,
    &.is-hovered {
        color: $black;
        text-decoration: none;
    }

    @include mq($from: $viewport--md) {
        max-width: u(615);
        padding-top: u(36);
        padding-bottom: u(36);

        &::before {
            background-color: $green--300;
        }

        &::after {
            @include transition(transform);
            width: calc(100% + #{u($structure-responsive-gutter--l + $spacing-unit)});

            @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                width: calc(100% + #{u($structure-gutter--l + $spacing-unit)});
            }

            @include mq($from: $structure-responsive-breakpoint) {
                width: calc((100vw - #{u($structure-width)}) / 2 + 100% + #{u($spacing-unit)});
            }
        }

        &,
        &:active,
        &.is-active,
        &:focus,
        &.is-focused,
        &:hover,
        &.is-hovered {
            color: $white;
        }

        &--left {
            @include breakout();
            padding-right: u($spacing-unit);

            &::before,
            &::after {
                border-top-right-radius: px(92.5);
                border-bottom-right-radius: px(92.5);
            }

            &::before {
                z-index: 0;
            }

            &::after {
                right: 0;
            }
        }

        &--right {
            @include breakout(right);
            margin-left: auto;
            flex-direction: row-reverse;
            padding-left: u($spacing-unit);

            &::before,
            &::after {
                border-top-left-radius: px(92.5);
                border-bottom-left-radius: px(92.5);
            }

            &::before {
                z-index: 0;
            }

            &::after {
                left: 0;
            }
        }
    }

    // Title
    &-title {
        @include font-size(16, false);
        margin-bottom: 0;
        line-height: (23 / 16);

        @include mq($from: $viewport--md) {
            @include font-size(20, false);
            line-height: 1.3;
        }
    }

    // Body
    &-body {

        @include mq($from: $viewport--md) {
            @include position(relative, $z: 1);
            flex-grow: 1;
        }

        @include mq($until: $viewport--md - 1) {
            margin-right: u($spacing-unit--sm);
        }
    }

    // Object
    &-object {

        @include mq($until: $viewport--md - 1) {
            background-color: $green--300;
            border-top-left-radius: px(92.5);
            border-bottom-left-radius: px(92.5);
            display: flex;
            align-items: center;
            flex-shrink: 0;
            padding: u(16, 30, 16, 16);
            margin-right: u($structure-responsive-gutter--r * -1);
            margin-left: auto;
            width: u(145);
        }

        &Item {
            @include dimensions(40);
            background-color: $white;
            border-radius: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include mq($from: $viewport--md) {
                @include dimensions(59);
            }
        }

        @include mq($from: $viewport--md) {
            @include position(relative, $z: 1);

            .CallToAction--right & {
                margin-right: u(40);
            }

            .CallToAction--left & {
                margin-left: u(40);
            }
        }
    }

    // Icon
    &-icon {
        @include dimensions(24);
        color: $green--300;
        fill: currentColor;

        @include mq($from: $viewport--md) {
            @include dimensions(36);
        }
    }

    // Arrow
    &-arrow {
        color: $white;
        fill: currentColor;
        margin-left: auto;

        @include mq($from: $viewport--md) {
            display: none;
        }
    }

    // Hover
    &::after {
        @include transition(transform);
        @include position(absolute, $top: 0, $bottom: 0, $z: -1);
        animation: 0;
        background-color: $green--100;
        content: '';
    }

    &:hover {

        &::after {
            animation: callToAction 800ms linear 1;
            transform: rotate(1.82deg);
        }
    }

    &.is-hovered {

        &::after {
            transform: rotate(1.82deg);
        }
    }
}
