/* Looking Back */
.LookingBack {
    align-items: start;
    padding-bottom: u($spacing-unit--sm);

    @include mq($from: $viewport--md) {
        margin-left: u($spacing-unit * -1);
        @include grid(1fr 1fr, auto auto);

        &-object,
        &-body,
        .CallToAction {
            margin-left: u($spacing-unit);
        }
    }

    .CallToAction {

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--xl);
        }

        @include mq($from: $viewport--md) {
            @include grid-area(1, 2, 2, 3);
            align-self: end;
        }
    }

    .Grid-items + & {
        margin-top: u($spacing-unit--lg);
    }

    .Block {

        &-header {
            margin-bottom: 0;

            &::before {
                background-color: $white;
                z-index: 0;
            }
        }

        &-title:not(.Related-title) {
            background-color: $yellow--300;
            color: $white;
            z-index: 1;
        }
    }

    &-body {

        @include mq($from: $viewport--md) {
            @include grid-area(2, 3, 2, 3);
            background-color: $yellow--300;
            border-bottom-right-radius: u($spacing-unit--xl);
            border-bottom-left-radius: u($spacing-unit--xl);
            max-width: u(612);
            padding-right: u($spacing-unit);
            padding-bottom: u($spacing-unit);
            padding-left: u($spacing-unit);
        }

        @include mq($until: $viewport--md - 1) {
            background-color: $yellow--300;
            padding-right: u($spacing-unit);
            padding-bottom: u($spacing-unit);
            padding-left: u($spacing-unit);
            margin-top: u($spacing-unit--lg);
            margin-right: u($structure-responsive-gutter--r * -1);
            margin-left: u($structure-responsive-gutter--l * -1);
            padding-right: u($structure-responsive-gutter--r);
            padding-left: u($structure-responsive-gutter--l);
            position: relative;

            &,
            &::before {
                border-bottom-right-radius: u($spacing-unit--sm);
                border-bottom-left-radius: u($spacing-unit--sm);
            }

            &::before {
                @include position(absolute, 0, u($spacing-unit), u($spacing-unit--sm * -1), u($spacing-unit), -1);
                background-color: $yellow--100;
                content: '';
            }
        }
    }

    &-object {
        display: block;

        @include mq($from: $viewport--md) {
            @include grid-area(1, 3, 1, 2);
        }

        @include mq($until: $viewport--md - 1) {
            position: relative;
            padding-bottom: (100% / 327 * 250);
            border-top-left-radius: u($spacing-unit--lg);
            border-bottom-right-radius: u($spacing-unit--lg);

            .LookingBack-image {
                @include position(absolute, 0, 0, 0, 0);
                object-fit: cover;
                height: 100%;
            }
        }
    }

    &-object {

        &,
        &::after,
        .LookingBack-image {

            @include mq($from: $viewport--md) {
                border-top-right-radius: u($spacing-unit--lg);
                border-bottom-left-radius: u($spacing-unit--lg);
            }

            @include mq($until: $viewport--md - 1) {
                border-top-left-radius: u($spacing-unit--lg);
                border-bottom-right-radius: u($spacing-unit--lg);
            }
        }

        &::after {

            @include mq($until: $viewport--md - 1) {
                content: none;
            }

            @include mq($from: $viewport--md) {
                right: 5.2% !important;
            }
        }
    }

    &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: u($spacing-unit--xs);

        @include mq($from: $viewport--md) {
            height: u($spacing-unit);
        }

        .LookingBack-title {
            background-color: $yellow--300;
            border-radius: u(40);
            color: $white;
            padding: u($spacing-unit / 1.5, $spacing-unit);
            margin-bottom: 0;
            line-height: (23 / 18);

            @include mq($from: $viewport--md) {
                transform: translateY(-50%);
                padding-right: u($spacing-unit--xl);
                padding-left: u($spacing-unit--xl);
            }
        }

        @include mq($until: $viewport--md - 1) {
            z-index: 1;

            &::before {
                @include position(absolute, 0, u($structure-responsive-gutter--r * -1), 50%, u($structure-responsive-gutter--l * -1), -1);
                background-color: $white;
                content: '';
            }
        }
    }
}

.Grid-items .Foxtrot {
    color: $yellow--400;
    margin-bottom: u($spacing-unit--xs);
}