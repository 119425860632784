.Jobs {

    &Content {
        position: relative;

        &-mainTitle {
            @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,32px);
            color: $yellow--300;
            margin-bottom: u(-10);
        }

        &-subTitle {
            @include fluid-type(font-size,$viewport--md,$viewport--lg,32px,52px);
            color: $white;
            font-family: $font-barlow;
            font-weight: 500;
            line-height: initial;
        }

        &-down {
            position: absolute;
            bottom: u(50);
            right: u(50);

            @include mq($until: $viewport--md - 1) {
                right: u(20);
                bottom: u(20);
            }

            .Arrow {
                width: u(40);
            }
        }

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            background-color: $yellow--300;
            width: 100%;
            max-width: u(800);
            height: 80%;
            left: u(150);
            top: u(100);
            border-bottom-left-radius: u(60);
            border-top-right-radius: u(60);
        }

        &-contact {
            background-color: $grey--500;
            border-top-left-radius: u(60);
            border-bottom-right-radius: u(60);
            overflow: hidden;

            .Contact {

                &Title {
                    color: $yellow--300;
                    @include fluid-type(font-size,$viewport--md,$viewport--lg,14px,21px);
                    font-weight: 400;
                }

                &Name {
                    color: $white;
                    margin-bottom: 0;
                    @include fluid-type(font-size,$viewport--md,$viewport--lg,18px,26px);
                    margin-top: u(-5);
                    margin-bottom: u(-5);
                }

                &Function {
                    color: $white;
                    @include fluid-type(font-size,$viewport--md,$viewport--lg,14px,21px);
                    font-weight: 200;
                }

                &Items {
                    margin-top: u(30);
                    display: flex;
                    flex-direction: column;

                    &-href {
                        color: $white;
                        @include fluid-type(font-size,$viewport--md,$viewport--lg,16px,18px);
                        text-decoration: none;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $yellow--300;
                        }
                    }
                }
            }

            &-object {
                width: 80%;
                border-bottom-right-radius: u(60);
                overflow: hidden;
            }

            &-content {
                padding: u(30) u(50);

                @include mq($until: $viewport--md - 1) {
                    padding: u(50) u(30);
                }
            }
        }

        &-item {
            margin-bottom: u(40);

            &:last-of-type {
                margin-bottom: 0;
            }

            &--image {
                overflow: hidden;
                border-radius: u(60);
            }

            &--video {
                overflow: hidden;
                border-radius: u(60);

                .video-js {
                    width: 100% !important;
                    height: u(300) !important;

                    .vjs-big-play-button {
                        position: absolute !important;
                        left: 0 !important;
                        right: 0 !important;
                        bottom: 0 !important;
                        top: 0 !important;
                        margin: auto !important;
                        border: none !important;
                        background-color: $yellow--300;
                        border-radius: 50% !important;
                        width: 60px !important;
                        height: 60px !important;
    
                        .vjs-icon-placeholder:before {                         
                            content: "" !important;
                            background-image: url('/assets/img/playbutton.png') !important;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 15px !important;
                        }
                    }
                }

                

                .vjs-has-started .vjs-control-bar {
                    padding: 0 u(60) !important;

                    @include mq($until: $viewport--md - 1) {
                        padding: u(0) u(30) !important;
                    }
                }
            }

            &--content {
                color: $white;

                li {
                    margin-top: u(10) !important;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: $yellow--300;
                    font-family: $font-barlow;
                    font-weight: 500;
                    max-width: 90%;
                }
            }
        }

        &-tab {
            display: none;
            position: relative;

            &.active {
                display: flex;
                flex-direction: column;
                padding: u(40) u(80);
                border-radius: u(60);
                background-color: $grey--500;

                @include mq($until: $viewport--md - 1) {
                    padding: u(50) u(30);
                }
            }
        }

        &-tabs {
            background-color: $grey--500;
            padding: u(30) u(50);
            border-top-right-radius: u(60);
            border-bottom-left-radius: u(60);
            margin-bottom: u(40);
            display: flex;
            flex-direction: column;

            @include mq($until: $viewport--md - 1) {
                padding: u(20) u(0);
                margin-bottom: u(-40) !important;
                background-color: transparent;
                flex-direction: row;
                margin-bottom: 0;
                flex-wrap: wrap;
            }

            &-item {
                font-family: $font-ibm;
                @include fluid-type(font-size,$viewport--md,$viewport--lg,20px,32px);
                color: $white;
                font-weight: 600;
                margin-bottom: u(0);
                text-decoration: none;
                display: flex;
                align-items: center;

                @include mq($until: $viewport--md - 1) {
                    background-color: $grey--500;
                    border-radius: u(30);
                    padding: u(10) u(20);
                    margin: 0 u(5) 0 0 !important;
                    font-size: u(15);
                }

                &::before {
                    content: "•";
                    margin-right: u(5);
                    display: none;
                }

                &.active,
                &:hover,
                &:active,
                &:focus {
                    color: $yellow--300;

                    &::before {
                        display: flex;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}