/* Columns */
.Block--columns {
    position: relative;
    padding-bottom: u($spacing-unit);

    &::after {
        @include position(absolute, $top: u($spacing-unit), $right: 0, $bottom: 0);
        background-color: rgba($yellow--200, 0.3);
        border-bottom-left-radius: px($spacing-unit--xl);
        content: '';
        display: block;

        @include mq($from: $viewport--md) {
            width: 71.25vw;
        }

        @include mq($until: $viewport--md - 1) {
            left: u($spacing-unit * 1.5);
        }
    }
}

.Columns {

    &--promises {
        @include position(relative, $z: 1);
        @include breakout();
        padding-top: u($spacing-unit);
        padding-right: u($spacing-unit);
        padding-bottom: u($spacing-unit);

        &::before {
            background-color: $yellow--300;
            border-top-right-radius: u($spacing-unit--xl);
            border-bottom-right-radius: u($spacing-unit--xl);
        }

        @include mq($from: $viewport--md) {
            max-width: (100% / 1248 * 1036);
            padding-top: u($spacing-unit--xl);
            padding-right: u($spacing-unit--xl * 2);
            padding-bottom: u($spacing-unit--xl);

            &-object {
                color: $white;
            }

            &-image {

                @include mq($from: $viewport--md) {
                    @include dimensions(32);
                }
            }
        }
    }

    @include mq($from: $viewport--md) {
        display: flex;
        flex-wrap: wrap;
        margin-left: u($spacing-unit * -1);

        .Block-title {
            flex: 1 1 100%;
        }

        .Column {
            width: (100% / 3);
        }

        .Column,
        .Block-title {
            padding-left: u($spacing-unit);
        }
    }
}

.Column {

    @include mq($until: $viewport--md - 1) {
        display: flex;
        align-items: baseline;

        + .Column {
            margin-top: u($spacing-unit--sm);
        }
    }

    &-body {

        :last-child {
            margin-bottom: 0;
        }
    }

    &-object {
        color: $yellow--300;

        .Columns--promises & {
            color: $white;
        }

        @include mq($until: $viewport--md - 1) {
            flex-shrink: 0;
            margin-right: u($spacing-unit--sm);
        }

        @include mq($from: $viewport--md) {
            margin-bottom: u($spacing-unit--sm);
        }
    }

    &-image {
        @include dimensions(16);
        fill: currentColor;

        @include mq($from: $viewport--md) {
            @include dimensions(24);
        }
    }
}
