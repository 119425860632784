/* Image */

// Fluid
img {
    @include image-rendering($image-rendering);
    max-width: 100%;
    height: auto;
}


// Figure
figure > img {
    display: block;
}

