/* Footer */
.Footer {
    @include position(relative, $z: 3);
    margin-top: u($spacing-unit--xl);

    @include mq($from: $viewport--md) {
        margin-top: u($spacing-unit--xl * 1.5);
    }

    &-items {
        background-color: $grey--500;
        color: $white;
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit--xs);

        @include mq($from: $viewport--md) {
            padding-top: u($spacing-unit--xl);
            padding-bottom: u($spacing-unit--xl);
        }

        @include mq($until: $viewport--md - 1) {
            font-size: u(14);
        }

        // Container
        > .Container {

            @include mq($from: $viewport--md) {
                display: flex;
                flex-wrap: wrap;

                @supports (display: grid) {
                    @include grid(repeat(4, auto), repeat(2, auto));
                }
            }

            @include mq($from: $viewport--lg) {
                justify-content: space-between;

                > .Footer-item:last-child {
                    flex: 1 1 100%;
                }
            }

            @include mq($until: $viewport--md - 1) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        }
    }

    // Link
    a:not([class]) {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    // Radius
    &-items,
    &::before {
        border-top-left-radius: u(40);
        border-top-right-radius: u(40);
    }

    &::before {
        @include position(absolute, 0, 0, 80%, 0, -1);
        background-color: $grey--200;
        content: '';
        transform: rotate(-0.9deg);
    }

    // Item
    &-item {

        :last-child {
            margin-bottom: 0;
        }

        @include mq($from: $viewport--md) {

            &:nth-child(1) {
                @include grid-area(1, 2, 1, 2);
                max-width: u(351);
            }

            &:nth-child(2) {
                @include grid-area(2, 3, 1, 3);
            }

            &:nth-child(3) {
                @include grid-area(3, 4, 1, 3);
            }

            &:nth-child(4) {
                @include grid-area(4, 5, 1, 3);
            }

            &:nth-child(5) {
                @include grid-area(5, 6, 1, 3);
            }

            &:nth-child(6) {
                @include grid-area(1, 2, 2, 3);
            }
        }

        @include mq($until: $viewport--md - 1) {

            br:first-of-type,
            p:not(:first-of-type) {
                display: none;
            }
        }
    }
}

.JobsPage .Footer {
    
    &::before {
        display: none;
    }
}

.JobsDetailPage .Footer {
    
    &::before {
        display: none;
    }
}