/* Colors: Palette */

// Yellow
$yellow--100:                    #fdefb0; // Yellow-noon
$yellow--200:                    #fce064; // Yellow-noon
$yellow--300:                    #fad018; // Yellow-noon
$yellow--400:                    #fcb610; // Yellow-afternoon
$yellow--500:                    #fd9b08; // Yellow-sunset
$yellow--600:                    #ff8802; // Yellow-evening
$yellow:                         $yellow--400;

// Grey
$grey--100:                      #ffffff; // Grey-day
$grey--200:                      #f5f5f5; // Grey-morning-6am
$grey--300:                      #d6d6d6; // Grey-morning-7am
$grey--400:                      #bababa; // Grey-morning-8am
$grey--500:                      #343434; // Grey-noon
$grey--600:                      #050505; // Grey-night
$grey:                           $grey--400;

// Green (Success)
$green--100:                     #a8efdc; // Green-sunrise
$green--200:                     #54e0ba; // Green-morning
$green--300:                     #00d198; // Green-noon
$green--400:                     #00a779; // Green-afternoon
$green--500:                     #007d5b; // Green-sunset
$green--600:                     #005f44; // Green-evening
$green:                          $green--400;

// Blue (Information)
$blue--100:                      #b5cafa; // Blue-sunrise
$blue--200:                      #6e96f4; // Blue-morning
$blue--300:                      #2662ef; // Blue-noon
$blue--400:                      #1b4dc3; // Blue-afternoon
$blue--500:                      #103898; // Blue-sunset
$blue--600:                      #082978; // Blue-evening
$blue:                           $blue--400;

// Orange (Warning)
$orange--100:                    #ffd7a9; // Orange-sunrise
$orange--200:                    #ffaf55; // Orange-morning
$orange--300:                    #ff8802; // Orange-noon
$orange--400:                    #e27101; // Orange-afternoon
$orange--500:                    #c45a01; // Orange-sunset
$orange--600:                    #af4a00; // Orange-evening
$orange:                         $orange--400;

// Red (Error)
$red--100:                       #eeb4a8; // Red-sunrise
$red--200:                       #dd6b54; // Red-morning
$red--300:                       #cc2200; // Red-noon
$red--400:                       #b31700; // Red-afternoon
$red--500:                       #9a0c00; // Red-sunset
$red--600:                       #880300; // Red-evening
$red:                            $red--400;

// Black
$black:                          #000000;

// White
$white:                          #ffffff;
